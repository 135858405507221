import React, { useReducer, useState } from 'react'
import FilterContext from './filterContext'
import FilterReducer from './filterReducer'
import { post } from '../../utils/graphql-api'
import {
  ADD_FILTER_CATEGORIES_ID,
  ADD_FILTER_COLLECTIONS_ID,
  ADD_FILTER_STATUS_LISTING,
  ADD_FILTER_VERIFIED,
  GET_SMART_SEARCH,
  GET_TOP_ACCOUNTS,
  GET_TOP_ASSETS,
  GET_TOP_COLLECTIONS,
  REMOVE_FILTER_CATEGORIES_ID,
  REMOVE_FILTER_COLLECTIONS_ID,
  REMOVE_FILTER_STATUS_LISTING,
  REMOVE_FILTER_VERIFIED,
  SET_FILTER_ACTIVITY_ASSET,
  SET_FILTER_PRICE,
  SET_FILTER_SEARCH_ASSET,
  SET_FILTER_SEARCH_COLLECTION,
  SET_FILTER_SEARCH_TAG,
  SET_FILTER_SORTED_BY,
  SET_FILTER_VERIFIED,
  SET_CATEGORY,
  SET_LOADING,
  SET_TYPE_TXN,
  RESET_FILTER,
  SET_TYPE_UTIL,
  REMOVE_TYPE_UTIL,
  SET_FILTER_SEARCH_PROJECT,
  SET_UTIL_PUBLISH,
  ADD_FILTER_PROJECT_ID,
  REMOVE_FILTER_PROJECT_ID,
  RESET_FILTER_PROJECT_ID,
  SET_FILTER_SEARCH_ASSET_UTILITY,
  ADD_FILTER_STATUS_SELL,
  REMOVE_FILTER_STATUS_SELL,
  ADD_ACTIVE_FILTER,
  REMOVE_ACTIVE_FILTER,
  SET_IS_OWNER_ALLOWED_FIAT,
  ADD_BLOCKCHAIN_FILTER,
  REMOVE_BLOCKCHAIN_FILTER,
} from '../types'
import logger from 'use-reducer-logger'
import api from '../../utils/api'

const DOC_SEARCH_TEXT_DESC = 'DOC_SEARCH_TEXT_DESC'

const FilterState = props => {
  const initialState = {
    categoryIds: [],
    collectionIds: [],
    verifiedIds: [],
    projectIds: [],
    priceRange: [0, 99999999999],
    statusType: [],
    statusSell: [],
    topCollections: [],
    topAssets: [],
    topAccounts: [],
    topTags: [],
    tags: [],
    isTag: false,
    sortedBy: null,
    activityType: null,
    searchAsset: undefined,
    searchAssetUtility: '',
    searchTag: '',
    searchCollection: '',
    isVerified: null,
    typeTxn: null,
    typeUtil: [],
    isDraft: false,
    isOwnerAllowedFiatTransaction: false,
    searchProject: undefined,
    activeFilter: [],
    loading: false,
    blockchainType: [],
  }
  const [showFilter, setshowFilter] = useState(false)
  const [showSort, setshowSort] = useState(false)
  const [state, dispatch] = useReducer(logger(FilterReducer), initialState)

  const setIsOwnerAllowedFiat = value => {
    setLoading()
    dispatch({
      type: SET_IS_OWNER_ALLOWED_FIAT,
      payload: value,
    })
  }

  //add blockchain filter
  const addBlockchain = value => {
    setLoading()
    dispatch({
      type: ADD_BLOCKCHAIN_FILTER,
      payload: value,
    })
  }
  const removeBlockchain = value => {
    setLoading()
    dispatch({
      type: REMOVE_BLOCKCHAIN_FILTER,
      payload: value,
    })
  }

  //active filter
  const addActiveFilter = filterName => {
    setLoading()
    dispatch({
      type: ADD_ACTIVE_FILTER,
      payload: filterName,
    })
  }
  const removeActiveFilter = filterName => {
    setLoading()
    dispatch({
      type: REMOVE_ACTIVE_FILTER,
      payload: filterName,
    })
  }

  // category
  const addCategory = async input => {
    setLoading()
    await dispatch({
      type: ADD_FILTER_CATEGORIES_ID,
      payload: input,
    })
  }

  const removeCategory = async input => {
    setLoading()

    await dispatch({
      type: REMOVE_FILTER_CATEGORIES_ID,
      payload: input,
    })
  }
  // project
  const addProject = async input => {
    setLoading()
    await dispatch({
      type: ADD_FILTER_PROJECT_ID,
      payload: input,
    })
  }

  const resetProject = async () => {
    setLoading()
    await dispatch({
      type: RESET_FILTER_PROJECT_ID,
    })
  }

  const removeProject = async input => {
    setLoading()

    await dispatch({
      type: REMOVE_FILTER_PROJECT_ID,
      payload: input,
    })
  }

  // collection
  const addCollection = async input => {
    setLoading()
    await dispatch({
      type: ADD_FILTER_COLLECTIONS_ID,
      payload: input,
    })
  }

  const removeCollection = async input => {
    setLoading()

    await dispatch({
      type: REMOVE_FILTER_COLLECTIONS_ID,
      payload: input,
    })
  }

  // collection
  const addStatusSell = async input => {
    setLoading()
    await dispatch({
      type: ADD_FILTER_STATUS_SELL,
      payload: input,
    })
  }

  const removeStatusSell = async input => {
    setLoading()

    await dispatch({
      type: REMOVE_FILTER_STATUS_SELL,
      payload: input,
    })
  }

  // type listing
  const addStatusListing = async input => {
    setLoading()
    await dispatch({
      type: ADD_FILTER_STATUS_LISTING,
      payload: input,
    })
  }

  const removeStatusListing = async input => {
    setLoading()

    await dispatch({
      type: REMOVE_FILTER_STATUS_LISTING,
      payload: input,
    })
  }

  const setPrice = async input => {
    setLoading()

    await dispatch({
      type: SET_FILTER_PRICE,
      payload: input,
    })
  }

  const setSortedBy = async input => {
    setLoading()

    await dispatch({
      type: SET_FILTER_SORTED_BY,
      payload: input,
    })
  }

  const setActivityType = async input => {
    setLoading()

    await dispatch({
      type: SET_FILTER_ACTIVITY_ASSET,
      payload: input,
    })
  }

  const setSearchAsset = async (input, isTag = false) => {
    setLoading()

    await dispatch({
      type: SET_FILTER_SEARCH_ASSET,
      payload: input,
      isTag: isTag,
    })
  }

  const setSearchAssetUtility = async (input, isTag = false) => {
    setLoading()

    await dispatch({
      type: SET_FILTER_SEARCH_ASSET_UTILITY,
      payload: input,
      isTag: isTag,
    })
  }

  const addVerified = async input => {
    setLoading()

    await dispatch({
      type: ADD_FILTER_VERIFIED,
      payload: input,
    })
  }

  const removeVerified = async input => {
    setLoading()

    await dispatch({
      type: REMOVE_FILTER_VERIFIED,
      payload: input,
    })
  }

  const setVerified = async input => {
    setLoading()

    await dispatch({
      type: SET_FILTER_VERIFIED,
      payload: input,
    })
  }
  const setSearchCollection = async input => {
    setLoading()

    await dispatch({
      type: SET_FILTER_SEARCH_COLLECTION,
      payload: input,
    })
  }
  const setSearchTag = async input => {
    setLoading()
    await dispatch({
      type: SET_FILTER_SEARCH_TAG,
      payload: input,
    })
  }

  const getTopCollections = async inputFilter => {
    setLoading()
    const res = await post('getAllCollectionsWithFilter', {
      ...inputFilter,
      orderBy: DOC_SEARCH_TEXT_DESC,
    })
    await dispatch({
      type: GET_TOP_COLLECTIONS,
      payload: res?.data?.data?.allCollections?.nodes,
    })
    return res.data.data.allCollections?.nodes
  }

  const getTopAssets = async inputFilter => {
    setLoading()
    const res = await post('getAllAssets', {
      ...inputFilter,
      orderBy: DOC_SEARCH_TEXT_DESC,
    })
    await dispatch({
      type: GET_TOP_ASSETS,
      payload: res?.data?.data?.allAssets?.nodes,
    })
    return res?.data?.data?.allAssets?.nodes
  }

  const getTopAccounts = async inputFilter => {
    setLoading()

    const res = await post('getAllAccounts', {
      ...inputFilter,
      orderBy: DOC_SEARCH_TEXT_DESC,
    })
    await dispatch({
      type: GET_TOP_ACCOUNTS,
      payload: res?.data?.data?.allAccounts?.nodes,
    })
    return res?.data?.data?.allAccounts?.nodes
  }

  const getSearch = async (indexes, keyword = '', isRanking = false) => {
    setLoading()
    const res = await api.post(
      '/asset/search',
      !isRanking ? { indexes, keyword } : { indexes },
    )
    await dispatch({
      type: GET_SMART_SEARCH,
      payload: res?.data?.data,
    })

    return res?.data?.data
  }

  const setCategory = input => {
    dispatch({
      type: SET_CATEGORY,
      payload: input,
    })
  }

  const setTypeTxn = param => {
    dispatch({
      type: SET_TYPE_TXN,
      payload: param,
    })
  }

  const setTypeUtil = param => {
    dispatch({
      type: SET_TYPE_UTIL,
      payload: param,
    })
  }

  const setUtilPublished = param => {
    dispatch({
      type: SET_UTIL_PUBLISH,
      payload: param,
    })
  }

  const removeTypeUtil = param => {
    dispatch({
      type: REMOVE_TYPE_UTIL,
      payload: param,
    })
  }

  const setSearchProject = async input => {
    setLoading()
    await dispatch({
      type: SET_FILTER_SEARCH_PROJECT,
      payload: input,
    })
  }

  const resetFilter = () => {
    setshowFilter(false)
    dispatch({
      type: RESET_FILTER,
    })
  }
  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING })
  return (
    <FilterContext.Provider
      value={{
        categoryIds: state.categoryIds,
        collectionIds: state.collectionIds,
        priceRange: state.priceRange,
        statusType: state.statusType,
        sortedBy: state.sortedBy,
        searchAsset: state.searchAsset,
        searchAssetUtility: state.searchAssetUtility,
        activityType: state.activityType,
        searchCollection: state.searchCollection,
        searchTag: state.searchTag,
        isVerified: state.isVerified,
        verifiedIds: state.verifiedIds,
        topCollections: state.topCollections,
        topAssets: state.topAssets,
        topAccounts: state.topAccounts,
        topTags: state.topTags,
        tags: state.tags,
        isTag: state.isTag,
        typeTxn: state.typeTxn,
        typeUtil: state.typeUtil,
        searchProject: state.searchProject,
        isDraft: state.isDraft,
        projectIds: state.projectIds,
        statusSell: state.statusSell,
        activeFilter: state.activeFilter,
        isOwnerAllowedFiatTransaction: state.isOwnerAllowedFiatTransaction,
        loading: state.loading,
        blockchainType: state.blockchainType,
        addProject,
        removeProject,
        resetProject,
        addCategory,
        removeCategory,
        addVerified,
        removeVerified,
        addCollection,
        removeCollection,
        setPrice,
        addStatusListing,
        removeStatusListing,
        setSearchAsset,
        setSortedBy,
        setActivityType,
        setVerified,
        setSearchCollection,
        getTopCollections,
        getTopAssets,
        getTopAccounts,
        getSearch,
        setSearchTag,
        setCategory,
        setTypeTxn,
        setTypeUtil,
        setUtilPublished,
        removeTypeUtil,
        resetFilter,
        setSearchProject,
        setSearchAssetUtility,
        addStatusSell,
        removeStatusSell,
        addActiveFilter,
        removeActiveFilter,
        setIsOwnerAllowedFiat,
        showFilter,
        setshowFilter,
        showSort,
        setshowSort,
        addBlockchain,
        removeBlockchain,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  )
}

export default FilterState
