import React, { useReducer } from 'react';
import BankContext from './bankContext';
import BankReducer from './bankReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { SET_LOADING, ALL_BANK, GET_VALIDATION_BANK, SET_WAITING_BANK, SET_MODAL_BANK, SET_URL_INV_BANK, ADD_VALIDATION_BANK, ADD_VERIFIED_BANK, GET_BANK } from '../types';
import logger from 'use-reducer-logger';
import api from '../../utils/api';

const BankState = props => {
  const initialState = {
    banks: [],
    bank: {},
    totalCount: 0,
    pageInfo: {},
    loading: false,
    validationInfo: [],
    showWaiting: false,
    showAddBank: false,
    invoiceUrl: null,
  };

  const [state, dispatch] = useReducer(logger(BankReducer), initialState);

  // All Collection
  const getAll = async () => {
    setLoading();

    try {

      const res = await post("allBanks");
      dispatch({
        type: ALL_BANK,
        payload: res.data.data.allBanks.nodes
      });
    } catch (error) {
      console.log('ERR: ', error);
    }
  };

  // All Collection
  const getBank = async () => {
    setLoading();

    try {

      const res = await api.get("xendit/get_bank");
      dispatch({
        type: GET_BANK,
        payload: res.data
      });
      return res.data
    } catch (error) {
      console.log('ERR: ', error);
    }
  };

  const addValidationBank = async (param) => {
      setLoading();
        const res = await post("addBankAccountValidation", {...param});
        dispatch({
          type: ADD_VALIDATION_BANK,
          payload: res?.data?.data
        })
        return res?.data?.data
  }
  const getValidationBank = async (param) => {
      setLoading();
        const res = await post("getAccountBankValidation", {...param});
        // console.log("ZAP RES", res.data.data.allAccountsBankValidations.nodes)
        dispatch({
          type: GET_VALIDATION_BANK,
          payload: res?.data?.data?.allAccountsBankValidations?.nodes
        })
        return res?.data?.data?.allAccountsBankValidations?.nodes
  }

  const addVerifiedBankAccount = async (param) => {
    setLoading();
      const res = await post("addVerifiedBankAccount", {...param});
      dispatch({
        type: ADD_VERIFIED_BANK,
        payload: res?.data?.data
      })
      return res?.data?.data
}

  const getVerifiedBank = async (param) => {
    setLoading()
    const res = await post("getVerifiedBankAccount", {...param})
    // console.log("ZAP RES", res.data.data.allVerifedBankAccounts)
    // dispatch({
    //   type: GET_VERIFIED_BANK,
    //   payload: res?.data
    // })
    return res?.data?.data?.allVerifedBankAccounts
  }
  const checkIluma = async (param) => {
    setLoading()
    const res = await api.post('/xendit/verify_bank', {...param})
    // dispatch({
    //   type: GET_ILUMA_BANK,
    //   payload: res?.data
    // })
    return res?.data
  }
  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  const setShowModalWaiting = (state) => dispatch({ type: SET_WAITING_BANK, payload: state });
  const setShowModalBank = (state) => dispatch({ type: SET_MODAL_BANK, payload: state });
  const setUrlInvBank = (url) => dispatch({ type: SET_URL_INV_BANK, payload: url });
  return (
    <BankContext.Provider
      value={{
        banks: state.banks,
        bank: state.bank,
        loading: state.loading,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        validationInfo: state.validationInfo,
        showWaiting: state.showWaiting,
        showAddBank: state.showAddBank,
        invoiceUrl: state.invoiceUrl,
        addValidationBank,
        addVerifiedBankAccount,
        getAll,
        getValidationBank,
        getVerifiedBank,
        checkIluma,
        setLoading,
        setShowModalWaiting,
        setShowModalBank,
        setUrlInvBank,
        getBank,
      }}
    >
      {props.children}
    </BankContext.Provider>
  );
};

export default BankState;
