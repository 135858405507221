import { ADD_SELECTED_PROJECT_ASSET, DRAFT_SELECTED_PROJECT_ASSET, GET_LIST_PROJECT_GUEST, GET_PROJECT, PREVIEW_IMAGE_ASSET_PROJECT, REMOVE_DRAFT_SELECTED_PROJECT_ASSET, REMOVE_DRAFT_SELECTED_PROJECT_ASSET_API, REMOVE_SELECTED_PROJECT_ASSET, SET_LOADING, SET_SELECTED_PROJECT_ASSET } from "../types";

const reducer = (state, action) => {
  switch (action.type) {
    case GET_LIST_PROJECT_GUEST:
      return {
        ...state,
        all_project_util: action.payload,
        totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false
      };
    case GET_PROJECT:
      return{
        ...state,
        project:action.payload,
        selectedAsset: action.payload?.utilityProjectsAssetsByUtilityProjectId?.nodes?.filter(({isLocked})=>!isLocked)?.map(assetProject=> assetProject?.assetByAssetId),
        draftSelectedAsset: action.payload?.utilityProjectsAssetsByUtilityProjectId?.nodes?.filter(({isLocked})=>!isLocked)?.map(assetProject=> assetProject?.assetByAssetId),
        utilityProjectsAssetsByUtilityProjectId: action.payload?.utilityProjectsAssetsByUtilityProjectId?.nodes,
        publishedAsset: action.payload?.utilityProjectsAssetsByUtilityProjectId?.nodes?.filter(({isLocked})=>isLocked)?.map(assetProject=> assetProject?.assetByAssetId),
        loading:false
      }
    case PREVIEW_IMAGE_ASSET_PROJECT:
      return{
        ...state,
        previewImage: action.payload
      }
    case ADD_SELECTED_PROJECT_ASSET:
      return {
        ...state,
        selectedAsset: state.selectedAsset.concat(action.payload),
      }

    case SET_SELECTED_PROJECT_ASSET:
      return {
        ...state,
        selectedAsset: action.payload,
      }
    case REMOVE_SELECTED_PROJECT_ASSET:
      return {
        ...state,
        selectedAsset: state.selectedAsset.filter((v) => v.id !== action.payload),
      }
    case REMOVE_DRAFT_SELECTED_PROJECT_ASSET:
      return{
        ...state,
        draftSelectedAsset: state.draftSelectedAsset.filter((v) => v.id !== action.payload),
      }
      case REMOVE_DRAFT_SELECTED_PROJECT_ASSET_API:
        return{
          ...state,
        }
    case DRAFT_SELECTED_PROJECT_ASSET:
      return{
        ...state,
        draftSelectedAsset: state.selectedAsset
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default: return state
  }
}

export default reducer