import React, { useReducer } from 'react';
import AccountsBankContext from './accountsBankContext';
import AccountsBankReducer from './accountsBankReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { SET_LOADING, GET_COLLECTION, CREATE_ACCOUNT_BANK, GET_ACTIVE_ACCOUNT_BANK, GET_ACCOUNT_BANK, ACTIVATE_BANK } from '../types';
import logger from 'use-reducer-logger';

const AccountsBankState = props => {
  const initialState = {
    accountsBank: [],
    activeBank: [],
    accountBank: {},
    totalCount: 0,
    pageInfo: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(AccountsBankReducer), initialState);

  // All Collection
  const create = async (input) => {
    setLoading();

    try {

      const res = await post("addAccountsBank", { ...input });
      dispatch({
        type: CREATE_ACCOUNT_BANK,
        payload: res.data.data.createAccountsBank.accountsBank
      });
    } catch (error) {
      console.log('ERR: ', error);
    }
  };

  // get accountBank by id
  const getActiveBank = async (accountId) => {

    setLoading()
    try {

      const res = await post("activeBankByAccountId", { accountId })

      dispatch({
        type: GET_ACTIVE_ACCOUNT_BANK,
        payload: res?.data?.data?.allAccountsBanks?.nodes
      })

      return res?.data?.data?.allAccountsBanks?.nodes

    } catch (error) {
      console.log('ERR: ', error);
    }
  }

  const getById = async (accountId) => {

    setLoading()
    try {

      const res = await post("accountsBankByAccountId", { accountId })

      dispatch({
        type: GET_ACCOUNT_BANK,
        payload: res?.data?.data?.allAccountsBanks?.nodes
      })

      return res?.data?.data?.allAccountsBanks?.nodes

    } catch (error) {
      console.log('ERR: ', error);
    }
  }

  // update accountBank by id
  const activateBank = async (accountId, bankId) => {

    setLoading()
    try {

      const res = await post("activateBank", { accountId, bankId })

      dispatch({
        type: ACTIVATE_BANK,
        payload: res?.data?.data
      })

      return res?.data?.data

    } catch (error) {
      console.log('ERR: ', error);
    }
  }

  // update accountBank by id
  const _delete = async (id) => {

    setLoading()
    try {

      const res = await post("deleteAccountsBank", { id })

      dispatch({
        type: GET_COLLECTION,
        payload: res?.data?.data?.deleteAccountsBankById
      })

      return res?.data?.data?.deleteAccountsBankById

    } catch (error) {
      console.log('ERR: ', error);
    }
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <AccountsBankContext.Provider
      value={{
        accountsBank: state.accountsBank,
        accountBank: state.accountBank,
        activeBank: state.activeBank,
        loading: state.loading,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        create,
        activateBank,
        getById,
        getActiveBank,
        delete: _delete
      }}
    >
      {props.children}
    </AccountsBankContext.Provider>
  );
};

export default AccountsBankState;
