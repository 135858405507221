
import axios from "axios";
import env from "../config/env";

const { GRAPHQL_API_URL } = env
export const post = (id, v) => {
    if (v) {
        let temp = JSON.stringify(v);
        const destroyer = [
            "www.instagram.com",
            "ww.instagram.com",
            "w.instagram.com",
            ".instagram.com",
        ];
        let checkWWW;
        destroyer.forEach((element) => {
            checkWWW = temp.includes(element);
        });
        if (checkWWW) {
            temp = temp.replace("www.instagram.com", "instagram.com");
            temp = temp.replace("ww.instagram.com", "instagram.com");
            temp = temp.replace("w.instagram.com", "instagram.com");
            temp = temp.replace(".instagram.com", "instagram.com");
            v = JSON.parse(temp);
        }
    }
    const auth = JSON.parse(window.localStorage.getItem('auth'))
    return axios.post(GRAPHQL_API_URL, { id, var: v }, {
        headers: {
            authorization: `Bearer ${auth?.accessToken}`
        }
    });
};