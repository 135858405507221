import {
  GET_ACCOUNT_BY_ADDRESS,
  GET_ACCOUNT_BY_ID,
  GET_ACCOUNT_OWNER_BY_ID,
  GET_ALL_ACCOUNTS,
  GET_ALL_ACCOUNTS_TOKENS,
  SET_LOADING,
  UPDATE_ACCOUNT,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case GET_ALL_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        loading: false
      };
    case GET_ACCOUNT_BY_ADDRESS:
      return {
        ...state,
        account: action.payload,
        loading: false
      };
    case GET_ACCOUNT_BY_ID:
      return {
        ...state,
        account: action.payload,
        loading: false
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        loading: false
      };
    case GET_ACCOUNT_OWNER_BY_ID:
      return {
        ...state,
        owner: action.payload,
        loading: false
      };

    case GET_ALL_ACCOUNTS_TOKENS:
      return {
        ...state,
        tokens: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
