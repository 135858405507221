import {
  ACCEPT_MAKE_OFFER_POSTPROCESS,
  BUY_POSTPROCESS,
  SET_LOADING,
} from '../types';

  const reducer = (state, action) => {
    switch (action.type) {
      case BUY_POSTPROCESS:
        return {
          ...state,
          buyerAsset:action.payload.buyerAsset,
          LOADING: false
        };
        
      case ACCEPT_MAKE_OFFER_POSTPROCESS:
        return {
          ...state,
          buyerAsset:action.payload.buyerAsset,
          LOADING: false
        };
      case SET_LOADING:
        return {
          ...state,
          loading: true
        };
      default:
        return state;
    }
  };
  
  export default reducer