import React from "react";

const MailOpen = ({viewBox, size, onClick, color, ...props}) => {
    return (
        <div onClick={onClick} {...props}>
            <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size}`} fill="none">
                <path d="M3 10.0608C3 9.71247 3.1813 9.38921 3.47855 9.20755L12 4L20.5214 9.20755C20.8187 9.38921 21 9.71247 21 10.0608V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V10.0608Z" stroke={color} strokeWidth="2"/>
                <path d="M3 10.5L12 16L21 10.5" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </div>
    )
}

MailOpen.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: "#868686",
}

export default MailOpen