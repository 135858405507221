import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Modal from './Modal';
import CardModal from '../Card/CardModal';
import ModalTitleText from '../Text/ModalTitleText';
// import RoundedButton from "../Button/RoundedButton";
// import ButtonText from "../Text/ButtonText";
import ButtonModal from './ButtonModal';
import ExclamationIcon from '../../assets/icon/exclamation_icon';
import authContext from '../../context/Auth/authContext';
import web3ModalContext from '../../context/Web3Modal/web3ModalContext';
import { useContext } from 'react';
import ResponsiveContext from '../../context/Responsive/responsiveContext';
import P from './P';

const ModalChangeAccount = (props) => {
  // const { fontSize = 'text-lg' } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const responsive = useContext(ResponsiveContext);
  const {
    setSession,
    setChangeAccount,
    login,
    logout,
    web3Connection,
    setMetamaskLoading,
    changeAccount,
  } = useContext(authContext);
  const { web3Modal, injectedProvider } = useContext(web3ModalContext);
  const [unlocked, setUnlocked] = useState(true);
  const constructMessageHash = (userAddress) => {
    const messageHash = ethers.utils.solidityKeccak256(
      ['string'],
      [`I want to login with ${userAddress}`],
    );
    return messageHash;
  };

  useEffect(() => {
    const checkConnectedAccount = async () => {
      console.log('zap change account', changeAccount);
      if (changeAccount) {
        setLoading(true);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        try {
          console.log('zap masuk try block');
          const timeoutId = setTimeout(async () => {
            // toast.error("zap metamask is not responding")
            clearTimeout(timeoutId);
            await handleLogout();
          }, 15000);
          const accounts = await provider.listAccounts();
          console.log('zap accounts', accounts);
          setUnlocked(accounts.length > 0);
          clearTimeout(timeoutId);
          setMetamaskLoading(false);
          if (accounts.length > 0) {
            setLoading(false);
          } else {
            setLoading(true);
            setUnlocked(false);
            await handleLogout();
          }
        } catch (error) {
          console.log('zap masuk error block');
          // unlocked = false
          setLoading(true);
          setUnlocked(false);
          await handleLogout();
        }
      }
    };
    checkConnectedAccount();
  }, [changeAccount]);

  const logoutOfWeb3Modal = async () => {
    navigate('/');
    await web3Modal.clearCachedProvider();
    if (
      injectedProvider &&
      injectedProvider.provider &&
      typeof injectedProvider.provider.disconnect == 'function'
    ) {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      await logout();
      Cookies.remove('web3login');
      setSession(false);
      await logoutOfWeb3Modal();
      setChangeAccount(false);
    } catch (error) {
      Cookies.remove('web3login');
      setSession(false);
      await logoutOfWeb3Modal();
      setChangeAccount(false);
    }
  };

  const handleLogin = async () => {
    try {
      // setMetamaskLoading(false)
      setLoading(true);
      setChangeAccount(false);
      setMetamaskLoading(true);
      const newProvider = new ethers.providers.Web3Provider(web3Connection);
      const newSigner = newProvider.getSigner();
      const newUserAddress = await newSigner.getAddress();
      const newMessageHash = constructMessageHash(newUserAddress);
      const newSignature = await newSigner.signMessage(newMessageHash);
      const input = {
        signature: newSignature,
        userAddress: newUserAddress,
      };
      await logout();
      console.log('input web3login', input);
      await login(input);
      // setInjectedProvider(newProvider);
      // setMetamaskLoading(false)
      window.location.reload();
    } catch (error) {
      console.log('ERR: ', error);
      if (error?.code === 4001) {
        // di reject signing nya
        await logout();
        Cookies.remove('web3login');
        setSession(false);
        await logoutOfWeb3Modal();
        setChangeAccount(false);
      }
    }
  };

  return (
    <Modal wide={true} backdrop={false} responsive={responsive} {...props}>
      <CardModal className="md:px-12 px-4">
        <ModalTitleText text="Pergantian Akun" />
        <ExclamationIcon width="100" height="100" />
        <p className="font-poppins text-teks font-medium mt-6">
          Kami mendetekasi akun login berbeda dengan akun yang aktif di metamask
        </p>
        <ul className="list-decimal text-left px-12 mt-2 mb-6 font-poppins text-sm text-teks_2 -ml-2">
          <li className='mb-1'>
            Jika anda ingin tetap melanjutkan pergantian akun, silahkan klik
            tombol "Lanjutkan" udah melakukan proses tanda tangan
          </li>
          <li>Atau jika tidak harap tekan "Logout"</li>
        </ul>
        <div className="flex flex-row justify-center items-center">
          <ButtonModal
            type={'confirmation'}
            onClick={handleLogout}
            okLabel="Lanjutkan"
            noLabel="Logout"
            onOkClick={handleLogin}
            onNoClick={handleLogout}
            disabled={loading}
          />
        </div>
      </CardModal>
    </Modal>
  );
};

export default ModalChangeAccount;
