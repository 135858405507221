import React, { useReducer } from 'react';
import AccountContext from './accountContext';
import AccountReducer from './accountReducer';
import { post } from '../../utils/graphql-api';
import { GET_ACCOUNT_BY_ADDRESS, GET_ACCOUNT_BY_ID, GET_ACCOUNT_OWNER_BY_ID, GET_ALL_ACCOUNTS, GET_ALL_ACCOUNTS_TOKENS, SET_LOADING, UPDATE_ACCOUNT } from '../types';
import logger from 'use-reducer-logger';

const AccountState = props => {
  const initialState = {
    accounts: [],
    account: {},
    owner: {},
    tokens: [],
    loading: false
  };

  const [state, dispatch] = useReducer(logger(AccountReducer), initialState);

  // Search Users
  const getAllAccounts = async () => {
    setLoading();

    const res = await post("getAllAccounts");
    await dispatch({
      type: GET_ALL_ACCOUNTS,
      payload: res.data.data.allAccounts.nodes
    });
  };

  const getAccountByAddress = async (address) => {
    setLoading();

    const res = await post("getAccountByAddress", {
      address
    });

    await dispatch({
      type: GET_ACCOUNT_BY_ADDRESS,
      payload: res.data.data.accountByAddress
    });
    return res.data.data.accountByAddress
  }

  const getAllAccountsTokens = async () => {
    setLoading();

    const res = await post("getAllAccountsTokens");

    await dispatch({
      type: GET_ALL_ACCOUNTS_TOKENS,
      payload: res.data.data.allAccountsTokens.nodes
    });
    return res.data.data.allAccountsTokens.nodes
  }

  const getAccountById = async (id) => {
    setLoading();

    const res = await post("getAccountById", {
      id
    })

    await dispatch({
      type: GET_ACCOUNT_BY_ID,
      payload: res?.data?.data?.accountById
    })
    return res?.data?.data?.accountById
  }

  const update = async (id, patch) => {
    setLoading();

    const res = await post("updateAccountById", {
      id,
      patch
    })

    await dispatch({
      type: UPDATE_ACCOUNT,
      payload: res.data.data.updateAccountById
    })
    return res.data.data.updateAccountById
  }

  const getAccountOwnerById = async (id) => {
    setLoading();

    const res = await post("getAccountById", {
      id
    })

    await dispatch({
      type: GET_ACCOUNT_OWNER_BY_ID,
      payload: res?.data?.data?.accountById
    })
    return res?.data?.data?.accountById
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <AccountContext.Provider
      value={{
        accounts: state.accounts,
        account: state.account,
        tokens: state.tokens,
        owner: state.owner,
        loading: state.loading,
        update,
        getAllAccounts,
        getAccountByAddress,
        getAllAccountsTokens,
        getAccountById,
        getAccountOwnerById
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export default AccountState;
