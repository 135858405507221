import React from 'react'

const MailClosed = ({ viewBox, size, onClick, color, ...props }) => {
  return (
    <div onClick={onClick} {...props}>
      <svg
        viewBox={`0 0 ${viewBox} ${viewBox}`}
        className={`h-${size} w-${size}`}
        fill="none"
      >
        <path
          d="M20 6H4C3.44772 6 3 6.44772 3 7V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V7C21 6.44772 20.5523 6 20 6Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M3 7.5L12 13L21 7.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  )
}

MailClosed.defaultProps = {
  viewBox: 24,
  size: 6,
  onClick: () => {},
  color: '#868686',
}

export default MailClosed
