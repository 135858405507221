import { ALL_INVOICE, CALCULATE_FEE, CHECK_PAYMENT, CREATE_INVOICE, GET_EXPIRED_INVOICE, GET_PAYMENT_STATUS, GET_UPAID_INVOICE, SET_LOADING, SIGN_MESSAGE } from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case CALCULATE_FEE:
      return {
        ...state,
        adminFee: action?.payload,
        loading: false
      };
    case CREATE_INVOICE:
      return {
        ...state,
        gqlInvoice: action.payload.gql_invoice,
        invoice: action.payload.invoice,
        message: action.payload.message,
        loading: false
      };
    case ALL_INVOICE:
      return {
        ...state,
        invoices: action.payload?.nodes,
        totalCount: action.payload.totalCount,
        pageInfo: action.payload.pageInfo,
        loading: false
      }
    case CHECK_PAYMENT:
      return {
        ...state,
        isPayed: action.payload,
        loading: false,
      }
    case GET_PAYMENT_STATUS:
      return {
        ...state,
        paidAmount: action?.payload?.paid_amount,
        unPaidAmount: action?.payload?.unpaid_amount,
        loading: false,
      }
    case SIGN_MESSAGE:
      return {
        ...state,
        messageHash: action.payload?.messageHash,
        signature: action.payload?.signature,
        loading: false,
      }

    case GET_UPAID_INVOICE:
      return {
        ...state,
        invoices: action.payload,
        loading: false,
      }
    case GET_EXPIRED_INVOICE:
      return{
        ...state,
        loading: false,
      }
      case SET_LOADING:
        return {
          ...state,
          loading: true
        };
    default:
      return state;
  }
};

export default reducer
