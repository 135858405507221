import {
  ALL_COLLECTION,
  ALL_COLLECTION_WITH_FILTER,
  GET_COLLECTION,
  SET_LOADING,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case ALL_COLLECTION:
      return {
        ...state,
        collections: action.payload,
        loading: false
      };
    case ALL_COLLECTION_WITH_FILTER:
      return {
        ...state,
        collections: action.payload,
        totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false
      };
    case GET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
