import SubMenu from "../../container/Layout/LayoutSubMenu"
// import { navSidebar } from "../../router/_nav_sidebar"
import nav from '../../router/_nav'
import InputSmartSearch from "../Input/InputSmartSearch"

const MobileMenu = ({
  toggleMenu, 
  Menu, 
  AuthContext, 
  ButtonCreateAsset, 
  ButtonLogin, 
  listMenuOnLogin, 
  setToggle, 
  showSub, 
  setShowSub, 
  menuParam,
  headerContext,
  navigate,
}) => {
    return (
      <div className={`transform ease-in-out duration-500 md:hidden flex flex-col bg-white h-full w-full fixed left-0 z-30 overflow-scroll  ${
        toggleMenu ? "translate-x-0 " : "-translate-x-full"
      }`}>
        <div>
          <div className="flex items-center w-full justify-center">
              <InputSmartSearch setShowMenuMobile = {setToggle}/>
          </div>
          <Menu data={nav} title={"Menu"} headerContext={headerContext} navigate={navigate}/>
          {AuthContext?.auth?.user && <div className="h-2 bg-gray-50 mt-6 w-full"></div>}
          {AuthContext?.auth?.user && <Menu data={listMenuOnLogin} title={"Akun Saya"} />}
        </div>
        {!AuthContext?.auth?.user ? <div className="w-full items-center grid grid-cols-2 gap-6 justify-center bg-white px-4 pt-4 mb-20">
          <ButtonCreateAsset />
          <ButtonLogin />
        </div> : <div className="w-full items-center grid grid-cols-1 justify-center bg-white px-4 pt-4 mb-20">
          <ButtonCreateAsset />
        </div>}
        <SubMenu showSubMenu={showSub} setShowSubMenu={setShowSub} menuParam={menuParam} setShowMenu={setToggle}/>
      </div>
    )
  }

export default MobileMenu