import React, { useReducer } from 'react';
import notificationContext from './notificationContext';
import notificationReducer from './notificationReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { SET_LOADING, CREATE_NOTIFICATION, ALL_NOTIFICATION, UPDATE_NOTIFICATION, UNREAD_NOTIFICATION } from '../types';
import logger from 'use-reducer-logger';

const NotificationState = props => {
  const initialState = {
    notifications: [],
    notification: {},
    totalCount: 0,
    pageInfo: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(notificationReducer), initialState);

  // craete  notification
  const create = async (input) => {
    setLoading();
    try {
      const id = input?.id
      delete input["id"]
      const res = await post("createNotificationConfig", { id, patch: {...input} });
      dispatch({
        type: CREATE_NOTIFICATION,
        payload: res.data.data.createAccountsNotification.accountsNotification
      });
      return res.data.data.createAccountsNotification.accountsNotification

    } catch (error) {
      console.log('ERR: ', error);
    }
  };

 // UPDATE  notification
 const update = async (id,input) => {
  setLoading();
  try {
    // FLAG ini belum bisa diubah jadi patch
    const res = await post("updateNotificationById", { id, patch : {...input} });
    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: res.data.data.updateNotificationById
    });
    return res.data.data.updateNotificationById

  } catch (error) {
    console.log('ERR: ', error);
  }
};

   // get all  notification
   const getAll = async (filter) => {
    setLoading();
      const res = await post("allNotifications", { ...filter });
      // console.log("TES", res)
      dispatch({
        type: ALL_NOTIFICATION,
        payload: res?.data?.data?.allNotifications
      });
      return res?.data?.data?.allNotifications
  };
   const getUnread = async (id) => {
    setLoading();
      const res = await post("unreadNotificationsByAccountId", {inputAccountId: id});
      // console.log("TES", res?.data?.data?.unreadNotificationByAccountId?.nodes)
      dispatch({
        type: UNREAD_NOTIFICATION,
        payload: res?.data?.data?.unreadNotificationByAccountId?.nodes[0]
      });
      return res?.data?.data?.unreadNotificationByAccountId?.nodes[0]
  };

  const updateBatchToRead = async (id) => {
      const getMessage = await getAll({accountId: id})
      const newData = await getMessage?.nodes.filter(el => el.isRead === false)
      const newProps = await newData.map(item => ({id: item?.id, isRead: true}))
      const res = await post("updateBatchNotificationById", {batchUpdate: newProps})
      return res.data.unreadNotificationByAccountId
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <notificationContext.Provider
      value={{
        notifications: state.notifications,
        notification: state.notification,
        loading: state.loading,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        create,
        getAll,
        update,
        getUnread,
        updateBatchToRead,
      }}
    >
      {props.children}
    </notificationContext.Provider>
  );
};

export default NotificationState;
