import React from "react";

const StarIcon = ({viewBox, size, onClick, color, ...props}) => {
    return (
        <div onClick={onClick} {...props}>
            <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
            <path d="M16.4455 18.9961C16.3173 18.9966 16.1908 18.9669 16.0767 18.9093L11.988 16.8024L7.89924 18.9093C7.76647 18.978 7.61677 19.0087 7.46717 18.9979C7.31757 18.987 7.17407 18.9351 7.05297 18.8479C6.93187 18.7608 6.83804 18.642 6.78214 18.505C6.72624 18.3679 6.71052 18.2183 6.73675 18.0729L7.53847 13.6303L4.23541 10.4739C4.13235 10.3726 4.05925 10.2457 4.02394 10.1067C3.98863 9.96766 3.99244 9.8218 4.03498 9.68476C4.08145 9.54451 4.16692 9.4199 4.28171 9.32505C4.39649 9.23021 4.53599 9.16893 4.68437 9.14817L9.25413 8.49322L11.2664 4.44514C11.3321 4.31172 11.4346 4.19921 11.5622 4.12048C11.6898 4.04176 11.8374 4 11.988 4C12.1386 4 12.2861 4.04176 12.4138 4.12048C12.5414 4.19921 12.6439 4.31172 12.7095 4.44514L14.7459 8.48533L19.3156 9.14028C19.464 9.16104 19.6035 9.22232 19.7183 9.31716C19.8331 9.41201 19.9186 9.53662 19.965 9.67687C20.0076 9.81391 20.0114 9.95977 19.9761 10.0988C19.9408 10.2378 19.8676 10.3647 19.7646 10.466L16.4615 13.6224L17.2632 18.065C17.2919 18.2129 17.2769 18.3658 17.2201 18.5057C17.1632 18.6455 17.0669 18.7664 16.9426 18.8541C16.7974 18.9542 16.6226 19.0042 16.4455 18.9961V18.9961ZM11.988 15.1295C12.1165 15.1263 12.2436 15.1563 12.3568 15.2163L15.3792 16.7945L14.802 13.4724C14.7796 13.3456 14.7891 13.2153 14.8296 13.0929C14.8701 12.9704 14.9405 12.8596 15.0345 12.7701L17.4396 10.4581L14.0724 9.96883C13.949 9.94436 13.8331 9.8916 13.7342 9.81483C13.6353 9.73806 13.5562 9.63943 13.5032 9.52694L11.988 6.56782L10.4727 9.52694C10.4147 9.64033 10.3298 9.73828 10.2251 9.81246C10.1204 9.88665 9.99911 9.93487 9.87145 9.95305L6.50426 10.4423L8.90939 12.7544C9.00342 12.8439 9.07376 12.9547 9.11428 13.0771C9.15481 13.1995 9.16429 13.3298 9.14189 13.4567L8.56466 16.7393L11.5871 15.1611C11.7152 15.1142 11.854 15.1033 11.988 15.1295V15.1295Z" fill={color}/> </svg>

        </div>
    )
}

StarIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: "#868686",
}

export default StarIcon