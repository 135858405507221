import React, { useReducer } from 'react';
import FiatContext from './fiatContext';
import FiatReducer from './fiatReducer';
import { SET_LOADING, CALCULATE_FEE, CREATE_INVOICE, CHECK_PAYMENT, SIGN_MESSAGE, GET_UPAID_INVOICE, GET_EXPIRED_INVOICE, ALL_INVOICE, GET_PAYMENT_STATUS, DELETE_INVOICE } from '../types';
import { BNI, BRI, DANA, LINKAJA, MANDIRI, OVO, PERMATA, QRIS, SAMPOERNA, SHOPEEPAY } from '../../utils/constants/paymentTypes';
import logger from 'use-reducer-logger';
import api from '../../utils/api';
import { post } from '../../utils/graphql-api';

const FiatState = props => {
  const initialState = {
    adminFee: 0,
    gqlInvoice: {},
    isPayed: "",
    message: "",
    invoice: {},
    invoices: [],
    signature: "",
    messageHash: "",
    totalCount: 0,
    pageInfo: {},
    paidAmount: 0,
    unPaidAmount: 0,
    loading: false
  };

  const [state, dispatch] = useReducer(logger(FiatReducer), initialState);

  const calculateFee = ({ price, paymentMethod }) => {
    setLoading()

    let admin_fee

    switch (paymentMethod) {
      /*  case "BCA":
         admin_fee = Math.ceil(BCA.admin_fee(price))
         break; */
      case "SAMPOERNA":
        admin_fee = Math.ceil(SAMPOERNA.admin_fee(price))
        break;
      case "PERMATA":
        admin_fee = Math.ceil(PERMATA.admin_fee(price))
        break;
      case "LINKAJA":
        admin_fee = Math.ceil(LINKAJA.admin_fee(price))
        break;
      case "BNI":
        admin_fee = Math.ceil(BNI.admin_fee(price))
        break;
      case "BRI":
        admin_fee = Math.ceil(BRI.admin_fee(price))
        break;
      case "DANA":
        admin_fee = Math.ceil(DANA.admin_fee(price))
        break;
      case "MANDIRI":
        admin_fee = Math.ceil(MANDIRI.admin_fee(price))
        break;
      case "OVO":
        admin_fee = Math.ceil(OVO.admin_fee(price))
        break;
      case "QRIS":
        admin_fee = Math.ceil(QRIS.admin_fee(price))
        break;
      case "SHOPEEPAY":
        admin_fee = Math.ceil(SHOPEEPAY.admin_fee(price))
        break;
      default:
        admin_fee = 0
        break;
    }

    dispatch({
      type: CALCULATE_FEE,
      payload: admin_fee
    })
    return { admin_fee }
  }

  const checkPayment = async (externalId) => {
    setLoading()

    const res = await post("allInvoices", {
      externalId
    })
    dispatch({
      type: CHECK_PAYMENT,
      payload: res?.data?.data?.allInvoices
    })
    return res?.data?.data?.allInvoices
  }

  const createInvoice = async (body) => {
    setLoading()
    const res = await api.post("xendit/create_invoice", { ...body }, { withCredentials: true })
    dispatch({
      type: CREATE_INVOICE,
      payload: res?.data
    })
    return res?.data
  }

  const _delete = async (id) => {
    setLoading()
    const res = await post('deleteInvoice', { id })
    dispatch({
      type: DELETE_INVOICE,
      payload: res?.data?.data?.deleteInvoiceById
    })
    return res?.data?.data?.deleteInvoiceById
  }

  const allInvoice = async (option) => {
    setLoading()
    const res = await post("allInvoices", { ...option })
    dispatch({
      type: ALL_INVOICE,
      payload: res?.data?.data?.allInvoices
    })
    return res?.data?.data?.allInvoices
  }

  const signMessage = async (message) => {
    setLoading()
    const res = await api.get("sign_message", {
      withCredentials: true,
      params: {
        message
      }
    })
    dispatch({
      type: SIGN_MESSAGE,
      payload: res?.data
    })
    return res?.data
  }

  const unpaidInvoice = async (assetId, accountId) => {
    setLoading()
    let res
    accountId ?
      res = await post('unPaidInvoiceByAssetIdAndAccountId', { assetId, accountId })
      :
      res = await post('unPaidInvoiceByAssetId', { assetId })

    dispatch({
      type: GET_UPAID_INVOICE,
      payload: res?.data?.data?.allInvoices?.nodes
    })
    return res?.data?.data?.allInvoices?.nodes
  }

  const getPaymentStatus = async ({ accountId }) => {
    setLoading()
    const res = await api.get('xendit/payment_status', { params: { accountId } })
    dispatch({
      type: GET_PAYMENT_STATUS,
      payload: res?.data
    })
    return res?.data
  }

  const expireInvoice = async (pgGeneratedId) => {
    setLoading()
    const res = await api.post('xendit/expire_invoice', { pgGeneratedId })

    dispatch({
      type: GET_EXPIRED_INVOICE,
      payload: res?.data?.expire
    })
    return res?.data?.expire
  }

  const getCryptoSummary = async (accountId) => {
    // setLoading()
    const res =  await post('amountSoldUsingCryto', {inputAccountId : parseInt(accountId)})
    return res?.data?.data?.soldInCryptoByAccountId?.nodes[0]
  }

  const getCryptoMultichainSummary = async (accountId, blockchainType) => {
    // setLoading()
    const res =  await post('amountSoldUsingCrytoMultichain', {inputAccountId : parseInt(accountId), inputBlockchainType: blockchainType})
    return res?.data?.data?.soldInCryptoMultichainByAccountId?.nodes[0]
  }

  const getFiatSummary = async (accountId) => {
    // setLoading()
    const res = await api.post('xendit/summary_fiat_payment', { params: { accountId } })
    return res?.data?.summary_fiat_amount
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <FiatContext.Provider
      value={{
        adminFee: state.adminFee,
        invoice: state.invoice,
        invoices: state.invoices,
        gqlInvoice: state.gqlInvoice,
        message: state.message,
        isPayed: state.isPayed,
        signature: state.signature,
        messageHash: state.messageHash,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        paidAmount: state.paidAmount,
        unPaidAmount: state.unPaidAmount,
        loading: state.loading,
        expireInvoice,
        signMessage,
        checkPayment,
        calculateFee,
        createInvoice,
        unpaidInvoice,
        allInvoice,
        getPaymentStatus,
        getCryptoSummary,
        getCryptoMultichainSummary,
        getFiatSummary,
        delete: _delete,
      }}
    >
      {props.children}
    </FiatContext.Provider>
  );
};

export default FiatState;
