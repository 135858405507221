import classNames from 'classnames'
import React from 'react'

const Input = ({
  className,
  appendleft,
  customPositionIcon,
  customPaddingInput,
  ...props
}) => {
  return (
    <div
      className={`inline-block relative ${className}
            ${classNames({
              'w-': className?.includes('2xl:w-72 lg:w-48 w-full'),
            })}`}
    >
      {
        appendleft && (
          // <div className="absolute left-3 top-3">
          <div
            className={`absolute ${classNames({
              'top-2.5': !customPositionIcon?.includes('top-'),
              'left-3': !customPositionIcon?.includes('left-'),
            })} ${customPositionIcon}`}
          >
            {appendleft}
          </div>
        )
        // </div>
      }
      <input
        {...props}
        className={`${className} ${customPaddingInput} w-full ${classNames({
          'w-full rounded-lg bg-white border border-divider focus:outline-none focus:ring-2 focus:ring-oranye focus:border-transparent':
            !className?.includes('border-'),
          'pl-16': appendleft && !customPaddingInput,
          'p-4': !className?.includes('p-'),
        })}`}
      />
    </div>
  )
}

export default Input
