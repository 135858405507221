import React, { useMemo } from 'react'

const Chips = ({
  selected,
  text,
  handleClick,
  rightIcon,
  customPadding,
  withIcon,
  icon: Icon,
}) => {
  const renderPx = useMemo(() => {
    if (withIcon) {
      return 'px-4 md:px-6'
    } else {
      return 'px-4 md:px-8'
    }
  }, [withIcon])
  return (
    <div
      onClick={handleClick}
      className={`cursor-pointer flex items-center ${renderPx} py-2 ${
        selected ? 'bg-hijau_soft border border-hijau_hutan' : 'bg-gray-100'
      } rounded-full`}
    >
      {withIcon && <Icon divClass="mr-2" />}
      <p
        className={`text-xs md:text-sm text-center font-poppins font-semibold whitespace-nowrap ${
          selected ? 'text-hijau_hutan' : 'text-gray-500'
        }`}
      >
        {text}
      </p>
      {rightIcon}
    </div>
  )
}

Chips.defaultProps = {
  selected: false,
  text: 'Text Chip',
  withIcon: false,
}

export default Chips
