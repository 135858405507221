import React from "react";

const TwitterIcon = ({ width = "22", height = "18", fill = "#FFFFFF", ...props }) => {
    return (
        <div {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.90002 0.399902C4.65325 0.399902 0.400024 4.65313 0.400024 9.8999C0.400024 15.1467 4.65325 19.3999 9.90002 19.3999C15.1468 19.3999 19.4 15.1467 19.4 9.8999C19.4 4.65313 15.1468 0.399902 9.90002 0.399902ZM13.7643 8.18199C13.7683 8.26313 13.7693 8.34428 13.7693 8.42344C13.7693 10.8974 11.8881 13.7484 8.44633 13.7484C7.42933 13.7501 6.43348 13.458 5.57851 12.9072C5.72398 12.9251 5.87341 12.932 6.02482 12.932C6.90159 12.932 7.7081 12.6341 8.34836 12.1314C7.95818 12.1238 7.58012 11.9946 7.26685 11.7618C6.95359 11.5291 6.72074 11.2044 6.60075 10.8331C6.88096 10.8864 7.16962 10.8752 7.44487 10.8004C7.02137 10.7148 6.64053 10.4853 6.36692 10.1509C6.09331 9.81653 5.94378 9.3978 5.94367 8.96574V8.94298C6.19601 9.08251 6.48497 9.16761 6.79174 9.17751C6.39472 8.91322 6.11367 8.50723 6.00606 8.04259C5.89845 7.57794 5.97242 7.08974 6.21284 6.67782C6.68284 7.25574 7.26898 7.72851 7.9333 8.06552C8.59761 8.40254 9.3253 8.59629 10.0692 8.63422C9.97466 8.23276 10.0154 7.81129 10.185 7.43535C10.3547 7.05941 10.6438 6.75005 11.0074 6.55537C11.3711 6.3607 11.7888 6.29161 12.1958 6.35885C12.6027 6.42609 12.976 6.62589 13.2577 6.92719C13.6764 6.84434 14.078 6.69074 14.4452 6.47298C14.3056 6.90655 14.0134 7.27471 13.6228 7.50907C13.9938 7.46439 14.356 7.36467 14.6975 7.21318C14.4467 7.58912 14.1306 7.91721 13.7643 8.18199Z" fill={fill} />
            </svg>
        </div>
    )
}

export default TwitterIcon