import { SET_LOADING, SET_INJECTED_PROVIDER, SET_LOCAL_CHAINID } from "../types";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_INJECTED_PROVIDER:
      return {
        ...state,
        injectedProvider: action?.payload,
        loading: false,
      };
    case SET_LOCAL_CHAINID:
      return {
        ...state,
        localChainId: action?.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer
