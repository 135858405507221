import {
  ALL_ASSET_AUCTION_,
  ALL_ASSET_AUCTION_BY_ASSETID,
  ALL_ASSET_AUCTION_IN_PROFILE,
  ALL_ASSET_AUCTION_MADE_IN_PROFILE,
  CREATE_ASSET_AUCTION,
  GET_ASSET_AUCTION_DETAIL,
  SET_LOADING,
  UPDATE_ASSET_AUCTION,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_ASSET_AUCTION:
      return {
        ...state,
        assetAuction: action.payload,
        loading: false
      };
    case GET_ASSET_AUCTION_DETAIL:
      return {
        ...state,
        assetAuction: action.payload,
        loading: false
      }
    case UPDATE_ASSET_AUCTION:
      return {
        ...state,
        assetAuction: action.payload,
        loading: false
      };
    case ALL_ASSET_AUCTION_BY_ASSETID:
      return {
        ...state,
        assetAuctionsByAsset: action.payload,
        assetAuctions: action.payload,
        loading: false
      };
    case ALL_ASSET_AUCTION_:
      return {
        ...state,
        assetAuctions: action.payload,
        loading: false
      };
    case ALL_ASSET_AUCTION_IN_PROFILE:
      return {
        ...state,
        assetAuctions: action.payload,
        pageInfo: action.pageInfo,
        totalCount: action.totalCount,
        loading: false
      };
    case ALL_ASSET_AUCTION_MADE_IN_PROFILE:
      return {
        ...state,
        assetMadeAuctions: action.payload,
        pageInfo: action.pageInfo,
        totalCount: action.totalCount,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
