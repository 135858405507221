import { useEffect, useReducer } from "react";
// import io from "socket.io-client";
import logger from "use-reducer-logger";
// import { ToastContainer, toast } from "react-toastify";

import SocketContext from "./socketContext";
import SocketReducer from "./socketReducer";
import {
  SET_LOADING,
  SET_MATIC_PRICE,
  ADD_BID_DATA,
} from "../types";
// import env from "../../config/env";
// import { NOTIFICATION_BUY, NOTIFICATION_MAKE_OFFER, NOTIFICATION_BID_AUCTION  } from "../../utils/constants/notificationTypes"
import api from "../../utils/api";

// const socket = io.connect(env.SOCKET_IO_URL, 
//   { 
//     path: env.DEV === "false" ? '/api/socket.io' : "/socket.io/",
//     transports: ['websocket', 'polling']
//   }
// )

const SocketState = (props) => {
  const initialState = {
    // socket,
    blockchain_price: {
      matic_price: 0,
      ether_price: 0,
      binance_price: 0,
    },
    bid: new Map(),
    notification: [],
    loading: false,
  };

  const [state, dispatch] = useReducer(logger(SocketReducer), initialState);

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  const setMaticPrice = (newPrice) => {
    setLoading();
    dispatch({
      type: SET_MATIC_PRICE,
      payload: newPrice,
    });
  };

  const addBidData = (newBidData) => {
    setLoading();
    dispatch({
      type: ADD_BID_DATA,
      payload: newBidData,
    });
  };

  // const addNotificationData = (newNotificationData) => {
  //   setLoading();
  //   dispatch({
  //     type: ADD_NOTIFICATION_DATA,
  //     payload: newNotificationData,
  //   });
  // };

  const fetchMatic = async () => {
    const getMatic = await api.get("/matic_price")
    const newMaticPrice = getMatic.data?.matic_price?.["matic-network"]?.idr
    const newEtherPrice = getMatic.data?.matic_price?.["ethereum"]?.idr
    const newBinacePrice = getMatic.data?.matic_price?.["binancecoin"]?.idr
    console.log("zap getMatic", getMatic.data)
    setMaticPrice({
      matic_price: newMaticPrice,
      ether_price: newEtherPrice,
      binance_price: newBinacePrice,
    })
  }

  useEffect(() => {
    // socket.on("matic_price", (data) => {
    //   const newPrice = JSON.parse(data);
    //   console.log("zap matic",newPrice)
    //   setMaticPrice(newPrice["matic-network"].idr);
    // });
    // socket.on("broadcastBid", (data) => {
    //   addBidData(data)
    // })
    /* eslint-disable */

    fetchMatic()
    
    const intervalId = setInterval(() => {
      fetchMatic()
      console.log("zap fetch matic")
    }, 60000)

    return () => clearInterval(intervalId)
  }, []);

  return (
    <SocketContext.Provider
      value={{
        // socket: state.socket,
        blockchain_price: state.blockchain_price,
        bid:state.bid,
        addBidData,
      }}
    >
      
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketState;
