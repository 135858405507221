// import env from "../config/env"

let navSidebar
let navTxn
// if (env.ALLOW_FIAT_PAYMENT) {
//   navSidebar = [
//     {
//       title: "Referensi",
//       content: [
//         {
//           name: 'Profil',
//           url: './profile',
//           collapse: false,
//         },
//         {
//           name: 'Rekening Bank',
//           url: './bank-account',
//         },
//         {
//           name: 'Notifikasi',
//           url: './notification',
//         },
//       ]
//     },
//     {
//       title: "Aktivitas Transaksi",
//       content: [
//         {
//           name: 'Semua',
//           url: './txn-activity-all',
//           collapse: false,
//         },
//         {
//           name: 'Tertunda',
//           url: './txn-activity-pending',
//         },
//       ]
//     },
//     {
//       title: "Pembayaran",
//       content: [
//         {
//           name: 'Aktif',
//           url: './active-purchase',
//         },
//         {
//           name: 'Berhasil',
//           url: './success-purchase#crypto',
//         },
//         {
//           name: 'Expired',
//           url: './expired-purchase',
//         },
//       ]
//     },
//     {
//       title: "Penjualan",
//       content: [
//         {
//           name: 'Sejarah Transaksi',
//           url: './transaction-history',
//         },
//         {
//           name: 'Sejarah Disbursement',
//           url: './disbursment-history',
//         },
//         /* {
//           name: 'Manajemen Voucher',
//           url: './voucher-management',
//         }, */
//       ]
//     }
//   ]
// } else {
//   navSidebar = [
//     {
//       title: "Referensi",
//       content: [
//         {
//           name: 'Profil',
//           url: './profile',
//           collapse: false,
//         },
//         {
//           name: 'Notifikasi',
//           url: './notification',
//         },
//       ]
//     },
//     {
//       title: "Pembayaran",
//       content: [
//         {
//           name: 'Berhasil',
//           url: './success-purchase',
//         },
//       ]
//     },
//     {
//       title: "Penjualan",
//       content: [
//         {
//           name: 'Sejarah Transaksi',
//           url: './transaction-history',
//         },
//       ]
//     }
//   ]
// }

navSidebar = [
  {
          title: "Pengaturan",
          content: [
            {
              name: 'Biodata',
              url: './profile',
              urlName: '/setting/profile',
              collapse: false,
            },
            {
              name: 'Rekening Bank',
              url: './bank-account',
              urlName: '/setting/bank-account',
            },
          ]
        },
]

// navSidebar = [
//   {
//           title: "Pengaturan",
//           content: [
//             {
//               name: 'Biodata',
//               url: './profile',
//               urlName: '/setting/profile',
//               collapse: false,
//             },
//             {
//               name: 'Rekening Bank',
//               url: './bank-account',
//               urlName: '/setting/bank-account',
//             },
//             {
//               name: 'Notifikasi',
//               url: './notification',
//               urlName: '/setting/notification',
//             },
//           ]
//         },
// ]

navTxn = [
  {
    title: "Transaksi Aset",
    content: [
      {
        name: 'Tertunda',
        url: './pending',
        urlName: '/history-txn/pending',
      },
      {
        name: 'Berhasil',
        url: './success',
        urlName: '/history-txn/success'
      },
      {
        name: 'Kadaluarsa',
        url: './expire',
        urlName: '/history-txn/expire'
      },
    ]
  },
  {
    title: "Sejarah Penerimaan",
    content: [
      {
        name: 'Ringkasan',
        url: './summary-disburse',
        urlName: '/history-txn/summary-disburse'
      },
      {
        name: 'Sejarah Transfer',
        url: './list-disburse',
        urlName: '/history-txn/list-disburse'
      },
    ]
  },
]
export {navSidebar, navTxn}