import React, { useMemo } from 'react'
import { AvatarPattern } from '../../assets'
import { SMALL_THUMBNAIL_IMAGE_TYPE } from '../../utils/constants/renderImgTypes'

const Avatar = ({thumbnailUser, handleClick, customSpace}) => {
   const renderThumbnail = useMemo(() => {
        if(thumbnailUser){
            return thumbnailUser +SMALL_THUMBNAIL_IMAGE_TYPE
        } else {
            return AvatarPattern
        }
   }, [thumbnailUser])
   return (
    <div className={`h-10 w-10 overflow-hidden rounded-full border-2 border-white shadow-lg cursor-pointer ${customSpace}`} onClick={handleClick}>
        <img src={renderThumbnail} alt='' className='w-full h-full'/>
    </div>
  )
}

Avatar.defaultProps = {
    handleClick: () => {}
}

export default Avatar