import { useReducer } from 'react';
import ProjectUtilContext from './projectUtilContext';
import projectReducer from './projectUtilReducer';
import { post } from '../../utils/graphql-api';
import api from '../../utils/api';
import logger from 'use-reducer-logger';
import {
  ADD_SELECTED_PROJECT_ASSET,
  CREATE_DRAFT_PROJECT,
  DRAFT_SELECTED_PROJECT_ASSET,
  EDIT_DRAFT_PROJECT,
  GET_LIST_PROJECT_GUEST,
  GET_PROJECT,
  PREVIEW_IMAGE_ASSET_PROJECT,
  REMOVE_DRAFT_SELECTED_PROJECT_ASSET,
  REMOVE_DRAFT_SELECTED_PROJECT_ASSET_API,
  REMOVE_SELECTED_PROJECT_ASSET,
  SET_LOADING,
  SET_SELECTED_PROJECT_ASSET,
  UPDATE_DRAFT_PROJECT,
  UPLOAD_PHOTO_PROJECT,
  UPLOAD_XLSX_PROJECT
} from '../types';
import apiUpload from '../../utils/apiUpload';

const ProjectState = (props) => {
  const initialState = {
    all_project_util: [],
    selectedAsset: [],
    draftSelectedAsset: [],
    publishedAsset: [],
    utilityProjectsAssetsByUtilityProjectId: [],
    previewImage: {},
    project: {},
    totalCount: 0,
    pageInfo: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(projectReducer), initialState);
  const setLoading = () => dispatch({ type: SET_LOADING });
  const getAllProject = async (params) => {
    setLoading();
    const res = await post('projekUtilitasDashboard', { ...params });
    // const res = await post("projekUtilitasDashboard");
    await dispatch({
      type: GET_LIST_PROJECT_GUEST,
      payload: res?.data?.data?.allUtilityProjects?.nodes,
      totalCount: res?.data?.data?.allUtilityProjects?.totalCount,
      pageInfo: res?.data?.data?.allUtilityProjects?.pageInfo
    });
    return res.data.data.allUtilityProjects;
  };
  const getAllProjectGst = async (params) => {
    setLoading();
    const res = await post('projekUtilitasDashboardGst', { ...params });
    // const res = await post("projekUtilitasDashboard");
    await dispatch({
      type: GET_LIST_PROJECT_GUEST,
      payload: res?.data?.data?.getActiveUtilityProjects?.nodes,
      totalCount: res?.data?.data?.getActiveUtilityProjects?.totalCount,
      pageInfo: res?.data?.data?.getActiveUtilityProjects?.pageInfo
    });
    return res?.data?.data?.getActiveUtilityProjects;
  };
  const getProject = async (projectId) => {
    setLoading();
    const res = await post('getAllUtilityProjectsGst', { projectId });
    await dispatch({
      type: GET_PROJECT,
      payload: res.data.data.allUtilityProjects?.nodes?.[0]
    });
    return res.data.data.allUtilityProjects?.nodes?.[0];
  };

  const getProjectOwner = async (projectId) => {
    setLoading();
    const res = await post('getAllUtilityProjects', { projectId });
    await dispatch({
      type: GET_PROJECT,
      payload: res.data.data.allUtilityProjects?.nodes?.[0]
    });
    return res.data.data.allUtilityProjects?.nodes?.[0];
  };
  const create = async (params) => {
    setLoading();
    const res = await api.post('utilities/create_draft_project', { ...params });
    await dispatch({
      type: CREATE_DRAFT_PROJECT
    });
    return res.data.utilityProject;
  };

  const update = async (projectId, params) => {
    setLoading();
    const res = await api.post('utilities/update_detail_project', { ...params, projectId });
    await dispatch({
      type: UPDATE_DRAFT_PROJECT
    });
    return res.data.utilityProject;
  };

  const uploadXLXS = async (input) => {
    setLoading();
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };
    const res = await apiUpload.post('/utilities/upload_xlsx', input, config);
    await dispatch({
      type: UPLOAD_XLSX_PROJECT,
      payload: res?.data
    });
    return res?.data;
  };

  const uploadProject = async (input) => {
    setLoading();
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };
    const res = await apiUpload.post('/utilities/upload_project_photo', input, config);
    await dispatch({
      type: UPLOAD_PHOTO_PROJECT,
      payload: res?.data
    });
    return res?.data;
  };

  const addSelectedAsset = async (asset) => {
    await dispatch({
      type: ADD_SELECTED_PROJECT_ASSET,
      payload: asset
    });
  };
  const removeSelectedAsset = async (id) => {
    await dispatch({
      type: REMOVE_SELECTED_PROJECT_ASSET,
      payload: id
    });
  };
  const setSelectedAsset = async (assets) => {
    await dispatch({
      type: SET_SELECTED_PROJECT_ASSET,
      payload: assets
    });
  };
  const draftedSelectedAsset = async () => {
    await dispatch({
      type: DRAFT_SELECTED_PROJECT_ASSET
    });
  };
  const removeDraftedAsset = async (id) => {
    await dispatch({
      type: REMOVE_DRAFT_SELECTED_PROJECT_ASSET,
      payload: id
    });
  };
  const updateDraftProject = async (body) => {
    await api.post('utilities/update_draft_project', { ...body });
    await dispatch({
      type: UPDATE_DRAFT_PROJECT
    });
  };
  const updatePublishedProject = async (body) => {
    await api.post('utilities/edit_project', { ...body });
    await dispatch({
      type: EDIT_DRAFT_PROJECT
    });
  };

  const deleteNFT = async (body) => {
    await api.post('utilities/delete_asset_utilities', { ...body });
    await dispatch({
      type: REMOVE_DRAFT_SELECTED_PROJECT_ASSET_API
    });
  };

  const setPreviewImage = async (asset) => {
    await dispatch({
      type: PREVIEW_IMAGE_ASSET_PROJECT,
      payload: asset
    });
  };

  const deleteProjectUsers = async (id) => {
    const body = {
      utilityProjectsUserId: id
    };
    const res = await api.post('utilities/delete_utility_project_user', body);
    return res;
  };

  const resetPasswordUser = async (id, password) => {
    const body = {
      utilityProjectsUserId: id,
      password: password
    };
    const res = await api.post('utilities/reset_project_user_password', body);
    return res;
  };

  const addProjectUser = async (utilityProjectId, username, password) => {
    const body = {
      utilityProjectId,
      username,
      password
    };
    const res = await api.post('utilities/add_utility_project_user', body);
    return res;
  };

  return (
    <ProjectUtilContext.Provider
      value={{
        all_project_util: state.all_project_util,
        pageInfo: state.pageInfo,
        totalCount: state.totalCount,
        selectedAsset: state.selectedAsset,
        draftSelectedAsset: state.draftSelectedAsset,
        project: state.project,
        previewImage: state.previewImage,
        utilityProjectsAssetsByUtilityProjectId: state.utilityProjectsAssetsByUtilityProjectId,
        publishedAsset: state.publishedAsset,
        loading: state.loading,
        setLoading,
        getAllProject,
        getAllProjectGst,
        uploadXLXS,
        uploadProject,
        create,
        update,
        addSelectedAsset,
        removeSelectedAsset,
        getProject,
        draftedSelectedAsset,
        updateDraftProject,
        updatePublishedProject,
        setPreviewImage,
        removeDraftedAsset,
        setSelectedAsset,
        deleteNFT,
        deleteProjectUsers,
        getProjectOwner,
        resetPasswordUser,
        addProjectUser
      }}>
      {props.children}
    </ProjectUtilContext.Provider>
  );
};

export default ProjectState;
