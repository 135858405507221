import React from "react";

const ChevronDownNew = ({ viewBox, size, onClick, divClass, color }) => {
  return (
    <div onClick={onClick} className={divClass}>
      <svg
        viewBox={`0 0 ${viewBox} ${viewBox}`}
        className={`h-${size} w-${size} fill-current`}
      >
        <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" fill={color}/>
      </svg>
    </div>
  );
};

ChevronDownNew.defaultProps = {
  viewBox: 24,
  size: 6,
  onClick: () => {},
  color: "#868686",
};

export default ChevronDownNew;
