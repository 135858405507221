import React from "react";

const BecomePartnerIcon = ({viewBox, size, onClick, color, ...props}) => {
    return (
        <div onClick={onClick} {...props}>
            <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
            <path d="M11.3317 20.25C11.2447 20.2499 11.1584 20.2347 11.0767 20.205C10.919 20.1473 10.7852 20.0383 10.6968 19.8955C10.6085 19.7527 10.5705 19.5844 10.5892 19.4175L11.1667 14.1H6.74918C6.61293 14.1002 6.47919 14.0633 6.36234 13.9932C6.24549 13.9232 6.14993 13.8226 6.08593 13.7023C6.02193 13.582 5.99191 13.4466 5.99909 13.3105C6.00627 13.1744 6.05038 13.0429 6.12668 12.93L12.0442 4.08001C12.1378 3.94152 12.2749 3.83825 12.4339 3.78662C12.5929 3.73498 12.7646 3.73794 12.9217 3.79501C13.0727 3.85108 13.2017 3.9543 13.2895 4.08938C13.3773 4.22446 13.4193 4.38422 13.4092 4.54501L12.8317 9.90001H17.2492C17.3854 9.89981 17.5192 9.93672 17.636 10.0068C17.7529 10.0769 17.8484 10.1774 17.9124 10.2977C17.9764 10.418 18.0064 10.5535 17.9993 10.6895C17.9921 10.8256 17.948 10.9571 17.8717 11.07L11.9542 19.92C11.8854 20.0218 11.7926 20.1051 11.6841 20.1627C11.5755 20.2202 11.4545 20.2502 11.3317 20.25V20.25ZM8.15168 12.6H11.9992C12.1038 12.6003 12.2073 12.6224 12.3029 12.665C12.3985 12.7077 12.4841 12.7698 12.5542 12.8475C12.625 12.9263 12.6783 13.0191 12.7106 13.1199C12.7429 13.2208 12.7535 13.3273 12.7417 13.4325L12.4042 16.545L15.8467 11.4H11.9992C11.8933 11.4007 11.7886 11.3789 11.6917 11.3361C11.5949 11.2934 11.5082 11.2307 11.4374 11.152C11.3666 11.0734 11.3132 10.9807 11.2807 10.8799C11.2483 10.7792 11.2375 10.6727 11.2492 10.5675L11.5867 7.45501L8.15168 12.6Z" fill={color}/>
            </svg>
        </div>
    )
}

BecomePartnerIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: "#868686",
}

export default BecomePartnerIcon