import React, { Suspense, useEffect, useState, useContext } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import authContext from "../../context/Auth/authContext";
import env from "../../config/env";

import routes from "../../router/routes";
import LayoutHeader from "./LayoutHeader";
import SpinCircleLogo from '../../assets/animation/spin_circle_logo';
import ModalConnectWallet from '../../component/Modal/ModalConnectWallet';
import ModalChangeAccount from '../../component/Modal/ModalChangeAccount';
import ModalChangeNetwork from '../../component/Modal/ModalChangeNetwork';
import ModalWrongAccount from '../../component/Modal/ModalWrongAccount';
import ModalLogin from '../../component/Modal/ModalLogin';
import ReactGa from "react-ga"
import { readVersionTextFile } from "../../utils/helper";

function usePageViews() {
  
  let location = useLocation()
  useEffect(() => {
    
    if (!window.GA_INITIALIZED) {
      ReactGa.initialize(env.GA_TRACKING_ID)
      window.GA_INITIALIZED = true
    }
    ReactGa.set({ page: location.pathname })
    ReactGa.pageview(location.pathname)
  }, [location])
}

function Layout(props) {
  let location = useLocation()
  const [routeComponent, setRouteComponent] = useState()
  const AuthContext = useContext(authContext);
  usePageViews()
  useEffect(()=>{

    async function checkVersion(){
      const {version} = await AuthContext.getCurrentVersion();
      console.log('zap current version',version,readVersionTextFile());
      if(readVersionTextFile() !== version){
        window.location.reload();
      }
    }
    checkVersion()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location])

  const setRoute = (data) =>
    data.map((prop, key) => prop?.children ?
      <Route
        path={prop.path}
        key={key}
        element={
          <prop.component  {...props} />
        }
      >
        {setRoute(prop?.children)}
      </Route>
      : <Route
        path={prop.path}
        key={key}
        element={
          <prop.component  {...props} />
        }
      />)

  useEffect(() => {
    setRouteComponent(setRoute(routes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <LayoutHeader {...props} />
      <Suspense fallback={
          <div className="min-h-screen flex justify-center items-center">
            <SpinCircleLogo />
          </div>
        }>
        <div className="overflow-visible">

        <Routes >
          {routeComponent}

          <Route path="/" element={<Navigate replace to="/home" />} />
        </Routes >

        <ModalConnectWallet backdrop={false} show={AuthContext.connWallet} setShow={AuthContext.setConnWallet} id="modal-connect-wallet"/>
        <ModalChangeNetwork backdrop={false} show={AuthContext.changeNetwork} setShow={AuthContext.setChangeNetwork} />
        <ModalChangeAccount backdrop={false} show={AuthContext.changeAccount} />
        <ModalWrongAccount backdrop={false} show={AuthContext.wrongAccount} setShow={AuthContext.setWrongAccount} />
        <ModalLogin backdrop={false} show={AuthContext.metamaskLoading} />
        </div>
      </Suspense>
      {/* <LayoutFooter {...props} /> */}
    </>
  )
}

export default Layout