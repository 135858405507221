import React from "react";

const SortIcon = ({viewBox, size, onClick, color}) => {
    return (
        <div onClick={onClick}>
           <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
           <path d="M3 18H9V16H3V18ZM3 6V8H21V6H3ZM3 13H15V11H3V13Z" fill={color}/>
            </svg>
        </div>
    )
}

SortIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: '#3C3C3B'
};

export default SortIcon