import React from 'react'

import RoundedButton from '../Button/RoundedButton'
import ButtonText from '../Text/ButtonText'
// import ButtonLink from "../Button/ButtonLink";

function ButtonModal({
  type,
  okLabel = 'Hapus',
  noLabel = 'Tidak',
  onNoClick = () => {},
  onOkClick = () => {},
  buttonTextSyle = '',
  buttonStyle = 'px-10 py-4 w-auto',
  onClick,
  tx,
  children,
  colorSubmitBtn = 'bg-hijau_hutan',
  setShow = () => {},
  ...rest
}) {
  if (type === 'confirmation') {
    return (
      <div
        id="wrapper-btn-confirmation"
        className="flex flex-row gap-x-4 self-center"
      >
        <RoundedButton
          {...rest}
          onClick={onNoClick}
          className={`border ${
            rest?.disabled ? 'border-transparent' : 'border-hijau_hutan'
          }  ${buttonStyle}`}
          color={rest?.disabled ? 'bg-gray-200' : 'bg-white'}
        >
          <ButtonText
            classstyle={`font-bold text-sm ${buttonTextSyle}`}
            tx={noLabel}
            color={rest?.disabled ? 'text-white' : 'text-hijau_hutan'}
          />
        </RoundedButton>
        <RoundedButton
          {...rest}
          onClick={onOkClick}
          color={rest?.disabled ? 'bg-gray-200' : colorSubmitBtn}
          className={`${buttonStyle}`}
          id="confirmation-button-continue"
          name="confirmation-button-continue"
        >
          <ButtonText
            classstyle={`font-bold text-sm ${buttonTextSyle}`}
            tx={okLabel}
          />
        </RoundedButton>
      </div>
    )
  }
  return (
    <RoundedButton
      {...rest}
      onClick={onClick}
      className={`${buttonStyle}`}
      id="button-continue"
      name="button-modal-continue"
    >
      <div id="wrapper-btn-children" className="inline-flex items-center">
        <ButtonText
          classstyle={`font-bold text-sm ${buttonTextSyle}`}
          text={tx}
        />
        {children}
      </div>
    </RoundedButton>
  )
}

export default ButtonModal
