import React, { useReducer } from 'react';
import AssetAuctionContext from './assetAuctionContext';
import AssetAuctionReducer from './assetAuctionReducer';
import { post } from '../../utils/graphql-api';
import { ALL_ASSET_AUCTION_, ALL_ASSET_AUCTION_BY_ASSETID, ALL_ASSET_AUCTION_IN_PROFILE, ALL_ASSET_AUCTION_MADE_IN_PROFILE, CREATE_ASSET_AUCTION, GET_ASSET_AUCTION_DETAIL, SET_LOADING, UPDATE_ASSET_AUCTION } from '../types';
import logger from 'use-reducer-logger';

const AssetAuctionDetailState = props => {
  const initialState = {
    assetAuctions: [],
    assetMadeAuctions: [],
    assetAuctionsByAsset: [],
    assetAuction: {},
    totalCount: 0,
    pageInfo: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(AssetAuctionReducer), initialState);

  const createAssetAuction = async (assetInput) => {
    setLoading();
    const res = await post("createAssetAuction", { ...assetInput });
    dispatch({
      type: CREATE_ASSET_AUCTION,
      payload: res.data.data.createAssetsAuction.assetsAuction
    });
    return res.data.data.createAssetsAuction.assetsAuction

  }
  const update = async (id, patch) => {
    setLoading();
    const res = await post("updateAssetsAuctionById", { id, patch })
    dispatch({
      type: UPDATE_ASSET_AUCTION,
      payload: res.data.data.updateAssetsAuctionById.assetsAuction
    })
    return res.data.data.updateAssetsAuctionById.assetsAuction
  }

  const getAllAssetsAuctions = async () => {
    setLoading();
    const res = await post("getAllAssetsAuction")
    dispatch({
      type: ALL_ASSET_AUCTION_,
      payload: res.data.data.allAssetsAuctions.nodes
    })
    return res.data.data.allAssetsAuctions.nodes
  }

  const get = async (id) => {
    setLoading();
    const res = await post("getAssetsAuctionById", { id })
    dispatch({
      type: GET_ASSET_AUCTION_DETAIL,
      payload: res?.data?.data?.assetsAuctionById
    })

    return res?.data?.data?.assetsAuctionById

  }

  const getAllAssetsAuctionsInProfile = async (option) => {
    setLoading();
    const res = await post("auctionInProfile", { ...option })
    dispatch({
      type: ALL_ASSET_AUCTION_IN_PROFILE,
      payload: res?.data?.data?.allAssetsAuctions?.nodes,
      totalCount: res?.data?.data?.allAssetsAuctions?.totalCount,
      pageInfo: res?.data?.data?.allAssetsAuctions?.pageInfo
    })
    return res?.data?.data?.allAssetsAuctions?.nodes
  }
  const getAuctionByAssetId = async (assetId, sellerAccount) => {
    setLoading();
    const res = await post("getAuctionByAssetId", { assetId, sellerAccount });
    dispatch({
      type: ALL_ASSET_AUCTION_BY_ASSETID,
      payload: res?.data?.data?.allAssetsAuctions?.nodes
    });
    return res?.data?.data?.allAssetsAuctions?.nodes
  }

  const getAuctionMade = async (option) => {
    setLoading();
    const res = await post("auctionMadeInProfile", { ...option });
    dispatch({
      type: ALL_ASSET_AUCTION_MADE_IN_PROFILE,
      payload: res?.data?.data?.auctionMadeInProfile?.nodes,
      totalCount: res?.data?.data?.auctionMadeInProfile?.totalCount,
      pageInfo: res?.data?.data?.auctionMadeInProfile?.pageInfo,
    });
    return res?.data?.data?.auctionMadeInProfile?.nodes
  }
  
  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <AssetAuctionContext.Provider
      value={{
        assetAuctions: state.assetAuctions,
        assetAuction: state.assetAuction,
        assetAuctionsByAsset: state.assetAuctionsByAsset,
        pageInfo: state.pageInfo,
        totalCount: state.totalCount,
        loading: state.loading,
        createAssetAuction,
        getAllAssetsAuctions,
        getAuctionByAssetId,
        getAuctionMade,
        update,
        getAllAssetsAuctionsInProfile,
        get,
      }}
    >
      {props.children}
    </AssetAuctionContext.Provider>
  );
};

export default AssetAuctionDetailState;
