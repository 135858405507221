import React, { useMemo } from 'react'
import BinanceIcon from '../../assets/icon/binance_icon'
import EthereumIcon from '../../assets/icon/ethereum_icon'
import MaticIcon from '../../assets/icon/matic_icon'
import {
  BNC_MAINNET,
  BNC_TESTNET,
  ETHEREUM_MAINNET,
  ETHEREUM_RINKEBY,
  POLYGON_MAINNET,
  POLYGON_MUMBAI,
} from '../../utils/constants/metamaskConfig'

const BadgesNetwork = ({ chainId }) => {
  const renderItem = useMemo(() => {
    switch (true) {
      case chainId === POLYGON_MAINNET.chainId:
        return { name: 'Polygon Mainnet', icon: <MaticIcon size={4} /> }
      case chainId === POLYGON_MUMBAI.chainId:
        return { name: 'Polygon Mumbai', icon: <MaticIcon size={4} /> }
      case chainId === ETHEREUM_MAINNET.chainId:
        return { name: 'Ethereum Mainnet', icon: <EthereumIcon size={4} /> }
      case chainId === ETHEREUM_RINKEBY.chainId:
        return { name: 'Ethereum Rinkeby', icon: <EthereumIcon size={4} /> }
      case chainId === BNC_MAINNET.chainId:
        return { name: 'Binance Mainnet', icon: <BinanceIcon size={4} /> }
      case chainId === BNC_TESTNET.chainId:
        return { name: 'Binance Testnet', icon: <BinanceIcon size={4} /> }
      default:
        return { name: 'Other Network', icon: <MaticIcon size={4} /> }
    }
  }, [chainId])
  return (
    <div className="flex gap-x-1 items-center">
      {/* {renderItem.icon} */}
      <p className="font-poppins text-xs text-teks_2">{renderItem.name}</p>
    </div>
  )
}

export default BadgesNetwork
