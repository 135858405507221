import React from 'react'
import { useTranslation } from 'react-i18next'

const ModalTitleText = props => {
  const { tx, text, children, classstyle } = props
  const { t } = useTranslation()
  const translatedText = tx && t(tx)
  const content = translatedText || text || children

  return (
    <p
      className={`text-teks font-poppins font-semibold md:text-2xl text-lg mb-6 ${classstyle}`}
    >
      {content}
    </p>
  )
}

export default ModalTitleText
