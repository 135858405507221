import Modal from "./Modal";
import CardModal from "../Card/CardModal";
import ModalTitleText from "../Text/ModalTitleText";
// import RoundedButton from "../Button/RoundedButton";
// import ButtonText from "../Text/ButtonText";
// import SpinCircleLogo from "../../assets/animation/spin_circle_logo";
import P from "./P";

import {
  POLYGON_MAINNET,
  POLYGON_MUMBAI,
  ETHEREUM_RINKEBY,
  BNC_TESTNET
} from "../../utils/constants/metamaskConfig";
import env from "../../config/env";
import ButtonModal from "./ButtonModal";
import ExclamationIcon from "../../assets/icon/exclamation_icon";
import { BLOCKCHAIN_BINANCE, BLOCKCHAIN_ETHERIUM, BLOCKCHAIN_POLYGON } from "../../utils/constants/blockChainTypes";
import { getNetworkDetail } from "../../utils/helper";

const ModalChangeNetwork = (props) => {
  const changeNetwork = async () => {
    const ethereum = window.ethereum;
    const {
      mumbaiChainId, chainName, nativeCurrency, rpcUrls, blockExplorerUrls
    } = getNetworkDetail(props?.blockchainType)
    const data = [
      {
        chainId: "0x" + mumbaiChainId.toString(16),
        chainName,
        nativeCurrency,
        rpcUrls,
        blockExplorerUrls,
      },
    ];
    // let switchTx;
    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
    try {
      /* switchTx = */ await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: data[0].chainId }],
      });
    } catch (switchError) {
      // not checking specific error code, because maybe we're not using MetaMask
      try {
        /* switchTx = */ await ethereum.request({
          method: "wallet_addEthereumChain",
          params: data,
        });
      } catch (addError) {
        // handle "add" error
      }
    }
  };

  return (
    <Modal {...props}>
      <CardModal>
        <ModalTitleText text="Ubah Jaringan" />
        <ExclamationIcon width="100" height="100" />
        <P>
          Jaringan yang sedang terhubung dengan anda saat ini salah. Harap tekan
          tombol dibawah ini untuk mengganti jaringan yang kami dukung yaitu
          Polygon
        </P>
        <ButtonModal onClick={changeNetwork} tx="Ubah Jaringan" />
      </CardModal>
    </Modal>
  );
};

export default ModalChangeNetwork;
