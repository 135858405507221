import {
  CREATE_ASSET_PREPROCESS_FIAT, SET_LOADING, UPDATE_METADATA_PREPROCESS_CRYPTO,
  } from '../types';
  
  const reducer = (state, action) => {
    switch (action.type) {
      case CREATE_ASSET_PREPROCESS_FIAT:
        return {
          ...state,
          dataAsset: {},
          invoiceUrl: "",
          createInvoice: {},
          txnData: {},
          loadingTxn: false
        };
      case UPDATE_METADATA_PREPROCESS_CRYPTO:
        return{
          ...state,
          txnData: action?.payload?.txnData,
          loadingTxn: false
        }

      case SET_LOADING:
        return {
          ...state,
          loading: true
        };
      default:
        return state;
    }
  };
  
  export default reducer