import React, { useReducer } from 'react';
import ExclusivePartnerContext from './exclusivePartnerContext';
import ExclusivePartnerReducer from './exclusivePartnerReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { CREATE_COLLECTION_VERIFICATION, SET_LOADING } from '../types';
import logger from 'use-reducer-logger';

const ExclusivePartnerState = props => {
  const initialState = {
    exclusivePartners: [],
    exclusivePartner: {},
    totalCount: 0,
    pageInfo: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(ExclusivePartnerReducer), initialState);


  // request Collection Verification
  const requestExclusivePartner = async (input) => {
    setLoading();
    try {
      const res = await post("createExclusivePartnerRequest", { ...input });
      dispatch({
        type: CREATE_COLLECTION_VERIFICATION,
        payload: res.data.data.allCollections.nodes
      });
    } catch (error) {
      console.log('Err:', error);
    }
  };

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <ExclusivePartnerContext.Provider
      value={{
        exclusivePartners: state.exclusivePartners,
        exclusivePartner: state.exclusivePartner,
        loading: state.loading,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        requestExclusivePartner,
      }}
    >
      {props.children}
    </ExclusivePartnerContext.Provider>
  );
};

export default ExclusivePartnerState;
