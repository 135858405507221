import React from "react";

const UserIcon = ({viewBox, size, onClick, color, ...props}) => {
    return (
        <div onClick={onClick} {...props}>
            <svg  viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
            <path d="M12.4868 10.5556C13.135 10.5556 13.7688 10.3633 14.3078 10.0032C14.8468 9.64298 15.2669 9.13107 15.515 8.53213C15.7631 7.9332 15.828 7.27414 15.7016 6.63832C15.5751 6.00249 15.2629 5.41845 14.8045 4.96004C14.3461 4.50163 13.7621 4.18946 13.1262 4.06298C12.4904 3.93651 11.8313 4.00142 11.2324 4.24951C10.6335 4.49759 10.1216 4.91772 9.76139 5.45674C9.40122 5.99577 9.20898 6.6295 9.20898 7.27778C9.20898 8.1471 9.55432 8.98082 10.169 9.59552C10.7837 10.2102 11.6174 10.5556 12.4868 10.5556ZM12.4868 5.63889C12.8109 5.63889 13.1278 5.73501 13.3973 5.91509C13.6668 6.09518 13.8769 6.35114 14.0009 6.6506C14.1249 6.95007 14.1574 7.2796 14.0942 7.59751C14.0309 7.91542 13.8748 8.20745 13.6456 8.43665C13.4164 8.66585 13.1244 8.82194 12.8065 8.88518C12.4886 8.94841 12.1591 8.91596 11.8596 8.79192C11.5601 8.66787 11.3042 8.45781 11.1241 8.1883C10.944 7.91878 10.8479 7.60192 10.8479 7.27778C10.8479 6.84312 11.0205 6.42626 11.3279 6.11891C11.6352 5.81156 12.0521 5.63889 12.4868 5.63889Z" fill={color}/>
            <path d="M12.4861 12.1945C10.9648 12.1945 9.5058 12.7988 8.43007 13.8745C7.35434 14.9503 6.75 16.4093 6.75 17.9306C6.75 18.1479 6.83633 18.3563 6.99001 18.51C7.14369 18.6637 7.35211 18.75 7.56944 18.75C7.78677 18.75 7.9952 18.6637 8.14888 18.51C8.30255 18.3563 8.38889 18.1479 8.38889 17.9306C8.38889 16.8439 8.82056 15.8018 9.58894 15.0334C10.3573 14.265 11.3995 13.8333 12.4861 13.8333C13.5728 13.8333 14.6149 14.265 15.3833 15.0334C16.1517 15.8018 16.5833 16.8439 16.5833 17.9306C16.5833 18.1479 16.6697 18.3563 16.8233 18.51C16.977 18.6637 17.1854 18.75 17.4028 18.75C17.6201 18.75 17.8285 18.6637 17.9822 18.51C18.1359 18.3563 18.2222 18.1479 18.2222 17.9306C18.2222 16.4093 17.6179 14.9503 16.5422 13.8745C15.4664 12.7988 14.0074 12.1945 12.4861 12.1945V12.1945Z" fill={color}/></svg>
        </div>
    )
}
UserIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: "#868686",
}
export default UserIcon