import {
  GET_ALL_BARTER,
  GET_BARTER,
  GET_BY_OWNER_BARTER,
  GET_REQUEST_BARTER,
  RESET_PARAM_BARTER,
  SET_LOADING,
  SET_OWNED_ASSET_BARTER,
  SET_PARAM_BARTER,
  SET_REQUESTED_ASSET_BARTER,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case GET_ALL_BARTER:
      return{
        ...state,
        barters: action.payload?.nodes,
        loading: false,
        totalCount: action.payload?.totalCount,
        pageInfo: action.payload?.pageInfo,
      }
    case GET_BY_OWNER_BARTER:
      return{
        ...state,
        barters: action.payload?.nodes,
        loading: false,
        totalCount: action.payload?.totalCount,
        pageInfo: action.payload?.pageInfo,
      }
    case GET_REQUEST_BARTER:
      return{
        ...state,
        requestBarters: action.payload?.nodes,
        loading: false,
        totalCount: action.payload?.totalCount,
        pageInfo: action.payload?.pageInfo,
      }
    case SET_OWNED_ASSET_BARTER:
      return {
        ...state,
        ownedAssetBarter: action.payload,
      }

    case SET_REQUESTED_ASSET_BARTER:
      return {
        ...state,
        requestedAssetbarter: action.payload,
      }
    case SET_PARAM_BARTER:
      if (action?.step === 1) {
        return {
          ...state,
          requestedAssetbarter: action.payload
        }
      } else if(action?.step === 2){
        return {
          ...state,
          ownedAssetBarter: action.payload
        }
      } else {
        return {
          ...state,
          barter: action.payload.assetBarter,
          requestedAssetbarter: action.payload.dataParam,
          idBarter: action.payload.idBarter
        }
      }
    case RESET_PARAM_BARTER:
      return {
        ...state,
        barterParameter: { assetSelected: null, assetBarter: null },
        loading: false
      }
    case GET_BARTER:
      return{
        ...state,
        loading: false,
        barter: action.payload
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
