import React from "react";

const MaticIcon = ({viewBox, size, onClick, divClass, color}) => {
    return (
        <div onClick={onClick} className={divClass}>
           <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
           <path fillRule="evenodd" clipRule="evenodd" d="M12 2C17.5235 2 22 6.47651 22 12C22 17.5235 17.5235 22 12 22C6.47651 22 2 17.5235 2 12C2 6.47651 6.47651 2 12 2Z" fill="#794FDD"/>
<path d="M15.2721 9.76054C15.036 9.61506 14.726 9.61506 14.466 9.76054L12.6177 10.8791L11.3608 11.6089L9.51252 12.7274C9.27641 12.8729 8.96637 12.8729 8.70642 12.7274L7.2373 11.8521C7.00119 11.7066 6.83425 11.4395 6.83425 11.1462V9.4195C6.83425 9.12854 6.97734 8.85904 7.2373 8.71356L8.68257 7.86214C8.91868 7.71666 9.22872 7.71666 9.48867 7.86214L10.9339 8.71356C11.17 8.85904 11.337 9.12615 11.337 9.4195V10.538L12.5938 9.78439V8.66586C12.5938 8.3749 12.4508 8.10541 12.1908 7.95992L9.51252 6.35487C9.27641 6.20939 8.96637 6.20939 8.70642 6.35487L5.98045 7.95992C5.72049 8.10541 5.57739 8.37252 5.57739 8.66586V11.9022C5.57739 12.1932 5.72049 12.4627 5.98045 12.6081L8.70642 14.2132C8.94252 14.3587 9.25257 14.3587 9.51252 14.2132L11.3608 13.1185L12.6177 12.3649L14.466 11.2702C14.7021 11.1247 15.0122 11.1247 15.2721 11.2702L16.7174 12.1216C16.9535 12.2671 17.1204 12.5342 17.1204 12.8276V14.5542C17.1204 14.8452 16.9773 15.1147 16.7174 15.2602L15.2721 16.1355C15.036 16.2809 14.726 16.2809 14.466 16.1355L13.0208 15.284C12.7846 15.1386 12.6177 14.8714 12.6177 14.5781V13.4596L11.3608 14.2132V15.3317C11.3608 15.6227 11.5039 15.8922 11.7639 16.0377L14.4899 17.6427C14.726 17.7882 15.036 17.7882 15.296 17.6427L18.0219 16.0377C18.258 15.8922 18.425 15.6251 18.425 15.3317V12.0954C18.425 11.8044 18.2819 11.5349 18.0219 11.3895L15.2721 9.75816V9.76054Z" fill="white"/>
            </svg>
        </div>
    )
}

MaticIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: '#3C3C3B'
};

export default MaticIcon