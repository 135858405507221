import React from "react";

const BuyIcon = ({viewBox, size, onClick, color, ...props}) => {
    return (
        <div onClick={onClick} {...props}>
            <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
                <path d="M16.5 5.25H7.5C6.90326 5.25 6.33097 5.48705 5.90901 5.90901C5.48705 6.33097 5.25 6.90326 5.25 7.5V16.5C5.25 17.0967 5.48705 17.669 5.90901 18.091C6.33097 18.5129 6.90326 18.75 7.5 18.75H16.5C17.0967 18.75 17.669 18.5129 18.091 18.091C18.5129 17.669 18.75 17.0967 18.75 16.5V7.5C18.75 6.90326 18.5129 6.33097 18.091 5.90901C17.669 5.48705 17.0967 5.25 16.5 5.25ZM7.5 6.75H16.5C16.6989 6.75 16.8897 6.82902 17.0303 6.96967C17.171 7.11032 17.25 7.30109 17.25 7.5V13.77L14.85 11.7225C14.4781 11.4165 14.0116 11.2492 13.53 11.2492C13.0484 11.2492 12.5819 11.4165 12.21 11.7225L6.75 16.275V7.5C6.75 7.30109 6.82902 7.11032 6.96967 6.96967C7.11032 6.82902 7.30109 6.75 7.5 6.75V6.75ZM16.5 17.25H7.92L13.17 12.87C13.2709 12.7951 13.3931 12.7547 13.5187 12.7547C13.6444 12.7547 13.7666 12.7951 13.8675 12.87L17.25 15.75V16.5C17.25 16.6989 17.171 16.8897 17.0303 17.0303C16.8897 17.171 16.6989 17.25 16.5 17.25Z" fill={color}/>
                <path d="M9 10.5C9.62132 10.5 10.125 9.99632 10.125 9.375C10.125 8.75368 9.62132 8.25 9 8.25C8.37868 8.25 7.875 8.75368 7.875 9.375C7.875 9.99632 8.37868 10.5 9 10.5Z" fill={color}/>
            </svg>
        </div>
    )
}

BuyIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: "#868686",
}

export default BuyIcon