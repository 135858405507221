import React from "react";

const ExclamationIcon = (props) => {
    return (
        <div {...props}>
            <svg width={`${props.width ? props.width : "19"}`} height={`${props.width ? props.width : "17"}`} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.164098 14.5402L8.51422 0.565373C8.97812 -0.188458 10.0799 -0.188458 10.4858 0.565373L18.8359 14.5402C19.2998 15.2941 18.7199 16.2798 17.8501 16.2798H1.14988C0.280072 16.2798 -0.299798 15.2941 0.164098 14.5402Z" fill="#D7D7D7" />
                <path d="M8.57031 13.3226C8.57031 13.2066 8.57031 13.0907 8.6283 12.9747C8.68629 12.8587 8.74427 12.8007 8.80226 12.6847C8.86025 12.5688 8.97622 12.5688 9.0922 12.5108C9.20817 12.4528 9.32414 12.4528 9.44012 12.4528C9.55609 12.4528 9.73005 12.4528 9.84603 12.5108C9.962 12.5688 10.078 12.6268 10.136 12.6847C10.1939 12.7427 10.2519 12.8587 10.3099 12.9747C10.3679 13.0907 10.3679 13.2066 10.3679 13.3226C10.3679 13.4386 10.3679 13.5546 10.3099 13.6705C10.2519 13.7865 10.1939 13.8445 10.136 13.9605C10.078 14.0764 9.962 14.0764 9.84603 14.1344C9.73005 14.1924 9.61408 14.1924 9.44012 14.1924C9.26616 14.1924 9.15018 14.1924 9.0922 14.1344C9.03421 14.0764 8.86025 14.0184 8.80226 13.9605C8.74427 13.9025 8.68629 13.7865 8.6283 13.6705C8.57031 13.5546 8.57031 13.4386 8.57031 13.3226ZM10.1939 11.525H8.86025L8.6283 5.84229H10.3679L10.1939 11.525Z" fill="#263238" />
            </svg>
        </div>
    )
}

export default ExclamationIcon