import React, {useCallback, useMemo} from "react";
// import clasNames from 'classnames'

const Modal = ({ backdrop = true,disablePropagation, topModal=200, positionMobile="items-end", wide,full, ...props }) => {
    const { children, show, setShow } = props
    const showModal = useMemo(() => {
        return show ? "flex visible" : "hidden invisible"
    }, [show])

    const handleClick = useCallback(
      () => {
        backdrop && setShow(false)
      },
      [backdrop, setShow],
    )
    
    
    return (
        <div onClick={handleClick} className={`bg-black bg-opacity-50 fixed inset-0 overflow-y-auto min-h-full z-50 ${showModal} z-30 justify-center ${positionMobile} md:items-center ${props?.className}`} id={props?.id}>
            <div className={`w-full flex justify-center md:items-center ${full ? "md:max-w-full" :wide ? "md:max-w-3xl" : "md:max-w-xl" }`} onClick={(e) => !disablePropagation && e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default Modal