import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import Cookies from 'js-cookie'
import { intervalToDuration, isPast } from 'date-fns'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../component/Button/Button'
import ButtonText from '../../component/Text/ButtonText'
import navs from '../../router/_nav'
import authContext from '../../context/Auth/authContext'
import ResponsiveContext from '../../context/Responsive/responsiveContext'
// import SpinCircleLogo from "../../assets/animation/spin_circle_logo";
import { ethers } from 'ethers'
import ButtonLink from '../../component/Button/ButtonLink'
import { NFTSayaLogo, UserLogo } from '../../assets'
import UserIcon from '../../assets/icon/user_icon'
import StarIcon from '../../assets/icon/star_icon'
import SettingIcon from '../../assets/icon/setting_icon'
import LogoutIcon from '../../assets/icon/logout_icon'
import BuyIcon from '../../assets/icon/buy_icon'
// import ChevronRightIcon from "../../assets/icon/checvron_right_icon";
import { toast } from 'react-toastify'
import CloseIcon from '../../assets/icon/close_icon'
import HamburgerIcon from '../../assets/icon/hamburger_icon'
import InputSmartSearch from '../../component/Input/InputSmartSearch'
import HeaderMobileContext from '../../context/HeaderMobile/HeaderMobileContext'
import web3ModalContext from '../../context/Web3Modal/web3ModalContext'
import env from '../../config/env'
import txnContext from '../../context/Txn/txnContext'
import { TXN_STATUS_PENDING } from '../../utils/constants/transactionLogStatus'
import MobileMenu from '../../component/Header/MobileMenu'
import NotifHeader from '../../component/Header/NotifHeader'
import IconNotification from '../../component/Header/Notification/IconNotification'
// import { renderUserLabel } from '../../utils/helper'
import ChevronUpNew from '../../assets/icon/new_chevron_up'
import ChevronDownNew from '../../assets/icon/new_chevron_down'
import { renderAddress } from '../../utils/helper'
import BadgesNetwork from '../../component/Badges/BadgesNetwork'
import Avatar from '../../component/Badges/Avatar'
import notificationContext from '../../context/Notification/notificationContext'
import ButtonComponent from '../../component/Button/ButtonComponent'

const LayoutHeader = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const AuthContext = useContext(authContext)
  const responsive = useContext(ResponsiveContext)
  const headerContext = useContext(HeaderMobileContext)
  const TxnContext = useContext(txnContext)
  const { getUnread, totalCount } = useContext(notificationContext)
  const isLanding = location.pathname === '/home'
  const {
    session,
    setSession,
    metamaskLoading,
    setMetamaskLoading,
    // web3Connection,
    setWeb3Connection,
    // changeAccount,
    setChangeAccount,
    // setChangeNetwork,
    setConnWallet,
    setWrongAccount,
  } = AuthContext
  const {
    web3Modal,
    injectedProvider,
    setInjectedProvider,
    localChainId,
    setLocalChainId,
  } = useContext(web3ModalContext)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [activeTab, setActiveTab] = useState('')
  const [isShowDropdownProfile, setIsShowDropdownProfile] = useState(false)
  const [isFiatBanner, setTypeBanner] = useState(false)
  const { triggerBanner, bgLanding, setShowBanner, showBanner } =
    useContext(HeaderMobileContext)
  const copyAddress = useCallback(async text => {
    await navigator.clipboard.writeText(text)
    toast('Alamat berhasil disalin', {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (session && AuthContext?.auth?.user?.id !== undefined) {
      await getUnread(AuthContext?.auth?.user?.id)
      const fetchLogs = await TxnContext.getTxn({
        first: 5,
        after: null,
        assetId: null,
        status: TXN_STATUS_PENDING,
        type: null,
        accountId: AuthContext?.auth?.user?.id,
      })
      if (fetchLogs?.nodes?.length === 0) {
        setShowBanner(false)
      } else {
        setShowBanner(true)
        if (fetchLogs?.nodes[0]?.isFiat === true) {
          setTypeBanner(true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, AuthContext?.auth, triggerBanner, session])

  useEffect(() => {
    if (Cookies.get('web3login') === 'true') {
      console.log('zap keset web3login')
      setSession(true)
    }
    if (Cookies.get('unResponsiveMetamask') === 'true') {
      connect()
      Cookies.remove('unResponsiveMetamask')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadWeb3Modal = useCallback(async () => {
    const connection = await web3Modal.connectTo('injected')
    const provider = new ethers.providers.Web3Provider(connection)
    setWeb3Connection(connection)
    setInjectedProvider(provider)
    const { chainId } = await provider.getNetwork()
    setLocalChainId(chainId)
    const signer = provider.getSigner()
    const userAddress = await signer.getAddress()
    const loggedAddress = JSON.parse(localStorage.getItem('auth'))?.user
      ?.address
    // console.log("zap perbandingan userAddress", userAddress, JSON.parse(localStorage.getItem("auth"))?.user?.address)
    if (userAddress !== loggedAddress) {
      setWrongAccount(true)
    }
    connection.on('chainChanged', chainId => {
      setLocalChainId(parseInt(chainId))
      setInjectedProvider(new ethers.providers.Web3Provider(connection))
    })
    connection.on('accountsChanged', async e => {
      setChangeAccount(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInjectedProvider])

  useEffect(() => {
    async function checkCache() {
      if (web3Modal.cachedProvider && session) {
        setMetamaskLoading(true)
        console.log('zap check cache metamask')
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        let unlocked
        try {
          console.log('zap masuk try block')
          const timeoutId = setTimeout(async () => {
            clearTimeout(timeoutId)
            await handleLogout()
          }, 15000)
          const accounts = await provider.listAccounts()
          unlocked = accounts.length > 0
          clearTimeout(timeoutId)
          setMetamaskLoading(false)
        } catch (error) {
          console.log('zap masuk error block')
          unlocked = false
        }
        if (unlocked) {
          await loadWeb3Modal()
        } else {
          await handleLogout()
        }
      }
    }
    checkCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  const logoutOfWeb3Modal = useCallback(async () => {
    navigate('/')
    await web3Modal.clearCachedProvider()
    if (
      injectedProvider &&
      injectedProvider.provider &&
      typeof injectedProvider.provider.disconnect == 'function'
    ) {
      await injectedProvider.provider.disconnect()
    }
    setTimeout(() => {
      window.location.reload()
    }, 1)
  }, [injectedProvider, navigate, web3Modal])

  const constructMessageHash = useCallback(userAddress => {
    const messageHash = ethers.utils.solidityKeccak256(
      ['string'],
      [`I want to login with ${userAddress}`],
    )
    return messageHash
  }, [])

  const connect = async () => {
    try {
      setMetamaskLoading(true)
      if (typeof window.ethereum !== 'undefined') {
        const connection = await web3Modal.connectTo('injected')
        setWeb3Connection(connection)
        const provider = new ethers.providers.Web3Provider(connection)
        const { chainId } = await provider.getNetwork()
        setInjectedProvider(provider)
        const signer = provider.getSigner()
        const userAddress = await signer.getAddress()
        const messageHash = constructMessageHash(userAddress)
        const signature = await signer.signMessage(messageHash)
        const input = {
          signature,
          userAddress,
        }
        await AuthContext.login(input)
        Cookies.set('web3login', true)
        setSession(true)
        setConnWallet(false)
        setLocalChainId(chainId)
      } else {
        if (responsive && env.DEV === 'false') {
          window.open(
            `https://metamask.app.link/dapp/${window.location.host}`,
            '_self',
          )
        } else {
          const toastId = toast.error(
            <div>
              Install ekstension Metamask ke browser Anda terlebih dahulu.
              Petunjuknya ada di
              <p
                className="text-blue-500"
                onClick={() => {
                  toast.dismiss(toastId)
                  navigate('/faq')
                }}
              >
                {' '}
                sini
              </p>
            </div>,
            {
              closeButton: true,
              autoClose: false,
            },
          )
        }
      }
      setMetamaskLoading(false)
    } catch (error) {
      console.log('error', error)
      if (error?.code === 4001) {
        setMetamaskLoading(false)
        // di reject signing nya
        await logoutOfWeb3Modal()
      }
    }
  }

  useEffect(() => {
    const refreshToken = () => {
      console.log('zap refreshtoken')
      let isRefreshTokenActive = false
      const intervalId = setInterval(async () => {
        if (Cookies.get('web3login') === 'true' && AuthContext?.auth?.user) {
          const interval = intervalToDuration({
            start: new Date(),
            end: new Date(AuthContext.auth.expiresAt),
          })
          // console.log(interval, isPast(new Date(AuthContext.auth.expiresAt)), "zap interval")
          if (isPast(new Date(AuthContext?.auth?.expiresAtRefreshToken))) {
            await handleLogout()
          }
          if (!isRefreshTokenActive) {
            if (
              (interval.minutes <= 2 &&
                interval.hours === 0 &&
                interval.days === 0) ||
              isPast(new Date(AuthContext.auth.expiresAt))
            ) {
              console.log('zap get ulang')
              isRefreshTokenActive = true
              await AuthContext.refreshToken()
              isRefreshTokenActive = false
              clearInterval(intervalId)
            }
          }
        } else {
          // console.log("zap refreshtoken clearInterval")
          clearInterval(intervalId)
        }
      }, 1000)
    }
    refreshToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthContext?.auth])

  const navigateToPage = useCallback(
    url => {
      navigate(url)
      setIsShowDropdown(false)
    },
    [navigate],
  )

  const onHoverTab = useCallback(name => {
    setIsShowDropdown(true)
    setActiveTab(name)
  }, [])

  const onHoverOutTab = useCallback(() => {
    setIsShowDropdown(false)
    setActiveTab('')
  }, [])

  const createLinks = useCallback(
    (routes, isDropdown) => {
      return routes.map((prop, key) => {
        if (prop.invisible) return null
        if (prop.redirect) {
          return null
        }
        if (isDropdown) {
          return (
            <li
              key={key}
              className="bg-white hover:bg-gray-200 py-3 px-2 block whitespace-no-wrap cursor-pointer"
              onClick={() => navigateToPage(prop.url)}
            >
              <button className="px-0">
                <ButtonText
                  classstyle="text-sm px-2 font-medium"
                  color="text-teks"
                  tx={prop.name}
                />
              </button>
            </li>
          )
        }
        if (prop.collapse) {
          return (
            <div
              key={key}
              className="inline-block self-center relative"
              onMouseEnter={() => onHoverTab(prop.name)}
              onMouseLeave={onHoverOutTab}
            >
              <button
                className="px-0"
                onClick={() => !prop?.disabled && navigate(prop?.url)}
              >
                <ButtonText
                  classstyle="font-semibold lg:text-sm fhd:text-base hover:text-hijau_hutan"
                  color="text-teks"
                  tx={prop.name}
                />
              </button>
              {
                <div
                  onMouseEnter={() => onHoverTab(prop.name)}
                  onMouseLeave={onHoverOutTab}
                  className={`absolute z-30 rounded-lg overflow-hidden shadow-md ${
                    isShowDropdown && activeTab === prop.name
                      ? 'block'
                      : 'hidden'
                  }`}
                >
                  <ul className="rounded-lg w-44 dropdown-navigator-background ">
                    {createLinks(prop.children, true)}
                  </ul>
                </div>
              }
            </div>
          )
        }
        return (
          <button key={key} className="px-0" onClick={() => navigate(prop.url)}>
            {' '}
            <ButtonText
              classstyle="font-semibold lg:text-sm fhd:text-base hover:text-hijau_hutan"
              color="text-teks"
              tx={prop.name}
            />
          </button>
        )
      })
    },
    [
      activeTab,
      isShowDropdown,
      navigate,
      navigateToPage,
      onHoverOutTab,
      onHoverTab,
    ],
  )

  const handleMenuMobile = useCallback(() => {
    const { setShowMenu, setShowSubMenu, showMenu } = headerContext
    setShowMenu(!showMenu)
    setShowSubMenu(false)
  }, [headerContext])

  const handleSubMenuMobile = useCallback(
    param => {
      const { setShowMenu, setShowSubMenu, showSubMenu, setSubMenuParam } =
        headerContext
      setShowSubMenu(!showSubMenu)
      setShowMenu(true)
      setSubMenuParam(param)
    },
    [headerContext],
  )

  const handleHome = useCallback(() => {
    headerContext.setShowMenu(false)
    navigate('/')
  }, [navigate, headerContext])

  const handleCreateAsset = useCallback(
    () => navigate('/create-asset'),
    [navigate],
  )

  const handleProfile = useCallback(() => {
    navigate('/profile-page/asset#0')
    setIsShowDropdownProfile(false)
  }, [navigate])

  const handleToCollection = useCallback(() => {
    navigate('/my-collection')
    setIsShowDropdownProfile(false)
  }, [navigate])

  const handleToMyProject = useCallback(() => {
    navigate('/my-project')
    setIsShowDropdownProfile(false)
  }, [navigate])

  const handleToSetting = useCallback(() => {
    navigate('/setting/profile')
    setIsShowDropdownProfile(false)
  }, [navigate])

  const handleToTxn = useCallback(() => {
    navigate('/history-txn/pending')
    setIsShowDropdownProfile(false)
  }, [navigate])

  const handleLogout = useCallback(async () => {
    await AuthContext.logout()
    Cookies.remove('web3login')
    setSession(false)
    await logoutOfWeb3Modal()
  }, [AuthContext, logoutOfWeb3Modal, setSession])

  const listMenuOnLogin = useMemo(
    () => [
      {
        name: 'Profil',
        icon: UserIcon,
        onClick: handleProfile,
      },
      {
        name: 'Koleksi Saya',
        icon: StarIcon,
        onClick: handleToCollection,
      },
      {
        name: 'Projek Saya',
        icon: StarIcon,
        onClick: handleToMyProject,
      },
      {
        name: 'Sejarah Transaksi',
        icon: BuyIcon,
        onClick: responsive ? () => handleSubMenuMobile(2) : handleToTxn,
      },
      {
        name: 'Pengaturan',
        icon: SettingIcon,
        onClick: responsive ? () => handleSubMenuMobile(1) : handleToSetting,
      },
      {
        name: 'Keluar',
        icon: LogoutIcon,
        onClick: handleLogout,
      },
    ],
    [
      handleLogout,
      handleProfile,
      handleSubMenuMobile,
      handleToCollection,
      handleToMyProject,
      handleToSetting,
      handleToTxn,
      responsive,
    ],
  )

  const handleLogin = useCallback(async () => {
    await setConnWallet(true)
  }, [setConnWallet])

  const ButtonLogin = useCallback(() => {
    return (
      <ButtonLink classstyle="px-0" onClick={handleLogin}>
        <UserLogo />
      </ButtonLink>
    )
  }, [handleLogin])

  const ButtonLoginMobile = useCallback(() => {
    return (
      <Button
        onClick={handleLogin}
        color="bg-hijau_hutan"
        classstyle="text-hijau_hutan border-hijau_hutan rounded-lg font-poppins md:py-2 py-3 md:px-7 w-full border-solid"
      >
        <ButtonText
          classstyle="md:text-base text-sm font-semibold"
          color="text-white"
          tx="Masuk"
        />
      </Button>
    )
  }, [handleLogin])

  const ButtonCreateAsset = () => {
    const handleClick = useCallback(() => {
      headerContext.setShowMenu(false)
      handleCreateAsset()
    }, [])
    return (
      <ButtonComponent
        type="ghost"
        color="primary"
        size="sm"
        handleClick={handleClick}
        text="Buat Aset"
      />
    )
  }

  const renderBgHeader = useMemo(() => {
    if (isLanding) {
      if (bgLanding && !responsive) {
        return 'bg-white shadow-sm'
      } else {
        return 'bg-white shadow-sm'
      }
    } else {
      return 'bg-white shadow-sm'
    }
  }, [bgLanding, isLanding, responsive])

  const renderIconMobile = useMemo(() => {
    if (!headerContext.showMenu) {
      return <HamburgerIcon />
    } else {
      return <CloseIcon size={6} viewBox={24} />
    }
  }, [headerContext.showMenu])

  const renderListMenuLogin = useMemo(() => {
    return listMenuOnLogin
      .filter(el => {
        return el.name !== 'Pembelian' && el.name !== 'Penjualan'
      })
      .map(({ name, onClick }, key) => (
        <li
          key={key}
          className="bg-white hover:bg-gray-200 py-3 pl-2 pr-4 flex whitespace-no-wrap items-center justify-center cursor-pointer"
          onClick={onClick}
        >
          <button className="md:mx-0 md:my-0 w-full">
            <div className="flex flex-row items-center px-2">
              <ButtonText
                classstyle="font-normal text-sm"
                color="teks"
                text={name}
              />
            </div>
          </button>
        </li>
      ))
  }, [listMenuOnLogin])

  return (
    <div className="overflow-visible sticky inset-0 w-full z-40">
      <nav
        className={`md:px-10 px-0 ${renderBgHeader} z-40 md:h-header md:py-0 py-2 flex flex-col md:relative w-full justify-center`}
      >
        <div className="md:flex md:justify-between md:items-center">
          <div className="flex flex-row-reverse items-center justify-end">
            <div
              className="cursor-pointer w-full md:ml-0 ml-16"
              onClick={handleHome}
            >
              <img alt="nft saya" src={NFTSayaLogo} className="w-32" />
            </div>
            <div className="flex md:hidden">
              <button
                type="button"
                className="py-3 mx-3 text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
                aria-label="toggle menu"
                onClick={handleMenuMobile}
              >
                {' '}
                {renderIconMobile}
              </button>
            </div>
            <div className="absolute md:hidden right-4 top-4">
              <IconNotification session={session} />
            </div>
          </div>
          <div className="items-center md:flex hidden">
            <li className="py-2 lg:mr-8 2xl:mr-12 block whitespace-no-wrap">
              <InputSmartSearch />
            </li>
            <div className="grid grid-flow-col gap-4 items-center">
              <div className="border-gray-200 pr-6 h-12 items-center grid grid-flow-col gap-8 flex-row">
                {createLinks(navs)}
              </div>
              <ButtonCreateAsset />
              {web3Modal?.cachedProvider && session && !metamaskLoading && (
                <IconNotification session={session} />
              )}
              {web3Modal?.cachedProvider && session && !metamaskLoading ? (
                <>
                  {AuthContext?.auth?.user && (
                    <>
                      <div
                        onMouseEnter={() => {
                          setIsShowDropdownProfile(true)
                        }}
                        onMouseLeave={() => {
                          setIsShowDropdownProfile(false)
                        }}
                        className="inline-block self-center relative px-0"
                      >
                        <div className="flex items-end justify-center mt-0 -mr-4 cursor-pointer">
                          <div className="flex flex-row items-center">
                            <Avatar
                              customSpace="mr-2"
                              thumbnailUser={
                                AuthContext.auth.user.profilePictureUrl
                              }
                              handleClick={handleProfile}
                            />
                            <div className="flex-col">
                              <p
                                className="text-xs font-poppins text-teks font-semibold mb-0.5 cursor-pointer hover:text-hijau_hutan"
                                onClick={() =>
                                  copyAddress(AuthContext?.auth?.user?.address)
                                }
                              >
                                {renderAddress(
                                  AuthContext?.auth?.user?.address,
                                )}
                              </p>
                              <BadgesNetwork chainId={localChainId} />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`absolute z-30 right-2 rounded-lg overflow-hidden shadow-md ${
                            isShowDropdownProfile ? 'block' : 'hidden'
                          }`}
                          onMouseEnter={() => {
                            setIsShowDropdownProfile(true)
                          }}
                          onMouseLeave={() => {
                            setIsShowDropdownProfile(false)
                          }}
                        >
                          <ul className="rounded-lg w-44 dropdown-navigator-background ">
                            {renderListMenuLogin}
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <ButtonLogin />
              )}
            </div>
          </div>
        </div>
      </nav>
      {responsive && (
        <MobileMenu
          AuthContext={AuthContext}
          ButtonCreateAsset={ButtonCreateAsset}
          ButtonLogin={ButtonLoginMobile}
          Menu={Menu}
          listMenuOnLogin={listMenuOnLogin}
          toggleMenu={headerContext.showMenu}
          setToggle={handleMenuMobile}
          showSub={headerContext.showSubMenu}
          setShowSub={handleSubMenuMobile}
          menuParam={headerContext.subMenuParam}
          headerContext={headerContext}
          navigate={navigate}
        />
      )}
      <NotifHeader
        navigate={navigate}
        show={showBanner}
        setShow={setShowBanner}
        location={location}
        isFiat={isFiatBanner}
      />
    </div>
  )
}

//#region Child component
const ListMobileMenu = ({ icon: Icon, name, onClick }) => {
  const headerContext = useContext(HeaderMobileContext)
  const handleClick = useCallback(() => {
    headerContext.setShowMenu(false)
    onClick()
  }, [headerContext, onClick])

  return (
    <div className="flex flex-row mb-4 items-center" onClick={handleClick}>
      {Icon && <Icon color={'#676767'} />}
      <button className="px-0" onClick={onClick}>
        {' '}
        <ButtonText
          classstyle="ml-2 text-sm font-semibold text-teks2 font-poppins"
          color="text-black"
          tx={name}
        />
      </button>
    </div>
  )
}

const ListCollapsMobileMenu = ({
  icon: Icon,
  name,
  children,
  url,
  navigate,
}) => {
  const headerContext = useContext(HeaderMobileContext)
  const {
    showCollaps,
    setShowCollaps,
    setShowMenu,
    setActiveName,
    activeName,
  } = headerContext
  const handleToggle = useCallback(() => {
    setShowCollaps(!showCollaps)
    if (activeName !== name) {
      setActiveName(name)
    }
  }, [activeName, name, setActiveName, setShowCollaps, showCollaps])
  const handleClick = useCallback(
    url => {
      setShowMenu(false)
      navigate(url)
    },
    [navigate, setShowMenu],
  )
  return (
    <>
      <div className="flex flex-row mb-4 items-center" onClick={handleToggle}>
        <div className="flex w-full">
          <Icon />
          <button className="px-0">
            {' '}
            <ButtonText
              classstyle="ml-2 text-sm font-medium text-teks_2 font-poppins"
              color="text-black"
              tx={name}
            />
          </button>
        </div>
        {showCollaps && activeName === name ? (
          <ChevronUpNew />
        ) : (
          <ChevronDownNew />
        )}
      </div>
      {showCollaps &&
        activeName === name &&
        children.map(({ name, url }, idx) => (
          <div className="bg-white pb-2" key={idx}>
            <ul className="mb-3">
              <li className="bg-white mb-2 ml-2 px-6 block whitespace-no-wrap">
                <button className="px-0" onClick={() => handleClick(url)}>
                  <ButtonText
                    classstyle="font-normal text-sm"
                    color="text-teks"
                    tx={name}
                  />
                </button>
              </li>
            </ul>
          </div>
        ))}
    </>
  )
}

const Menu = ({ data, title, headerContext, navigate }) => {
  const renderList = useMemo(() => {
    return data.map(({ icon, name, url, collapse, children, onClick }, idx) => {
      if (collapse) {
        return (
          <ListCollapsMobileMenu
            icon={icon}
            name={name}
            key={idx}
            children={children}
            url={url}
            headerContext={headerContext}
            navigate={navigate}
          />
        )
      }
      return (
        <ListMobileMenu
          icon={icon}
          name={name}
          onClick={!!url ? () => navigate(url) : onClick}
          key={idx}
          headerContext={headerContext}
        />
      )
    })
  }, [data, headerContext, navigate])
  return (
    <div className="px-4">
      <p className="font-poppins text-base font-semibold text-teks mt-100 py-4">
        {title}
      </p>
      {renderList}
    </div>
  )
}

//#endregion

export default LayoutHeader
