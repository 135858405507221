import React from "react";

const ChevronRightIcon = ({ color = "white", opacity = "1", width = "2", ...props }) => {
    return (
        <div {...props}>
            <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L4 4.5L1 9" stroke={color} strokeOpacity={opacity} strokeWidth={width} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}

export default ChevronRightIcon