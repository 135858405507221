import React, { useReducer } from 'react';
import PreprocessingContext from './preProcessingContext';
import PreProcessingReducer from './preProcessingReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import api from '../../utils/api';
import { SET_LOADING,UPDATE_METADATA_PREPROCESS_FIAT,UPDATE_METADATA_PREPROCESS_CRYPTO, CREATE_ASSET_PREPROCESS_FIAT,LISTING_PREPROCESS_FIAT, CANCEL_SALE_PREPROCESS_FIAT, CANCEL_MAKE_OFFER_PRE_PROCESS_FIAT,CREATE_ASSET_PREPROCESS_CRYPTO, BUY_PREPROCESS_CRYPTO, ACCEPT_MAKE_OFFER_PRE_PROCESS_CRYPTO, ACCEPT_MAKE_OFFER_PRE_PROCESS_FIAT, CANCEL_SALE_PREPROCESS_CRYPTO} from '../types';
import logger from 'use-reducer-logger';
var converter = require('hex2dec');

const PreprocessingState = props => {
  const initialState = {
    dataAsset: {},
    invoiceUrl: "",
    createInvoice: {},
    txnData: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(PreProcessingReducer), initialState);

  const getMetadataFile = async ({
    tokenId, contractAddress
  }) => {
    setLoading()
    
    const params = {
      tokenId: converter.hexToDec(tokenId), contractAddress
    }
    const res = await api.post('transactions/preprocess_get_metadatafile', { ...params }, { withCredentials: true })

    await dispatch({
      type: CREATE_ASSET_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res.data
  }
  
  const createMetadataFile = async ({
    tokenId, thumbnailUrl, contractAddress
  }) => {
    setLoading()
    const params = {
      tokenId: converter.hexToDec(tokenId), thumbnailUrl, contractAddress
    }
    const res = await api.post('transactions/preprocess_create_metadatafile', { ...params }, { withCredentials: true })

    await dispatch({
      type: CREATE_ASSET_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res.data
  }
  
  const createNftFiat = async ({
    tokenMetadataUrl, tokenMetadataCid, asset, assetInvoice, adminFee, gasFee, amount, userId, nft, formInput, price, INITIAL_AMOUNT, isERC721, paymentMethod, externalId, WEB_HOST
  }) => {
    setLoading()
    const body = {
      tokenMetadataUrl, tokenMetadataCid, asset, assetInvoice, adminFee, gasFee, amount, userId, nft, formInput, price, INITIAL_AMOUNT, isERC721, paymentMethod, externalId, WEB_HOST
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_createnft_fiat', { params }, { withCredentials: true })
    await dispatch({
      type: CREATE_ASSET_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const createNftCrypto = async ({
    tokenMetadataUrl, tokenMetadataCid, asset, assetInvoice, adminFee, gasFee, amount, userId, nft, formInput, price, INITIAL_AMOUNT, isERC721, paymentMethod, externalId, WEB_HOST, existingAsset
  }) => {
    setLoading()
    const body = {
      tokenMetadataUrl, tokenMetadataCid, asset, assetInvoice, adminFee, gasFee, amount, userId, nft, formInput, price, INITIAL_AMOUNT, isERC721, paymentMethod, externalId, WEB_HOST, existingAsset
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_createnft_crypto', { params }, { withCredentials: true })
    await dispatch({
      type: CREATE_ASSET_PREPROCESS_CRYPTO,
      payload: res?.data
    })
    return res?.data
  }

  const listingFiat = async({
    formInput, userId, asset, dataFee, externalId, WEB_HOST,assetInvoice
  }) => {
    const body = {formInput, userId, asset, dataFee, externalId, WEB_HOST,assetInvoice}
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_activatesale_fiat',{ params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const cancelAuctionFiat = async({
    formInput, userId, dataFee, nft, auctionData, WEB_HOST, externalId, assetInvoice
  }) => {
    const body = {formInput, userId, dataFee, nft, auctionData, WEB_HOST, externalId,assetInvoice}
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_cancelauction_fiat',{ params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const cancelBidderAuctionFiat = async({
    assetInvoice,formInput, nft, userId, dataFee, externalId, auctionData, WEB_HOST, 
  }) => {
    const body = {assetInvoice,formInput, nft, userId, dataFee, externalId, auctionData, WEB_HOST,}
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_cancelbidderauction_fiat',{ params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }


  const cancelSaleCrypto = async ({
    nft, userId
  }) => {
    const body = {
      nft, userId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_cancelsale_crypto', { params }, { withCredentials: true })
    await dispatch({
      type: CANCEL_SALE_PREPROCESS_CRYPTO,
      payload: res?.data
    })
    return res?.data
  }

  const cancelSaleFiat = async ({
    formInput, userId, nft, dataFee, externalId, WEB_HOST, assetInvoice,
  }) => {
    // setLoading()
    const body = {
      formInput, userId, nft, dataFee, externalId, WEB_HOST, assetInvoice
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_cancelsale_fiat', { params }, { withCredentials: true })
    await dispatch({
      type: CANCEL_SALE_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }
  const cancelBidFiat = async ({
    formInput, asset, dataFee, userId, offer, externalId, WEB_HOST, assetInvoice
  }) => {
    // setLoading()
    const body = {
      formInput, asset, dataFee, userId, offer, externalId, WEB_HOST, assetInvoice
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_cancelbid_fiat', { params }, { withCredentials: true })
    await dispatch({
      type: CANCEL_MAKE_OFFER_PRE_PROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const acceptMakeofferCrypto = async ({
    nft, formInput, userId, offer
  }) => {
    const body = {
      nft, formInput, userId, offer
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_acceptoffer_crypto', { params }, { withCredentials: true })
    await dispatch({
      type: ACCEPT_MAKE_OFFER_PRE_PROCESS_CRYPTO,
      payload: res?.data
    })
    return res?.data
  }

  const acceptMakeOfferFiat = async ({
    assetInvoice, formInput, userId, acceptedAsset, dataFee, offer, externalId, WEB_HOST,
  }) => {
    const body = {
      assetInvoice, formInput, userId, acceptedAsset, dataFee, offer, externalId, WEB_HOST,
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_acceptmakeoffer_fiat', { params }, { withCredentials: true })
    await dispatch({
      type: ACCEPT_MAKE_OFFER_PRE_PROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const buyCrypto = async({
    nft, formInput, userId
  }) => {
  const body = {
    nft, formInput, userId
  }
  const params = JSON.stringify(body)
  const res = await api.post('transactions/preprocess_buynft_crypto',{ params }, { withCredentials: true })
  await dispatch({
    type: BUY_PREPROCESS_CRYPTO,
    payload: res?.data
  })
  return res?.data
}

  const buyFiat = async({
    asset, matic_price, input, userId, dataFee, externalId, WEB_HOST
  }) => {
  const body = {
    asset, matic_price, input, userId, dataFee, externalId, WEB_HOST
  }
  const params = JSON.stringify(body)
  const res = await api.post('transactions/preprocess_buynft_fiat',{ params }, { withCredentials: true })
  await dispatch({
    type: BUY_PREPROCESS_CRYPTO,
    payload: res?.data
  })
  return res?.data
}

const settleAuctionFiat = async({
      assetInvoice, adminFee, gasFee, amount, formInput, userId, auctionData, dataFee, asset, externalId, WEB_HOST, }) => {
const body = {
      assetInvoice, adminFee, gasFee, amount, formInput, userId, auctionData, dataFee, asset, externalId, WEB_HOST, }
const params = JSON.stringify(body)
const res = await api.post('transactions/preprocess_settleauction_fiat',{ params }, { withCredentials: true })
await dispatch({
  type: LISTING_PREPROCESS_FIAT,
  payload: res?.data
})
return res?.data
}

  const listingAuctionFiat = async ({

    formInput, nft, dataFee, externalId, WEB_HOST, userId, assetInvoice, }) => {
    const body = {
      formInput, nft, dataFee, externalId, WEB_HOST, userId, assetInvoice,
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_activatesaleauction_fiat', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const listingAuctionCrypto = async ({

    nft, formInput, userId }) => {
    const body = {
      nft, formInput, userId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_activatesaleauction_crypto', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const acceptBarterCrypto = async ({
    nft, userId, typeSourceId 
  }) => {
    
    const body = {
      nft, userId, typeSourceId 
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_accept_request_barter_crypto',{params}, {withCredentials: true})
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const acceptBarterFiat = async ({
    asset, input, userId, dataFee, externalId, typeSourceId, WEB_HOST
  }) => {
    const body = {
      asset, input, userId, dataFee, externalId, typeSourceId, WEB_HOST
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_accept_request_barter_fiat', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const requestBarterCrypto = async ({
    nft, userId, barteredAssetId
  }) => {
    const body = {
      nft, userId, barteredAssetId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_request_barter_crypto', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const requestBarterFiat = async ({
    asset, input, userId, dataFee, externalId, barteredAssetId, WEB_HOST
  }) => {
    const body = {
      asset, input, userId, dataFee, externalId, barteredAssetId, WEB_HOST
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_request_barter_fiat', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const cancelRequestBarterFiat = async ({
    asset, input, userId, dataFee, externalId, typeSourceId, WEB_HOST
  }) => {
    const body = {
      asset, input, userId, dataFee, externalId, typeSourceId, WEB_HOST
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_cancel_request_barter_fiat', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const cancelRequestBarterCrypto = async ({
    nft, userId, typeSourceId
  }) => {
    const body = {
      nft, userId, typeSourceId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/preprocess_cancel_request_barter_crypto', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  } 

  const updateMetadata = async (input) => {
    const res = await api.post('transactions/preprocess_changeuri_crypto', input, { withCredentials: true })

    await dispatch({
      type: UPDATE_METADATA_PREPROCESS_CRYPTO,
      payload: res?.data
    })
    return res?.data
  }

  const updateMetadataFiat = async (input) => {
    const res = await api.post('transactions/preprocess_changeuri_fiat', input, { withCredentials: true })

    await dispatch({
      type: UPDATE_METADATA_PREPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }
  
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <PreprocessingContext.Provider
      value={{
        dataAsset:state.dataAsset, 
        invoiceUrl:state.invoiceUrl,
        createInvoice:state.createInvoice, 
        txnData:state.txnData, 
        loading: state.loading,
        setLoading,
        createNftFiat,
        createNftCrypto,
        listingFiat,
        cancelAuctionFiat,
        cancelBidderAuctionFiat,
        acceptMakeOfferFiat,
        buyCrypto,
        buyFiat,
        settleAuctionFiat,
        listingAuctionFiat,
        listingAuctionCrypto,
        cancelSaleFiat,
        cancelBidFiat,
        updateMetadata,
        updateMetadataFiat,
        acceptMakeofferCrypto,
        cancelSaleCrypto,
        createMetadataFile,
        getMetadataFile,
        acceptBarterCrypto,
        acceptBarterFiat,
        requestBarterCrypto,
        requestBarterFiat,
        cancelRequestBarterCrypto,
        cancelRequestBarterFiat,
      }}
    >
      {props.children}
    </PreprocessingContext.Provider>
  );
};

export default PreprocessingState;
