import React, { useReducer } from 'react';
import assetAuctionDetailContext from './assetAuctionDetailContext';
import assetAuctionDetailReducer from './assetAuctionDetailReducer';
import { post } from '../../utils/graphql-api';
import { CHECK_USER_BID, CREATE_ASSET_AUCTION_DETAIL, DELETE_ASSET_AUCTION_DETAIL, GET_ALL_AUCTION_IN_PROFILE, SET_LOADING } from '../types';
import logger from 'use-reducer-logger';

const AssetAuctionDetailState = props => {
  const initialState = {
    auctionOfferMade: [],
    assetAuctionDetail: {},
    isBid: false,
    loading: false
  };

  const [state, dispatch] = useReducer(logger(assetAuctionDetailReducer), initialState);

  const createAssetsAuctionDetail = async (assetInput) => {
    setLoading()

    const res = await post("createAssetsAuctionDetail", { ...assetInput })
    dispatch({
      type: CREATE_ASSET_AUCTION_DETAIL,
      payload: res.data.data.createAssetsAuctionDetail.assetsAuctionDetail
    });
    return res.data.data.createAssetsAuctionDetail.assetsAuctionDetail

  }

  const _delete = async (id) => {
    setLoading()
    const res = await post('deleteAssetsAuctionDetailById',{id})
    dispatch({
      type: DELETE_ASSET_AUCTION_DETAIL,
      payload: res.data.data.deleteAssetsAuctionDetailById.assetsAuctionDetail
    })
    return res.data.data.deleteAssetsAuctionDetailById.assetsAuctionDetail
  }

  const update = async (id, patch) => {
    setLoading()
    const res = await post("updateAssetsAuctionDetailById", { id, patch })
    dispatch({

    })
    return res?.data?.data?.updateAssetsAuctionDetailById?.assetsAuctionDetail
  }

  const checkUserBid = async (assetInput) => {
    setLoading()

    const res = await post("checkUserAuctionBidByAuctionId", { ...assetInput })
    dispatch({
      type: CHECK_USER_BID,
      payload: res?.data?.data?.allAssetsAuctionDetails.nodes && res?.data?.data?.allAssetsAuctionDetails.nodes.length !== 0
    });
    return res?.data?.data?.allAssetsAuctionDetails?.nodes

  }

  const auctionInProfile = async (input) => {
    const res = await post("auctionInProfile", { ...input })

    dispatch({
      type: GET_ALL_AUCTION_IN_PROFILE,
      payload: res.data.data.allAssetsAuctions.nodes
    });
    return res.data.data.allAssetsAuctions.nodes

  }
  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <assetAuctionDetailContext.Provider
      value={{
        auctionOfferMade: state.auctionOfferMade,
        assetAuctionDetail: state.assetAuctionDetail,
        isBid: state.isBid,
        loading: state.loading,
        createAssetsAuctionDetail,
        auctionInProfile,
        checkUserBid,
        delete: _delete,
        update,
      }}
    >
      {props.children}
    </assetAuctionDetailContext.Provider>
  );
};

export default AssetAuctionDetailState;
