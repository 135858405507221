import { useEffect } from "react";

export const useClickOutside = (insideRefs, isVisible, onClose) => {
  useEffect(() => {
    /* eslint-disable */

    const handleWindowClick = (event) => {
      if (insideRefs && insideRefs.current) {
        if (insideRefs.current.contains(event.target)) {
          return;
        }
        if (!isVisible) {
          return;
        }

        if (onClose) {
          onClose();
        }
      }
    };
    if (isVisible) {
      window.addEventListener("click", handleWindowClick);
    }

    return () => {
      if (isVisible) {
        window.removeEventListener("click", handleWindowClick);
      }
    };
  }, [isVisible, onClose]);
};
