import {
  GET_ALL_UTILITAS,
  GET_ALL_UTILITAS_PROJECTS,
  GET_OWNED_UTILITAS,
  SET_DETAIL_UTILITAS,
  SET_LOADING,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {

    case GET_OWNED_UTILITAS:
      return {
        ...state,
        ownedUtility: action.payload.nodes,
        totalCount: action.payload.totalCount,
        pageInfo: action.payload.pageInfo,
        loading: false
      };
    case GET_ALL_UTILITAS:
      return {
        ...state,
        utilitass: action.payload.nodes,
        totalCount: action.payload.totalCount,
        pageInfo: action.payload.pageInfo,
        loading: false
      };
    case GET_ALL_UTILITAS_PROJECTS:
      return {
        ...state,
        projects: action.payload.nodes,
        totalCount: action.payload.totalCount,
        pageInfo: action.payload.pageInfo,
        loading: false
      };
    case SET_DETAIL_UTILITAS: 
      return {
        ...state,
        detailUtilitas: action.payload,
        loading: false,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
