import React, { useReducer } from 'react';
import BarterContext from './barterContext';
import BarterReducer from './barterReducer';
import { post } from '../../utils/graphql-api';
import {  
  CREATE_BARTER,
  CREATE_REQUEST_BARTER, 
  GET_ALL_BARTER, 
  GET_BARTER, 
  GET_BY_OWNER_BARTER, 
  GET_REQUEST_BARTER,
  RESET_PARAM_BARTER, 
  SET_LOADING, 
  SET_OWNED_ASSET_BARTER, 
  SET_PARAM_BARTER, 
  SET_REQUESTED_ASSET_BARTER, 
  } from '../types';
import logger from 'use-reducer-logger';

const BarterState = props => {
  const initialState = {
    barters: [],
    requestBarters: [],
    barter: {},
    ownedAssetBarter:{},
    requestedAssetbarter:{},
    idBarter: undefined,
    totalCount: 0,
    pageInfo: {},
    loading: false,
  };

  const [state, dispatch] = useReducer(logger(BarterReducer), initialState);

  const create = async (assetInput) => {
    setLoading();

    const res = await post("createAssetBarter", { ...assetInput });
    dispatch({
      type: CREATE_BARTER,
      payload: res.data.data.createAssetBarter.assetsBarter
    });
    return res.data.data.createAssetBarter.assetsBarter

  }
  
  const createRequest = async (assetInput) => {
    setLoading();

    const res = await post("createAssetBarterRequest", { ...assetInput });
    dispatch({
      type: CREATE_REQUEST_BARTER,
      payload: res.data.data.createAssetBarterRequest.assetsBarterRequest
    });
    return res.data.data.createAssetBarterRequest.assetsBarterRequest

  }


  const getAll = async (option) => {
    setLoading();
    const res = await post("getAllAssetsBarter", { ...option })
    dispatch({
      type: GET_ALL_BARTER,
      payload: res.data.data.allAssetsBarters
    })
    return res.data.data.allAssetsBarters
  }

  const get = async (id) => {
    setLoading();
    const res = await post("getAllAssetsBarter", { id })

    dispatch({
      type: GET_BARTER,
      payload: res?.data?.data?.allAssetsBarters?.nodes?.[0]
    })

    return res?.data?.data?.allAssetsBarters?.nodes?.[0]

  }

  const getRequestBarter = async (option) => {
    setLoading()
    const res = await post("getAssetsBarterRequests",{...option})

    dispatch({
      type: GET_REQUEST_BARTER,
      payload: res?.data?.data?.allAssetsBarterRequests
    })
    return res?.data?.data?.allAssetsBarterRequests
  } 

  const getRequestBarterCurrentOwner = async (option) => {
    setLoading()
    const res = await post("getAssetBarterRequestByCurrentOwner", {...option})
    dispatch({
      type: GET_REQUEST_BARTER,
      payload: res?.data?.data?.getAssetBarterRequestByCurrentOwner
    })
    return res?.data?.data?.getAssetBarterRequestByCurrentOwner
  }

  const getByOwner = async (option) => {
    setLoading();
    const res = await post("getAssetBarterByCurrentOwner", { ...option })
    dispatch({
      type: GET_BY_OWNER_BARTER,
      payload: res?.data?.data?.getAssetBarterByCurrentOwner
    })

    return res?.data?.data?.getAssetBarterByCurrentOwner

  }
  const setOwnedAssetBarter = (val) => {
    dispatch({
      type: SET_OWNED_ASSET_BARTER,
      payload: val
    })
  }
  const setRequestedAssetbarter = (val) => {
    dispatch({
      type: SET_REQUESTED_ASSET_BARTER,
      payload: val
    })
  }
  
  const setBarterParam = (payload, step) => {
    dispatch({
      type: SET_PARAM_BARTER,
      payload: {...payload},
      step: step,
    })
  }
  
  const resetBarterParam = () => {
    dispatch({
      type: RESET_PARAM_BARTER,
    })
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <BarterContext.Provider
      value={{
        barters: state.barters,
        barter: state.barter,
        requestBarters: state.requestBarters,
        ownedAssetBarter: state.ownedAssetBarter,
        requestedAssetbarter: state.requestedAssetbarter,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        loading: state.loading,
        idBarter: state.idBarter,
        create,
        getAll,
        createRequest,
        get,
        getByOwner,
        getRequestBarter,
        getRequestBarterCurrentOwner,
        setOwnedAssetBarter,
        setRequestedAssetbarter,
        setBarterParam,
        resetBarterParam,
      }}
    >
      {props.children}
    </BarterContext.Provider>
  );
};

export default BarterState;
