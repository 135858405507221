import { SET_LOADING, SET_MATIC_PRICE, ADD_BID_DATA, ADD_NOTIFICATION_DATA } from "../types";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MATIC_PRICE:
      return {
        ...state,
        blockchain_price: action.payload,
        loading: false
      }
    case ADD_BID_DATA:
      return {
        ...state,
        bid: state.bid.set(`${action.payload.assetId}`, action.payload.bidPrice),
        loading: false
      }
    case ADD_NOTIFICATION_DATA:
      return {
        ...state,
        bid: [...state.notification, action.payload],
        loading: false
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer
