import { useReducer } from "react";
import logger from "use-reducer-logger";
import Web3Modal from "web3modal";
import web3ModalContext from "./web3ModalContext"
import Web3ModalReducer from "./web3ModalReducer"
import { SET_INJECTED_PROVIDER, SET_LOCAL_CHAINID, SET_LOADING } from "../types"

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
});

const Web3ModalState = (props) => {
  /* eslint-disable */
  const initialState = {
    web3Modal,
    injectedProvider: null,
    localChainId: null
  };
  const [state, dispatch] = useReducer(logger(Web3ModalReducer), initialState);
  /* eslint-enable */

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  const setInjectedProvider = (prov) => {
    setLoading()
    dispatch({
      type: SET_INJECTED_PROVIDER,
      payload: prov
    })
  }

  const setLocalChainId = (chainId) => {
    setLoading()
    dispatch({
      type: SET_LOCAL_CHAINID,
      payload: chainId
    })
  }

  return (
    <web3ModalContext.Provider
      value={{
        web3Modal: state.web3Modal,
        localChainId: state.localChainId,
        injectedProvider: state.injectedProvider,
        setLocalChainId,
        setInjectedProvider
      }}
    >
      {props.children}
    </web3ModalContext.Provider>
  );
};

export default Web3ModalState;
