export const ALL_COLLECTION = 'ALL_COLLECTION';
export const ALL_COLLECTION_WITH_FILTER = 'ALL_COLLECTION_WITH_FILTER';
export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const GET_COLLECTION = 'GET_COLLECTION';
export const CREATE_COLLECTION_VERIFICATION = 'CREATE_COLLECTION_VERIFICATION';

export const ALL_BANK = 'ALL_BANK';
export const CREATE_BANK = 'CREATE_BANK';
export const UPDATE_BANK = 'UPDATE_BANK';
export const GET_BANK = 'GET_BANK';

export const ALL_NOTIFICATION = 'ALL_NOTIFICATION';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UNREAD_NOTIFICATION = 'UNREAD_NOTIFICATION';

export const GET_ACCOUNT_BANK = 'GET_ACCOUNT_BANK';
export const CREATE_ACCOUNT_BANK = 'CREATE_ACCOUNT_BANK';
export const DELETE_ACCOUNT_BANK = 'DELETE_ACCOUNT_BANK';
export const GET_ACTIVE_ACCOUNT_BANK = 'GET_ACTIVE_ACCOUNT_BANK';
export const ACTIVATE_BANK = 'ACTIVATE_BANK';

export const ALL_ASSET = 'ALL_ASSET';
export const ALL_ASSET_UTIL = 'ALL_ASSET_UTIL';
export const ALL_ASSET_UTIL_GST = 'ALL_ASSET_UTIL_GST';
export const ALL_ASSET_CREATED = 'ALL_ASSET_CREATED';
export const ALL_ASSET_OWNED = 'ALL_ASSET_OWNED';
export const ALL_ASSET_SOLD = 'ALL_ASSET_SOLD';
export const ALL_ASSET_NOT_IN_ANOTHER_PROJECT = 'ALL_ASSET_NOT_IN_ANOTHER_PROJECT';
export const GET_ASSET = 'GET_ASSET';
export const UPLOAD_IPFS_FILE = 'UPLOAD_IPFS_FILE';
export const UPLOAD_IPFS_METADATA = 'UPLOAD_IPFS_METADATA';
export const DELETE_ASSET = 'DELETE_ASSET';
export const RESET_ASSET_UTIL = 'RESET_ASSET_UTIL';
export const GET_ASSET_BY_COLLECTION = 'GET_ASSET_BY_COLLECTION';
export const CREATE_ASSET = 'CREATE_ASSET';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const CHECK_USER_HAS_ASSET = 'CHECK_USER_HAS_ASSET';
export const CHECK_ASSET_ITEM_ID_IS_EXIST = 'CHECK_ASSET_ITEM_ID_IS_EXIST';
export const ALL_ASSET_CACHE = 'ALL_ASSET_CACHE';
export const UPLOAD_ASSET_FILE = 'UPLOAD_ASSET_FILE';
export const ALL_NFT_ASSET_BLOCKCHAIN = 'ALL_NFT_ASSET_BLOCKCHAIN';
export const SET_NFT_METADATA = 'SET_NFT_METADATA';
export const SET_ADDRESS_OWNER = 'SET_ADDRESS_OWNER';

export const ALL_ASSET_ACTIVITIES_BY_ASSETID = 'ALL_ASSET_ACTIVITIES_BY_ASSETID';
export const SOLD_FIAT = 'SOLD_FIAT';
export const ALL_ASSET_ACTIVITIES = 'ALL_ASSET_ACTIVITIES';
export const GET_ASSET_PRICE_HISTORY = 'GET_ASSET_PRICE_HISTORY';
export const GET_PROFILE_ASSET_TRANSACTION_HISTORY = 'GET_PROFILE_ASSET_TRANSACTION_HISTORY';
export const CREATE_ASSET_ACTIVITIES = 'CREATE_ASSET_ACTIVITIES';
export const TOP_BUYER = "TOP_BUYER"
export const TOP_SELLER = "TOP_SELLER"
export const TOP_BUYER_MULTICHAIN = "TOP_BUYER_MULTICHAIN"
export const TOP_SELLER_MULTICHAIN = "TOP_SELLER_MULTICHAIN"

export const ALL_ASSET_AUCTION_BY_ASSETID = 'ALL_ASSET_AUCTION_BY_ASSETID';
export const ALL_ASSET_AUCTION_ = 'ALL_ASSET_AUCTION_';
export const ALL_ASSET_AUCTION_IN_PROFILE = 'ALL_ASSET_AUCTION_IN_PROFILE';
export const ALL_ASSET_AUCTION_MADE_IN_PROFILE = 'ALL_ASSET_AUCTION_MADE_IN_PROFILE';
export const CREATE_ASSET_AUCTION = 'CREATE_ASSET_AUCTION';
export const GET_ASSET_AUCTION = 'GET_ASSET_AUCTION';
export const UPDATE_ASSET_AUCTION = 'UPDATE_ASSET_AUCTION';

export const CREATE_ASSET_AUCTION_DETAIL = 'CREATE_ASSET_AUCTION_DETAIL';
export const GET_ASSET_AUCTION_DETAIL = 'GET_ASSET_AUCTION_DETAIL';
export const GET_ALL_AUCTION_IN_PROFILE = 'GET_ALL_AUCTION_IN_PROFILE';
export const DELETE_ASSET_AUCTION_DETAIL = 'DELETE_ASSET_AUCTION_DETAIL'
export const CHECK_USER_BID = 'CHECK_USER_BID';

export const CREATE_ASSET_BID_DETAIL = 'CREATE_ASSET_BID_DETAIL';
export const DELETE_ASSET_BID_DETAIL = 'DELETE_ASSET_BID_DETAIL';
export const UPDATE_ASSET_BID_DETAIL = 'UPDATE_ASSET_BID_DETAIL';
export const GET_ASSET_BID_DETAIL = 'GET_ASSET_BID_DETAIL';
export const GET_ALL_PROFILE_OFFER_MADE = 'GET_ALL_PROFILE_OFFER_MADE';
export const GET_ALL_PROFILE_IN = 'GET_ALL_PROFILE_IN';
export const CHECK_USER_OFFER = 'CHECK_USER_OFFER';
export const IS_BIDDER_HAS_BID = "IS_BIDDER_HAS_BID"
export const GET_BID_DETAIL_BY_ASSET = 'GET_BID_DETAIL_BY_ASSET'

export const CREATE_ASSET_BID = 'CREATE_ASSET_BID';
export const CHECK_INVOICE = 'CHECK_INVOICE';
export const UPDATE_ASSET_BID = 'UPDATE_ASSET_BID';
export const DELETE_ASSET_BID = 'DELETE_ASSET_BID';
export const GET_ASSET_BID_BY_ASSET_ID = "GET_ASSET_BID_BY_ASSET_ID"
export const GET_ASSET_BID_BY_ID = "GET_ASSET_BID_BY_ID"

export const ALL_CATEGORY = 'ALL_CATEGORY';

export const GET_ALL_ACCOUNTS = 'GET_ALL_ACCOUNTS';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const GET_ACCOUNT_BY_ADDRESS = 'GET_ACCOUNT_BY_ADDRESS';
export const GET_ACCOUNT_BY_ID = 'GET_ACCOUNT_BY_ID';
export const GET_ACCOUNT_OWNER_BY_ID = 'GET_ACCOUNT_OWNER_BY_ID';
export const GET_ALL_ACCOUNTS_TOKENS = 'GET_ALL_ACCOUNTS_TOKENS';

export const SET_LOADING = 'SET_LOADING';

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const TOKEN = 'TOKEN'
export const GA_TOKEN = 'GA_TOKEN'
export const GENERATE_AUTHORIZATION_CODE = 'GENERATE_AUTHORIZATION_CODE'
export const SET_CONNECT_WALLET='SET_CONNECT_WALLET'
export const SET_SESSION_LOGIN='SET_SESSION_LOGIN'
export const SET_METAMASK_LOADING='SET_METAMASK_LOADING'
export const SET_CHANGE_ACCOUNT='SET_CHANGE_ACCOUNT'
export const SET_WEB3_CONNECTION='SET_WEB3_CONNECTION'
export const SET_CHANGE_NETWORK='SET_CHANGE_NETWORK'
export const SET_CHANGE_NETWORK_WITH_BLOCKCHAIN_TYPE='SET_CHANGE_NETWORK_WITH_BLOCKCHAIN_TYPE'
export const SET_WRONG_ACCOUNT='SET_WRONG_ACCOUNT'
export const SET_IS_AT_CREATE_ASSET='SET_IS_AT_CREATE_ASSET'

export const SET_INJECTED_PROVIDER='SET_INJECTED_PROVIDER'
export const SET_LOCAL_CHAINID='SET_LOCAL_CHAINID'

export const SET_MATIC_PRICE = 'SET_MATIC_PRICE'
export const ADD_BID_DATA = 'ADD_BID_DATA'
export const ADD_NOTIFICATION_DATA = 'ADD_NOTIFICATION_DATA'


export const SET_IS_OWNER_ALLOWED_FIAT = 'SET_IS_OWNER_ALLOWED_FIAT'
export const ADD_ACTIVE_FILTER = 'ADD_ACTIVE_FILTER'
export const REMOVE_ACTIVE_FILTER = 'REMOVE_ACTIVE_FILTER'
export const ADD_BLOCKCHAIN_FILTER = 'ADD_BLOCKCHAIN_FILTER'
export const REMOVE_BLOCKCHAIN_FILTER = 'REMOVE_BLOCKCHAIN_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
export const ADD_FILTER_CATEGORIES_ID = 'ADD_FILTER_CATEGORIES_ID'
export const REMOVE_FILTER_CATEGORIES_ID = 'REMOVE_FILTER_CATEGORIES_ID'
export const ADD_FILTER_STATUS_SELL = 'ADD_FILTER_STATUS_SELL'
export const REMOVE_FILTER_STATUS_SELL = 'REMOVE_FILTER_STATUS_SELL'
export const ADD_FILTER_COLLECTIONS_ID = 'ADD_FILTER_COLLECTIONS_ID'
export const REMOVE_FILTER_COLLECTIONS_ID = 'REMOVE_FILTER_COLLECTIONS_ID'
export const ADD_FILTER_PROJECT_ID = 'ADD_FILTER_PROJECT_ID'
export const REMOVE_FILTER_PROJECT_ID = 'REMOVE_FILTER_PROJECT_ID'
export const RESET_FILTER_PROJECT_ID = 'RESET_FILTER_PROJECT_ID'
export const SET_FILTER_PRICE = 'SET_FILTER_PRICE'
export const ADD_FILTER_STATUS_LISTING = 'ADD_FILTER_STATUS_LISTING'
export const REMOVE_FILTER_STATUS_LISTING = 'REMOVE_FILTER_STATUS_LISTING'
export const ADD_FILTER_VERIFIED = 'ADD_FILTER_VERIFIED'
export const REMOVE_FILTER_VERIFIED = 'REMOVE_FILTER_VERIFIED'
export const SET_FILTER_SORTED_BY = 'SET_FILTER_SORTED_BY'
export const SET_FILTER_ACTIVITY_ASSET = 'SET_FILTER_ACTIVITY_ASSET'
export const SET_FILTER_SEARCH_ASSET = 'SET_FILTER_SEARCH_ASSET'
export const SET_FILTER_SEARCH_ASSET_UTILITY = 'SET_FILTER_SEARCH_ASSET_UTILITY'
export const SET_FILTER_SEARCH_COLLECTION = 'SET_FILTER_SEARCH_COLLECTION'
export const SET_FILTER_VERIFIED = 'SET_FILTER_VERIFIED'
export const GET_TOP_COLLECTIONS = "GET_TOP_COLLECTIONS"
export const GET_TOP_ASSETS = "GET_TOP_ASSETS"
export const GET_TOP_ACCOUNTS = "GET_TOP_ACCOUNTS"
export const GET_SMART_SEARCH = "GET_SMART_SEARCH"
export const SET_FILTER_SEARCH_TAG = "SET_FILTER_SEARCH_TAG"
export const SET_CATEGORY = "SET_CATEGORY"
export const SET_TYPE_TXN = "SET_TYPE_TXN"
export const SET_TYPE_UTIL = "SET_TYPE_UTIL"
export const SET_UTIL_PUBLISH = "SET_UTIL_PUBLISH"
export const REMOVE_TYPE_UTIL = "REMOVE_FILTER_UTIL"
export const SET_FILTER_SEARCH_PROJECT = 'SET_FILTER_SEARCH_PROJECT'

export const CREATE_INVOICE = "CREATE_INVOICE"
export const ALL_INVOICE = "ALL_INVOICE"
export const GET_UPAID_INVOICE = "GET_UPAID_INVOICE"
export const GET_EXPIRED_INVOICE = "GET_EXPIRED_INVOICE"
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const CHECK_PAYMENT = "CHECK_PAYMENT"
export const CALCULATE_FEE = "CALCULATE_FEE"
export const SIGN_MESSAGE = "SIGN_MESSAGE"
export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS"

export const ALL_DISBURSMENT = "ALL_DISBURSMENT"
export const CREATE_DISBURSMENT = "CREATE_DISBURSMENT"
export const REQUEST_DISBURSMENT = "REQUEST_DISBURSMENT"
export const REQUEST_BATCH_DISBURSMENT = "REQUEST_BATCH_DISBURSMENT"

export const GET_ARTICLES = "GET_ARTICLES"
export const GET_ARTICLE_BY_ID = "GET_ARTICLE_BY_ID"
export const GET_ARTICLE_CATEGORIES = "GET_ARTICLE_CATEGORIES"
export const GET_ARTICLE_CATEGORY_BY_SLUG = "GET_ARTICLE_CATEGORY_BY_SLUG" 
export const GET_SMART_ARTICLES = "GET_SMART_ARTICLES"
export const LOADING_SEARCH_ARTICLE = "LOADING_SEARCH_ARTICLE"

export const GET_VALIDATION_BANK = "GET_VALIDATION_BANK"
export const ADD_VALIDATION_BANK = "ADD_VALIDATION_BANK"
export const GET_VERIFIED_BANK = "GET_VERIFIED_BANK"
export const ADD_VERIFIED_BANK = "ADD_VERIFIED_BANK"
export const GET_ILUMA_BANK = "GET_ILUMA_BANK"
export const SET_WAITING_BANK = "SET_WAITING_BANK"
export const SET_MODAL_BANK = "SET_MODAL_BANK"
export const SET_URL_INV_BANK = "SET_URL_INV_BANK"

export const GET_TXN_LOG = "GET_TXN_LOG"
export const ADD_TXN_LOG = "ADD_TXN_LOG"
export const UPDATE_TXN_LOG = "UPDATE_TXN_LOG"
export const DELETE_TXN_LOG = "DELETE_TXN_LOG"
export const CANCEL_TXN_LOG_CREATE_ASSET = "CANCEL_TXN_LOG_CREATE_ASSET"

export const CREATE_ASSET_PREPROCESS_FIAT = 'CREATE_ASSET_PREPROCESS_FIAT'
export const CREATE_ASSET_PREPROCESS_CRYPTO = 'CREATE_ASSET_PREPROCESS_CRYPTO'
export const LISTING_PREPROCESS_CRYPTO = 'LISTING_PREPROCESS_CRYPTO'
export const LISTING_PREPROCESS_FIAT = 'LISTING_PREPROCESS_FIAT'
export const LISTING_POSTPROCESS_CRYPTO = 'LISTING_POSTPROCESS_CRYPTO'
export const LISTING_POSTPROCESS_FIAT = 'LISTING_POSTPROCESS_FIAT'
export const CREATE_ASSET_POSTPROCESS_FIAT = 'CREATE_ASSET_POSTPROCESS_FIAT'
export const CANCEL_SALE_PREPROCESS_CRYPTO = 'CANCEL_SALE_PREPROCESS_CRYPTO'
export const CANCEL_SALE_PREPROCESS_FIAT = 'CANCEL_SALE_PREPROCESS_FIAT'
export const CANCEL_SALE_POSTPROCESS = 'CANCEL_SALE_POSTPROCES'
export const BUY_PREPROCESS_CRYPTO = 'BUY_PREPROCESS_CRYPTO'
export const BUY_POSTPROCESS = 'BUY_POSTPROCES'
export const ACCEPT_MAKE_OFFER_PRE_PROCESS_FIAT = 'ACCEPT_MAKE_OFFER_PRE_PROCESS_FIAT'
export const ACCEPT_MAKE_OFFER_PRE_PROCESS_CRYPTO = 'ACCEPT_MAKE_OFFER_PRE_PROCESS_CRYPTO'
export const ACCEPT_MAKE_OFFER_POSTPROCESS = 'ACCEPT_MAKE_OFFER_POSTPROCES'
export const CANCEL_MAKE_OFFER_POSTPROCESS = 'CANCEL_MAKE_OFFER_POST_PROCESS'
export const CANCEL_MAKE_OFFER_PRE_PROCESS_FIAT = 'CANCEL_MAKE_OFFER_PRE_PROCESS_FIAT'
export const CREATE_ASSET_FIXEDPRICE_META = 'CREATE_ASSET_FIXEDPRICE_META'
export const CREATE_ASSET_AUCTION_META = 'CREATE_ASSET_AUCTION_META'
export const ENABLE_ASSET_TRANSACTION = 'ENABLE_ASSET_TRANSACTION'


export const CREATE_COLLECTION_POSTPROCESS = 'CREATE_COLLECTION_POSTPROCESS'

export const UPDATE_METADATA_PREPROCESS_CRYPTO = 'UPDATE_METADATA_PREPROCESS_CRYPTO'
export const UPDATE_METADATA_PREPROCESS_FIAT = 'UPDATE_METADATA_PREPROCESS_FIAT'
export const UPDATE_METADATA_POSTPROCESS = 'UPDATE_METADATA_POSTPROCESS'

export const GET_LIST_PROJECT_GUEST = 'GET_LIST_PROJECT_GUEST'
export const GET_PROJECT = 'GET_PROJECT'
export const CREATE_DRAFT_PROJECT = 'CREATE_DRAFT_PROJECT'
export const UPDATE_DRAFT_PROJECT = 'UPDATE_DRAFT_PROJECT'
export const EDIT_DRAFT_PROJECT = 'EDIT_DRAFT_PROJECT'
export const UPLOAD_XLSX_PROJECT = 'UPLOAD_XLSX_PROJECT'
export const UPLOAD_PHOTO_PROJECT = 'UPLOAD_PHOTO_PROJECT'
export const DRAFT_SELECTED_PROJECT_ASSET = 'DRAFT_SELECTED_PROJECT_ASSET'
export const REMOVE_DRAFT_SELECTED_PROJECT_ASSET = 'REMOVE_DRAFT_SELECTED_PROJECT_ASSET'
export const GET_SELECTED_PROJECT_ASSET = 'GET_SELECTED_PROJECT_ASSET'
export const ADD_SELECTED_PROJECT_ASSET = 'ADD_SELECTED_PROJECT_ASSET'
export const EDIT_SELECTED_PROJECT_ASSET = 'EDIT_SELECTED_PROJECT_ASSET'
export const REMOVE_SELECTED_PROJECT_ASSET = 'REMOVET_SELECTED_PROJECT_ASSET'
export const SET_SELECTED_PROJECT_ASSET = 'SET_SELECTED_PROJECT_ASSET'
export const PREVIEW_IMAGE_ASSET_PROJECT = 'PREVIEW_IMAGE_ASSET_PROJECT'
export const REMOVE_DRAFT_SELECTED_PROJECT_ASSET_API = 'REMOVE_DRAFT_SELECTED_PROJECT_ASSET_API'

export const GET_ALL_UTILITAS = "GET_ALL_UTILITAS"
export const GET_ALL_UTILITAS_PROJECTS = "GET_ALL_UTILITAS_PROJECTS"
export const SET_DETAIL_UTILITAS = "SET_DETAIL_UTILITAS"
export const GET_OWNED_UTILITAS = "GET_OWNED_UTILITAS"

export const SET_PARAM_BARTER = "SET_PARAM_BARTER"
export const RESET_PARAM_BARTER = "RESET_PARAM_BARTER"

export const GET_ALL_BARTER = "GET_ALL_BARTER"
export const GET_BY_OWNER_BARTER = "GET_BY_OWNER_BARTER"
export const GET_BARTER = "GET_BARTER"
export const GET_REQUEST_BARTER = "GET_REQUEST_BARTER"
export const CREATE_BARTER = "CREATE_BARTER"
export const CREATE_REQUEST_BARTER = "CREATE_REQUEST_BARTER"
export const SET_OWNED_ASSET_BARTER = "SET_OWNED_ASSET_BARTER"
export const SET_REQUESTED_ASSET_BARTER = "SET_REQUESTED_ASSET_BARTER"