import React from "react";

const ChevronUpNew = ({ viewBox, size, onClick, divClass, color }) => {
  return (
    <div onClick={onClick} className={divClass}>
      <svg
        viewBox={`0 0 ${viewBox} ${viewBox}`}
        className={`h-${size} w-${size} fill-current`}
      >
        <path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z" fill={color}/>
      </svg>
    </div>
  );
};

ChevronUpNew.defaultProps = {
  viewBox: 24,
  size: 6,
  onClick: () => {},
  color: "#868686",
};

export default ChevronUpNew;
