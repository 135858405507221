import React, { useState } from "react"
import HeaderMobileContext from "./HeaderMobileContext"

const HeaderMobileState = (props) => {
    const [showMenu, setShowMenu] = useState(false)
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [showCollaps, setShowCollaps] = useState(false)
    const [subMenuParam, setSubMenuParam] = useState(0)
    const [activeName, setActiveName] = useState("")
    const [triggerBanner, setTriggerBanner] = useState(false)
    const [bgLanding, setBgLanding] = useState(false)
    const [showBanner, setShowBanner] = useState(false)
    
    const handleBackPage = (param) => {
        setShowMenu(true)
        setShowSubMenu(true)
        setSubMenuParam(param)
    }

    const handleCloseMenu = () => {
        setShowMenu(false)
        setShowSubMenu(false)
        setShowCollaps(false)
    }

    return (
        <HeaderMobileContext.Provider 
            value={{
                bgLanding,
                setBgLanding,
                triggerBanner,
                setTriggerBanner,
                showMenu, 
                showSubMenu, 
                setShowMenu, 
                setShowSubMenu, 
                showCollaps, 
                setShowCollaps, 
                subMenuParam, 
                setSubMenuParam, 
                handleBackPage, 
                handleCloseMenu,
                activeName, 
                setActiveName,
                showBanner,
                setShowBanner
            }}>
            {props.children}
        </HeaderMobileContext.Provider>
    )
}

export default HeaderMobileState