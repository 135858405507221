import {
  ALL_CATEGORY,
  SET_LOADING,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case ALL_CATEGORY:
      return {
        ...state,
        categories: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
