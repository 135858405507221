import classNames from 'classnames'
import React from 'react'
import Card from './Card'

const CardModal = React.forwardRef( ({ children, className, ...props },ref) => {
    return (
        <Card 
            ref={ref}
            id="card-modal"
         {...props} className={`bg-white flex flex-col md:rounded-xl rounded-t-xl ${classNames({
            'md:py-9 py-6': !className?.includes('py'),
            'md:px-8 px-8': !className?.includes('px'),
            'items-center':!className?.includes('items-'),
            'w-full':className?.includes('w-full')
        })} ${className}`} >
            {children}
        </Card>
    )
})

export default CardModal