export const POLYGON_MAINNET = {
  chainId: 137,
  rpcUrl: ["https://polygon-rpc.com/"],
  chainName: "POLYGON MAINNET",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrl:["https://polygonscan.com/"]
}

export const POLYGON_MUMBAI = {
  chainId: 80001,
  rpcUrl: ["https://rpc-mumbai.matic.today/"],
  chainName: "POLYGON MUMBAI",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrl:["https://mumbai.polygonscan.com/"]
}

export const ETHEREUM_MAINNET = {
  chainId: 1,
  rpcUrl: ["https://eth-mainnet.public.blastapi.io"],
  chainName: "Ethereum",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrl:["https://etherscan.io/"]
}

export const ETHEREUM_RINKEBY = {
  chainId: 4,
  rpcUrl: ["https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  chainName: "Rinkeby",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
  },
  blockExplorerUrl:["https://rinkeby.etherscan.io/"]
}

export const BNC_MAINNET = {
  chainId: 56,
  rpcUrl: ["https://dataseed1.binance.org/"],
  chainName: "bnc mainnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  },
  blockExplorerUrl:["https://bscscan.com"]
}

export const BNC_TESTNET = {
  chainId: 97,
  rpcUrl: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
  chainName: "bnc testnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  },
  blockExplorerUrl:["https://testnet.bscscan.com/"]
}