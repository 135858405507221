import React, { useReducer } from 'react';
import CollectionContext from './collectionContext';
import CollectionReducer from './collectionReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { ALL_COLLECTION, CREATE_COLLECTION_VERIFICATION, SET_LOADING, CREATE_COLLECTION, GET_COLLECTION, ALL_COLLECTION_WITH_FILTER } from '../types';
import logger from 'use-reducer-logger';

const CollectionState = props => {
  const initialState = {
    collections: [],
    collection: {},
    totalCount: 0,
    pageInfo: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(CollectionReducer), initialState);

  // All Collection
  const getAllCollections = async (collectionOptions) => {
    setLoading();
    try {

      const res = await post("getAllCollections",{...collectionOptions});
      dispatch({
        type: ALL_COLLECTION,
        payload: res.data.data.allCollections
      });
      return res.data.data.allCollections
    } catch (error) {
      console.log('ERR: ', error);
    }
  };

  // get collection by id
  const getCollection = async (id) => {

    setLoading()
    try {

      const res = await post("getCollectionById", { id })

      dispatch({
        type: GET_COLLECTION,
        payload: res?.data?.data?.collectionById
      })

      return res?.data?.data?.collectionById

    } catch (error) {
      console.log('ERR: ', error);
    }
  }

  // update collection by id
  const updateCollection = async (id, patch) => {

    setLoading()
    try {
      console.log('zap update collection data at context state:',id, patch);

      const res = await post("updateCollectionById", { id: parseInt(id), patch })

      dispatch({
        type: GET_COLLECTION,
        payload: res?.data?.data?.collectionById
      })

      return res?.data?.data?.collectionById

    } catch (error) {
      console.log('ERR: ', error);
    }
  }

  // All Collection
  const getAllCollectionsWithFilter = async (collectionOptions) => {
    setLoading();

    try {

      const res = await post("getAllCollectionsWithFilter", { ...collectionOptions });
      dispatch({
        type: ALL_COLLECTION_WITH_FILTER,
        payload: res.data.data.allCollections.nodes,
        totalCount: res?.data?.data?.allCollections?.totalCount,
        pageInfo: res?.data?.data?.allCollections?.pageInfo,
      });

      return res.data.data.allCollections
    } catch (error) {
      console.log('ERR: ', error);
    }
  };

  const getCollectionByOwnerId = async (ownerId, blockchainType) => {
    setLoading()
    try {
      const res = await post('getCollectionByOwnerId', { ownerId, blockchainType })
      dispatch({
        type: ALL_COLLECTION,
        payload: res.data.data.allCollections
      });
        return res.data.data.allCollections?.nodes
    } catch (error) {
      console.log("ERR: ", error);
    }
  }

  // create Collection
  const createCollection = async (collectionInput) => {
    setLoading();
    try {
      const res = await post("createCollection", { ...collectionInput });
      dispatch({
        type: CREATE_COLLECTION,
        payload: res.data.data.createCollection.collection
      });
      return res.data.data.createCollection.collection
    } catch (error) {
      console.log('Err:', error);
    }
  };

  // create Collection
  const requestCollectionVerification = async (createCollectionVerificationRequest) => {
    setLoading();
    try {
      const res = await post("createCollectionVerificationRequest", { ...createCollectionVerificationRequest });
      dispatch({
        type: CREATE_COLLECTION_VERIFICATION,
        payload: res.data.data.allCollections.nodes
      });
    } catch (error) {
      console.log('Err:', error);
    }
  };

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <CollectionContext.Provider
      value={{
        collections: state.collections,
        collection: state.collection,
        loading: state.loading,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        getAllCollections,
        getCollection,
        createCollection,
        getCollectionByOwnerId,
        getAllCollectionsWithFilter,
        updateCollection,
        requestCollectionVerification,
      }}
    >
      {props.children}
    </CollectionContext.Provider>
  );
};

export default CollectionState;
