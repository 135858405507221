import {
  CHECK_USER_BID,
  CREATE_ASSET_AUCTION_DETAIL,
  GET_ALL_AUCTION_IN_PROFILE,
  SET_LOADING,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_ASSET_AUCTION_DETAIL:
      return {
        ...state,
        assetAuctionDetail: action.payload,
        loading: false
      };
    case CHECK_USER_BID:
      return {
        ...state,
        loading: false,
        isBid: action.payload,
      }
    case GET_ALL_AUCTION_IN_PROFILE:
      return {
        ...state,
        auctionOfferMade: action.payload,
        loading: false
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
