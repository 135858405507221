import {
  ALL_ASSET_ACTIVITIES,
  ALL_ASSET_ACTIVITIES_BY_ASSETID,
  CREATE_ASSET_ACTIVITIES,
  GET_ASSET_PRICE_HISTORY,
  GET_PROFILE_ASSET_TRANSACTION_HISTORY,
  SET_LOADING,
  SOLD_FIAT,
  // TOP_BUYER,
  TOP_BUYER_MULTICHAIN,
  // TOP_SELLER,
  TOP_SELLER_MULTICHAIN,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case GET_PROFILE_ASSET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: action.payload,
        loading: false
      }
    case GET_ASSET_PRICE_HISTORY:
      return {
        ...state,
        priceHistory: action.payload,
        loading: false
      }
    case ALL_ASSET_ACTIVITIES_BY_ASSETID:
      return {
        ...state,
        assetActivities: action.payload,
        loading: false
      };
    case CREATE_ASSET_ACTIVITIES:
      return {
        ...state,
        assetActivity: action.payload,
        loading: false
      };
    case ALL_ASSET_ACTIVITIES:
      return {
        ...state,
        assetActivities: action?.after ? state?.assetActivities?.concat(action.payload.nodes) : action.payload.nodes,
        totalCount: action?.payload?.totalCount,
        pageInfo: action?.payload?.pageInfo,
        loading: false
      }
    case SOLD_FIAT:
      return{
        ...state,
        assetActivities: action?.nodes,
        totalCount: action?.payload?.totalCount,
        pageInfo: action?.payload?.pageInfo,
        loading:false
      }
      case TOP_SELLER_MULTICHAIN:
        return{
          ...state,
          topSellers:action?.payload?.nodes,
          loading:false
        }
      case TOP_BUYER_MULTICHAIN:
        return{
          ...state,
          topBuyers:action?.payload?.nodes,
          loading:false
        }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
