import React from "react";

const SellIcon = ({viewBox, size, onClick, color, ...props}) => {
    return (
        <div onClick={onClick} {...props}>
            <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
            <path d="M12.75 4.5C12.5511 4.5 12.3603 4.57902 12.2197 4.71967C12.079 4.86032 12 5.05109 12 5.25V11.25C12 11.4489 12.079 11.6397 12.2197 11.7803C12.3603 11.921 12.5511 12 12.75 12H18.75C18.9489 12 19.1397 11.921 19.2803 11.7803C19.421 11.6397 19.5 11.4489 19.5 11.25C19.5 9.45979 18.7888 7.7429 17.523 6.47703C16.2571 5.21116 14.5402 4.5 12.75 4.5V4.5ZM13.5 10.5V6.0525C14.6222 6.21421 15.6618 6.73483 16.4635 7.53651C17.2652 8.33819 17.7858 9.37785 17.9475 10.5H13.5Z" fill={color}/>
            <path d="M18.6142 13.545C18.5211 13.5117 18.4224 13.4972 18.3237 13.5022C18.225 13.5072 18.1283 13.5317 18.039 13.5742C17.9498 13.6167 17.8699 13.6764 17.8039 13.7499C17.7378 13.8234 17.6869 13.9093 17.6542 14.0025C17.3162 14.9579 16.7417 15.8122 15.9843 16.4856C15.2269 17.159 14.3112 17.6295 13.3228 17.8533C12.3344 18.0772 11.3053 18.047 10.3317 17.7656C9.35811 17.4842 8.47164 16.9608 7.75501 16.2441C7.03839 15.5275 6.51496 14.641 6.23357 13.6674C5.95217 12.6938 5.92198 11.6648 6.14581 10.6764C6.36964 9.68793 6.8402 8.7723 7.51357 8.01489C8.18695 7.25749 9.04121 6.68299 9.99665 6.345C10.1846 6.27936 10.3388 6.14174 10.4253 5.9624C10.5118 5.78307 10.5235 5.57672 10.4579 5.38875C10.3923 5.20078 10.2546 5.04658 10.0753 4.96008C9.89597 4.87358 9.68962 4.86186 9.50165 4.9275C8.30693 5.34958 7.23863 6.06747 6.39644 7.01417C5.55425 7.96086 4.96564 9.10549 4.68554 10.3412C4.40545 11.577 4.44299 12.8635 4.79468 14.0808C5.14637 15.2981 5.80073 16.4065 6.6967 17.3025C7.59266 18.1984 8.70102 18.8528 9.91832 19.2045C11.1356 19.5562 12.4222 19.5937 13.6579 19.3136C14.8937 19.0335 16.0383 18.4449 16.985 17.6027C17.9317 16.7605 18.6496 15.6922 19.0717 14.4975C19.1365 14.3105 19.1248 14.1054 19.0391 13.927C18.9534 13.7486 18.8007 13.6113 18.6142 13.545Z" fill={color}/>    
            </svg>
        </div>
    )
}

SellIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: "#868686",
}

export default SellIcon