import React from 'react';
import Button from './Button';
const ButtonLink = (props) => {
  const { children } = props;
  return (
    <Button
      {...props}
      color="bg-transparent"
      classstyle={`cursor-pointer ${props?.classstyle}`}>
      {children}
    </Button>
  );
};

export default React.memo(ButtonLink);
