import {
  LOGIN,
  LOGOUT,
  SET_LOADING,
  TOKEN, 
  GA_TOKEN,
  SET_CONNECT_WALLET,
  SET_SESSION_LOGIN,
  SET_METAMASK_LOADING,
  SET_CHANGE_ACCOUNT,
  SET_WEB3_CONNECTION,
  SET_CHANGE_NETWORK,
  SET_WRONG_ACCOUNT,
  SET_IS_AT_CREATE_ASSET,
  GENERATE_AUTHORIZATION_CODE,
  SET_CHANGE_NETWORK_WITH_BLOCKCHAIN_TYPE
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        auth: action.payload,
        loading: false
      };
    case LOGOUT:
      return {
        ...state,
        auth: {},
        loading: false
      };
    case TOKEN:
      return {
        ...state,
        auth: action.payload,
        loading: false
      };
    case GENERATE_AUTHORIZATION_CODE:
      return {
        ...state,
        authorizationCode: action.payload.code,
        loading: false
      }
    case GA_TOKEN:
      return {
        ...state,
        atga: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CONNECT_WALLET:
      return {
        ...state,
        connWallet: action.payload,
        loading: false
      }
    case SET_SESSION_LOGIN:
      return {
        ...state,
        session: action.payload,
        loading: false
      }
    case SET_METAMASK_LOADING:
      return {
        ...state,
        metamaskLoading: action.payload,
        loading: false
      }
    case SET_CHANGE_ACCOUNT:
      return {
        ...state,
        changeAccount: action.payload,
        loading: false
      }
    case SET_WEB3_CONNECTION:
      return {
        ...state,
        web3Connection: action.payload,
        loading: false
      }
    case SET_CHANGE_NETWORK_WITH_BLOCKCHAIN_TYPE:
      return {
        ...state,
        wrongAccount: action.payload.bool,
        blockchainType: action.payload.blockchainType,
        loading: false
      }
    case SET_CHANGE_NETWORK:
      return {
        ...state,
        changeNetwork: action.payload,
        blockchainType: '',
        loading: false
      }
    case SET_WRONG_ACCOUNT:
      return {
        ...state,
        wrongAccount: action.payload,
        loading: false
      }
    case SET_IS_AT_CREATE_ASSET:
      return {
        ...state,
        isAtCreateAsset: action.payload,
        loading: false
      }
    default:
      return state;
  }
};

export default reducer
