import React, { useContext, useState } from 'react'
import { MetamaskLogo } from '../../assets'
import ChevronRightIcon from '../../assets/icon/checvron_right_icon'
import RoundedButton from '../Button/RoundedButton'
import CardModal from '../Card/CardModal'
import ButtonText from '../Text/ButtonText'
import ModalTitleText from '../Text/ModalTitleText'
import Modal from './Modal'
import P from './P'
import ButtonModal from './ButtonModal'

import Cookies from 'js-cookie'
import { ethers } from 'ethers'
import authContext from '../../context/Auth/authContext'
import { toast } from 'react-toastify'
import web3ModalContext from '../../context/Web3Modal/web3ModalContext'
import ResponsiveContext from '../../context/Responsive/responsiveContext'
import env from '../../config/env'
import { useNavigate } from 'react-router-dom'
import HeaderMobileContext from '../../context/HeaderMobile/HeaderMobileContext'

const ModalConnectWallet = props => {
  // const { fontSize = 'text-lg'} = props;
  const Web3ModalContext = useContext(web3ModalContext)
  const {
    setSession,
    setWeb3Connection,
    setMetamaskLoading,
    setIsAtCreateAsset,
    setConnWallet,
    login,
    isAtCreateAsset,
    // connWallet
  } = useContext(authContext)
  const responsive = useContext(ResponsiveContext)
  const header = useContext(HeaderMobileContext)
  const { handleCloseMenu } = header
  const navigate = useNavigate()
  const [/* localChainId, */ setLocalChainId] = useState(null)

  const [injectedProvider, setInjectedProvider] = useState(null)

  const constructMessageHash = userAddress => {
    const messageHash = ethers.utils.solidityKeccak256(
      ['string'],
      [`I want to login with ${userAddress}`],
    )
    return messageHash
  }

  const logoutOfWeb3Modal = async () => {
    navigate('/')
    await Web3ModalContext.web3Modal.clearCachedProvider()
    if (
      injectedProvider &&
      injectedProvider.provider &&
      typeof injectedProvider.provider.disconnect == 'function'
    ) {
      await injectedProvider.provider.disconnect()
    }
    setTimeout(() => {
      window.location.reload()
    }, 1)
  }

  const connect = async () => {
    try {
      setConnWallet(false)
      setMetamaskLoading(true)
      if (typeof window.ethereum !== 'undefined') {
        if (!responsive) {
          // const permissions = await window.ethereum.request({
          //     method: "wallet_getPermissions"
          // })
          // console.log("zap permissions", permissions)
          const requestPermission = await window.ethereum.request({
            method: 'wallet_requestPermissions',
            params: [
              {
                eth_accounts: {},
              },
            ],
          })
          console.log('zap request', requestPermission)
          // await window.ethereum.request({
          //     method: 'eth_requestAccounts'
          // })
        }
        const connection = await Web3ModalContext?.web3Modal.connectTo(
          'injected',
        )
        setWeb3Connection(connection)
        const provider = new ethers.providers.Web3Provider(connection)
        const { chainId } = await provider.getNetwork()
        setInjectedProvider(provider)
        const signer = provider.getSigner()
        const userAddress = await signer.getAddress()
        const messageHash = constructMessageHash(userAddress)
        const signature = await signer.signMessage(messageHash)
        const input = {
          signature,
          userAddress,
        }
        await login(input)
        Cookies.set('web3login', true)
        setSession(true)
        setLocalChainId(chainId)
        setMetamaskLoading(false)
        setConnWallet(false)
      } else {
        if (responsive && env.DEV === 'false') {
          window.open(
            `https://metamask.app.link/dapp/${window.location.host}`,
            '_self',
          )
        } else {
          const toastId = toast.error(
            <div>
              Install ekstension Metamask ke browser Anda terlebih dahulu.
              Petunjuknya ada di
              <p
                className="text-blue-500"
                onClick={() => {
                  // window.open("https://metamask.io/download/", '_blank', 'noopener,noreferrer')
                  toast.dismiss(toastId)
                  navigate('/faq')
                  handleCloseMenu()
                }}
              >
                {' '}
                sini
              </p>
            </div>,
            {
              closeButton: true,
              autoClose: false,
            },
          )
          setMetamaskLoading(false)
          navigate('/')
        }
        setConnWallet(false)
      }
    } catch (error) {
      console.log('error', error)
      if (error?.code === 4001) {
        setMetamaskLoading(false)
        // di reject signing nya
        await logoutOfWeb3Modal()
      }
    }
  }

  const handleKembali = () => {
    if (isAtCreateAsset) {
      setIsAtCreateAsset(false)
      navigate('/')
      setConnWallet(false)
    } else {
      setConnWallet(false)
    }
  }

  return (
    <Modal {...props}>
      <CardModal>
        <ModalTitleText text="Hubungkan ke Wallet Kamu!" />
        <img alt="metamask" className="mb-2" src={MetamaskLogo} />
        <P>Metamask</P>
        <div className="grid grid-cols-2 gap-4 px-4">
          <RoundedButton
            onClick={handleKembali}
            className={`border border-hijau_hutan py-3`}
            color="bg-white"
          >
            <ButtonText
              classstyle={`font-semibold text-base`}
              tx={'Kembali'}
              color="text-hijau_hutan"
            />
          </RoundedButton>
          <ButtonModal
            buttonTextSyle="pr-2"
            onClick={connect}
            tx="Hubungkan"
            buttonStyle="md:px-6 px-4 py-3"
          >
            <ChevronRightIcon className="mr-1" opacity="0.7" />
            <ChevronRightIcon opacity="0.8" className="mr-1" />
            <ChevronRightIcon className="mr-1" />
          </ButtonModal>
        </div>
      </CardModal>
    </Modal>
  )
}

export default ModalConnectWallet
