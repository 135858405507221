import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

const ButtonText = ({ tx, text, children, classstyle, color = "text-white", ...props }) => {
    const { t } = useTranslation();
    const translatedText = tx && t(tx)
    const content = translatedText || text || children

    return <p {...props} className={`${color} ${classNames({
        'font-semibold': !classstyle?.includes('font'),
        "text-sm": !classstyle?.includes('text'),
        "tracking-wider": !classstyle?.includes('tracking'),
    })} font-poppins ${classstyle}`}>
        {content}
    </p>
}

export default ButtonText