import React, { useReducer } from 'react';
import AssetActivitiesContext from './assetActivitiesContext';
import AssetActivitiesReducer from './assetActivitiesReducer';
import { post } from '../../utils/graphql-api';
import { ALL_ASSET_ACTIVITIES, ALL_ASSET_ACTIVITIES_BY_ASSETID, CREATE_ASSET_ACTIVITIES, GET_ASSET_PRICE_HISTORY, GET_PROFILE_ASSET_TRANSACTION_HISTORY, SET_LOADING, SOLD_FIAT, TOP_BUYER, TOP_BUYER_MULTICHAIN, TOP_SELLER, TOP_SELLER_MULTICHAIN } from '../types';
import logger from 'use-reducer-logger';

const AssetActivitiesState = props => {
  const initialState = {
    assetActivities: [],
    assetActivity: {},
    priceHistory: [],
    transactionHistory: [],
    topSellers: [],
    topBuyers: [],
    totalCount: 0,
    pageInfo: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(AssetActivitiesReducer), initialState);

  // asset activities by asset id
  const getAssetPriceHistory = async (assetId) => {
    setLoading();
    const res = await post("assetPriceHistory", { assetId });
    dispatch({
      type: GET_ASSET_PRICE_HISTORY,
      payload: res?.data?.data?.allAssetsActivities?.nodes
    });
  };
  // asset activities by asset id
  const getProfileTransactionHistory = async (toAccount) => {
    setLoading();
    const res = await post("profileTransactionHistory", { toAccount });
    dispatch({
      type: GET_PROFILE_ASSET_TRANSACTION_HISTORY,
      payload: res?.data?.data?.allAssetsActivities?.nodes
    });
  };
  // asset activities by asset id
  const getAllAssetActivityByAssetId = async (assetId) => {
    setLoading();
    const res = await post("assetActivitiesByAssetId", { assetId });
    dispatch({
      type: ALL_ASSET_ACTIVITIES_BY_ASSETID,
      payload: res?.data?.data?.allAssetsActivities?.nodes
    });
  };

  // all asset activities
  const allAssetActivities = async (filter) => {
    setLoading();
    const res = await post("getAssetsActivites", { ...filter });
    dispatch({
      type: ALL_ASSET_ACTIVITIES,
      payload: res?.data?.data?.allAssetsActivities,
      after: filter?.after,
    });
    return res?.data?.data?.allAssetsActivities
  };

  const createAssetActivities = async (assetInput) => {
    setLoading();
    const res = await post("createAssetActivities", { ...assetInput });
    dispatch({
      type: CREATE_ASSET_ACTIVITIES,
      payload: res.data.data.createAssetsActivity.assetsActivity
    });

  }

  const soldFiat = async (filterInput) => {
    setLoading()
    const res = await post("soldFiat", { ...filterInput })
    dispatch({
      type: SOLD_FIAT,
      payload: res.data.data.assetActivitiesToAccountInvoice
    });
    return res.data.data.assetActivitiesToAccountInvoice
  }

  const topBuyer = async () => {
    setLoading()
    const res = await post("topBuyer")
    dispatch({
      type: TOP_BUYER,
      payload: res?.data?.data?.topBuyer
    })
    return res?.data?.data?.topBuyer
  }
  
  const topSeller = async () => {
    setLoading()
    const res = await post("topSeller")
    dispatch({
      type: TOP_SELLER,
      payload: res?.data?.data?.topSeller
    })
    return res?.data?.data?.topSeller
  }
  
  const topBuyerMultichain = async (blockchainType) => {
    setLoading()
    const res = await post("topBuyerMultichain", {blockchainType: blockchainType})
    dispatch({
      type: TOP_BUYER_MULTICHAIN,
      payload: res?.data?.data?.topBuyerMultichain
    })
    return res?.data?.data?.topBuyerMultichain
  }

  const topSellerMultichain = async (blockchainType) => {
    setLoading()
    const res = await post("topSellerMultichain", {blockchainType: blockchainType})
    dispatch({
      type: TOP_SELLER_MULTICHAIN,
      payload: res?.data?.data?.topSellerMultichain
    })
    return res?.data?.data?.topSellerMultichain
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <AssetActivitiesContext.Provider
      value={{
        assetActivities: state.assetActivities,
        assetActivity: state.assetActivity,
        priceHistory: state.priceHistory,
        transactionHistory: state.transactionHistory,
        loading: state.loading,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        topSellers: state.topSellers,
        topBuyers: state.topBuyers,
        getAllAssetActivityByAssetId,
        createAssetActivities,
        allAssetActivities,
        getAssetPriceHistory,
        getProfileTransactionHistory,
        soldFiat,
        topBuyer,
        topSeller,
        topSellerMultichain,
        topBuyerMultichain,
      }}
    >
      {props.children}
    </AssetActivitiesContext.Provider>
  );
};

export default AssetActivitiesState;
