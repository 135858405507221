import React, { useReducer } from 'react'
import AssetContext from './assetContext'
import AssetReducer from './assetReducer'
import { post } from '../../utils/graphql-api'
import {
  ALL_ASSET,
  ALL_ASSET_CACHE,
  ALL_ASSET_CREATED,
  ALL_ASSET_NOT_IN_ANOTHER_PROJECT,
  ALL_ASSET_OWNED,
  ALL_ASSET_SOLD,
  ALL_ASSET_UTIL,
  ALL_ASSET_UTIL_GST,
  ALL_NFT_ASSET_BLOCKCHAIN,
  CHECK_ASSET_ITEM_ID_IS_EXIST,
  CHECK_INVOICE,
  CHECK_USER_HAS_ASSET,
  CREATE_ASSET,
  DELETE_ASSET,
  GET_ASSET,
  GET_ASSET_BY_COLLECTION,
  RESET_ASSET_UTIL,
  SET_ADDRESS_OWNER,
  SET_LOADING,
  // SET_NFT_METADATA,
  UPDATE_ASSET,
  UPLOAD_ASSET_FILE,
  // UPLOAD_IPFS_FILE,
  // UPLOAD_IPFS_METADATA,
} from '../types'
import logger from 'use-reducer-logger'
import api from '../../utils/api'
import apiUpload from '../../utils/apiUpload'

// const UPLOAD_TIMEOUT = 1800000

const AssetState = props => {
  const initialState = {
    assets: [],
    assetsUtil: [],
    allAssetsBlockchain: [],
    hasTokenId: false,
    asset: {},
    totalCount: 0,
    pageInfo: {},
    topTags: [],
    banners: [],
    bannersCollection: [],
    randomAssets: [],
    categories: [],
    addressOwnerTemp: '',
    loading: false,
  }

  const [state, dispatch] = useReducer(logger(AssetReducer), initialState)

  const getAllNFTBlockchain = async parameter => {
    setLoading()
    let res
    try {
      res = await api.get('/nft_index/getNft', { params: parameter })
      await dispatch({
        type: ALL_NFT_ASSET_BLOCKCHAIN,
        payload: res?.data?.data?.ownedNfts,
        totalCount: res?.data?.data?.totalCount,
      })
    } catch (error) {
      res = error.response
    }
    return res?.data?.data
  }
  const getNFTMetadata = async parameter => {
    setLoading()
    let res
    try {
      res = await api.get('/nft_index/getNftMetadata', { params: parameter })
      await dispatch({
        type: GET_ASSET,
        payload: res?.data?.data,
      })
    } catch (error) {
      res = error.response
    }
    return res?.data
  }

  const getAllAssetsUtil = async assetOption => {
    setLoading()
    const res = await post('daftarUtilitasAsset', { ...assetOption })
    await dispatch({
      type: ALL_ASSET_UTIL,
      payload: res?.data?.data?.allAssetsUtilities?.nodes,
      totalCount: res?.data?.data?.allAssetsUtilities?.totalCount,
      pageInfo: res?.data?.data?.allAssetsUtilities?.pageInfo,
    })
    return res?.data?.data?.allAssetsUtilities
  }

  const getAllAssetsUtilGst = async assetOption => {
    setLoading()
    const res = await post('daftarUtilitasAssetGst', { ...assetOption })
    await dispatch({
      type: ALL_ASSET_UTIL_GST,
      payload: res?.data?.data?.allAssetsUtilities?.nodes,
      totalCount: res?.data?.data?.allAssetsUtilities?.totalCount,
      pageInfo: res?.data?.data?.allAssetsUtilities?.pageInfo,
    })
    return res?.data?.data?.allAssetsUtilities
  }

  const resetUtil = async () => {
    setLoading()
    await dispatch({
      type: RESET_ASSET_UTIL,
    })
  }

  // Search Users
  const getAllAssets = async assetOption => {
    setLoading()
    const res = await post('getAllAssets', { ...assetOption })
    await dispatch({
      type: ALL_ASSET,
      payload: res?.data?.data?.allAssets?.nodes,
      totalCount: res?.data?.data?.allAssets?.totalCount,
      pageInfo: res?.data?.data?.allAssets?.pageInfo,
    })
    return res?.data?.data?.allAssets
  }

  const getCache = async filter => {
    setLoading()
    const res = await api.post('/asset/cache', { filter })
    await dispatch({
      type: ALL_ASSET_CACHE,
      payload: res?.data?.data,
    })
    return res?.data
  }

  const checkInvoice = async id => {
    setLoading()
    const res = await api.post('/asset/check_invoice', { id })
    await dispatch({
      type: CHECK_INVOICE,
      // payload: res?.data?.data?.allAssets?.nodes,
      // totalCount: res?.data?.data?.allAssets?.totalCount,
      // pageInfo: res?.data?.data?.allAssets?.pageInfo,
    })
    return res?.data?.isSigning
  }

  const insertTags = async (assetId, arrayTags) => {
    setLoading()
    const res = await api.post('/asset/tags', { arrayTags, assetId })
    return res?.data
  }

  const checkUserHasTokenId = async (tokenId, ownerId) => {
    setLoading()
    const res = await post('getAllAssets', { ownerId, tokenId })
    await dispatch({
      payload: res?.data?.data?.allAssets,
      type: CHECK_USER_HAS_ASSET,
    })
    return res?.data?.data?.allAssets
  }

  const checkAssetItemId = async (itemContractId, blockchainType = null) => {
    setLoading()
    const res = await post('getAllAssets', {
      itemContractId,
      blockchainType,
      greaterThanZero: null,
    })

    await dispatch({
      payload: res?.data?.data?.allAssets,
      type: CHECK_ASSET_ITEM_ID_IS_EXIST,
    })

    return res?.data?.data?.allAssets
  }

  // get collection by id
  const getCollection = async assetOption => {
    setLoading()
    // try {
    const res = await post('getAssetByCollectionId', { ...assetOption })
    dispatch({
      type: GET_ASSET_BY_COLLECTION,
      payload: res?.data?.data?.collectionById?.assetsByCollectionId?.nodes,
      totalCount:
        res?.data?.data?.collectionById?.assetsByCollectionId?.totalCount,
      pageInfo: res?.data?.data?.collectionById?.assetsByCollectionId?.pageInfo,
    })
    return res?.data?.data?.collectionById?.assetsByCollectionId
    // } catch (error) {
    //   console.log("ERR :", error);
    // }
  }

  const allOwnedAsset = async (ownerId, assetOption) => {
    setLoading()
    const res = await post('ownedAssetByOwnerId', { ownerId, ...assetOption })
    await dispatch({
      type: ALL_ASSET_OWNED,
      payload: res?.data?.data?.allAssets?.nodes,
      totalCount: res?.data?.data?.allAssets?.totalCount,
      pageInfo: res?.data?.data?.allAssets?.pageInfo,
    })
    return res?.data?.data?.allAssets
  }

  const allAssetNotInAnotherProject = async assetOption => {
    setLoading()
    const res = await post('getAssetsNotInUtilityProjects', { ...assetOption })
    await dispatch({
      type: ALL_ASSET_NOT_IN_ANOTHER_PROJECT,
      payload: res?.data?.data?.getAssetsNotInUtilityProjects?.nodes,
      totalCount: res?.data?.data?.getAssetsNotInUtilityProjects?.totalCount,
      pageInfo: res?.data?.data?.getAssetsNotInUtilityProjects?.pageInfo,
    })
    return res?.data?.data?.getAssetsNotInUtilityProjects
  }

  const checkAllAssetSelected = async assetOption => {
    setLoading()
    const res = await post('getAssetsNotInUtilityProjects', { ...assetOption })
    return res?.data?.data?.getAssetsNotInUtilityProjects
  }

  const uploadAsset = async input => {
    setLoading()
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }
    const res = await apiUpload.post('/asset/upload', input, config)
    await dispatch({
      type: UPLOAD_ASSET_FILE,
      payload: res?.data,
    })
    return res?.data
  }

  const uploadIpfsFile = async input => {
    setLoading()
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }
    const res = await apiUpload.post('/asset/uploadIpfsFile', input, config)
    await dispatch({
      type: UPLOAD_ASSET_FILE,
      payload: res?.data,
    })
    return res?.data
  }

  const uploadIpfsMetadata = async (name, description, image) => {
    setLoading()
    const res = await apiUpload.post('/asset/uploadIpfsMetadata', {
      name,
      description,
      image,
    })
    await dispatch({
      type: UPLOAD_ASSET_FILE,
      payload: res?.data,
    })
    return res?.data
  }

  const allCreatedAsset = async creatorId => {
    setLoading()
    const res = await post('assetCreated', { creatorId })
    await dispatch({
      type: ALL_ASSET_CREATED,
      payload: res?.data?.data?.createdAsset?.nodes,
    })
    return res?.data?.data?.createdAsset
  }

  const allSoldAsset = async sellerAccount => {
    setLoading()
    try {
      const res = await post('profileSoldAsset', { sellerAccount })
      await dispatch({
        type: ALL_ASSET_SOLD,
        payload: res?.data?.data?.allAssetsActivities?.nodes,
      })
      return res?.data?.data?.allAssetsActivities?.nodes
    } catch (error) {
      await dispatch({
        type: ALL_ASSET_SOLD,
        payload: [],
      })
    }
  }

  const _delete = async id => {
    const res = await post('deleteAssetById', { id })
    dispatch({
      type: DELETE_ASSET,
      payload: res?.data?.data?.deleteAssetById?.asset,
    })
    return res?.data?.data?.deleteAssetById?.asset
  }

  const createAsset = async assetInput => {
    setLoading()
    const res = await post('createAsset', { ...assetInput })
    await dispatch({
      type: CREATE_ASSET,
      payload: res.data.data.createAsset.asset,
    })
    return res.data.data.createAsset.asset
  }

  const updateAssetById = async (id, patch) => {
    setLoading()
    const res = await post('updateAssetById', { id, patch })
    await dispatch({
      type: UPDATE_ASSET,
      payload: res.data?.data?.updateAssetById?.asset,
    })
    return res?.data?.data?.updateAssetById?.asset
  }

  const assetById = async id => {
    setLoading()
    const res = await post('assetById', { id })
    await dispatch({
      type: GET_ASSET,
      payload: res.data?.data?.assetById,
    })
    return res.data?.data?.assetById
  }

  const getAssetStatisticById = async id => {
    // setLoading()
    const res = await post('getAssetStatisticById', { id })
    return res.data?.data?.allAssets?.nodes?.[0]
  }

  const fetchAssetById = async id => {
    const res = await post('assetById', { id })
    return res.data?.data?.assetById
  }

  const validateErc1155Amount = async (id, balanceOf, itemContractId) => {
    const res = await api.post('/asset/validate_erc1155_amount', {
      id,
      balanceOf: parseFloat(balanceOf),
      itemContractId: parseFloat(itemContractId),
    })
    return res?.data?.balanceOf
  }

  const setAddressOwnerTemp = ownerAddress =>
    dispatch({
      type: SET_ADDRESS_OWNER,
      payload: ownerAddress,
    })

  const checkPinnedFile = async cid => {
    const res = await api.post('/asset/checkFilePinned', {
      cid: cid,
    })
    return res.data.pinned
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING })
  return (
    <AssetContext.Provider
      value={{
        assets: state.assets,
        assetsUtil: state.assetsUtil,
        asset: state.asset,
        loading: state.loading,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        hasTokenId: state.hasTokenId,
        topTags: state.topTags,
        randomAssets: state.randomAssets,
        categories: state.categories,
        banners: state.banners,
        bannersCollection: state.bannersCollection,
        allAssetsBlockchain: state.allAssetsBlockchain,
        addressOwnerTemp: state.addressOwnerTemp,
        getAssetStatisticById,
        uploadIpfsFile,
        uploadIpfsMetadata,
        checkUserHasTokenId,
        checkAssetItemId,
        getAllAssets,
        getAllAssetsUtil,
        getAllAssetsUtilGst,
        createAsset,
        assetById,
        updateAssetById,
        allOwnedAsset,
        allCreatedAsset,
        allSoldAsset,
        getCollection,
        checkInvoice,
        fetchAssetById,
        insertTags,
        getCache,
        uploadAsset,
        delete: _delete,
        validateErc1155Amount,
        allAssetNotInAnotherProject,
        checkAllAssetSelected,
        resetUtil,
        getAllNFTBlockchain,
        getNFTMetadata,
        setAddressOwnerTemp,
        checkPinnedFile,
      }}
    >
      {props.children}
    </AssetContext.Provider>
  )
}

export default AssetState
