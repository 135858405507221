import {
  ACTIVATE_BANK,
  CREATE_ACCOUNT_BANK,
  DELETE_ACCOUNT_BANK,
  GET_ACCOUNT_BANK,
  GET_ACTIVE_ACCOUNT_BANK,
  SET_LOADING,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case GET_ACCOUNT_BANK:
      return {
        ...state,
        accountsBank: action.payload,
        loading: false
      };
      
    case CREATE_ACCOUNT_BANK:
      return {
        ...state,
        accountBank: action.payload,
        loading: false
      };
      
    case DELETE_ACCOUNT_BANK:
      return {
        ...state,
        accountBank: action.payload,
        loading: false
      };
    case GET_ACTIVE_ACCOUNT_BANK:
      return {
        ...state,
        activeBank: action.payload,
       /*  totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false */
      };
    case ACTIVATE_BANK:
      return {
        ...state,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer