import axios from "axios";
import env from "../config/env";

const { API_URL } = env;

const getAuth = () => {
  const auth = JSON.parse(window.localStorage.getItem("auth"));
  return auth;
};

const apiUpload = axios.create();

apiUpload.interceptors.request.use(config => {
  config.baseURL = API_URL
  config.timeout = 1800000
  config.headers["Authorization"] = `Bearer ${getAuth()?.accessToken}`
  return config
})

export default apiUpload;