import {
    GET_ARTICLES, 
    SET_LOADING,
    GET_ARTICLE_CATEGORIES,
    GET_ARTICLE_BY_ID,
    GET_ARTICLE_CATEGORY_BY_SLUG,
    GET_SMART_ARTICLES,
    LOADING_SEARCH_ARTICLE,
} from '../types'


const reducer = (state, action) => {
    switch (action.type) {
      case GET_ARTICLES:
        return {
          ...state,
          articles: action.payload,
          loading: false
        };
      case GET_ARTICLE_CATEGORIES:
        return {
          ...state,
          categories: action.payload,
          loading: false
        };
        case GET_ARTICLE_BY_ID:
          return {
            ...state,
            selected_article: action.payload,
            loading: false
          };
        case GET_ARTICLE_CATEGORY_BY_SLUG:
          return {
            ...state,
            selected_category: action.payload,
            loading: false
          };
        case GET_SMART_ARTICLES:
          return {
            ...state,
            topArticle: action?.payload.articles?.map(({_source}) => _source),
            loadingSearch: false
          };
        case SET_LOADING:
        return {
            ...state,
            loading: true
        };
        case LOADING_SEARCH_ARTICLE:
        return {
            ...state,
            loadingSearch: true
        };
      
    default: return state
}
}

export default reducer