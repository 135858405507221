import {
  CREATE_ASSET_BID_DETAIL,
  DELETE_ASSET_BID_DETAIL,
  GET_ALL_PROFILE_IN,
  GET_ALL_PROFILE_OFFER_MADE,
  GET_ASSET_BID_DETAIL,
  IS_BIDDER_HAS_BID,
  SET_LOADING,
  UPDATE_ASSET_BID_DETAIL,
  GET_BID_DETAIL_BY_ASSET
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_ASSET_BID_DETAIL:
      return {
        ...state,
        assetBidDetail: action.payload,
        loading: false
      };
    case DELETE_ASSET_BID_DETAIL:
      return {
        ...state,
        loading: false
      };
    case UPDATE_ASSET_BID_DETAIL:
      return {
        ...state,
        assetBidDetail: action.payload,
        loading: false
      };
    case GET_ALL_PROFILE_OFFER_MADE:
      return {
        ...state,
        offerMade: action.payload,
        loading: false
      };
    case GET_ALL_PROFILE_IN:
      return {
        ...state,
        offerIn: action.payload,
        loading: false
      };
    case IS_BIDDER_HAS_BID:
      return {
        ...state,
        isOffer: action.payload,
        loading: false
      };
    case GET_ASSET_BID_DETAIL:
      return {
        ...state,
        loading: false
      }
    case GET_BID_DETAIL_BY_ASSET:
      return {
        ...state,
        bidDetailByAsset: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
