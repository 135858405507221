import { lazy } from "react";
const UpdateProject = lazy(() =>
  import("../pages/Project/update/UpdateProject")
);
// import ActivePurchase from '../pages/ActivePurchase/ActivePurchase';
// import ExpiredPurchase from '../pages/ExpiredPurchase/ExpiredPurchase';
// import SuccessPurchase from '../pages/SuccessPurchase/SuccessPurchase';

// import LandingPage from '../pages/LandingPage/LandingPage'
const ActivePurchase = lazy(() =>
  import("../pages/ActivePurchase/ActivePurchase")
);
const ExpiredPurchase = lazy(() =>
  import("../pages/ExpiredPurchase/ExpiredPurchase")
);
const SuccessPurchase = lazy(() =>
  import("../pages/SuccessPurchase/SuccessPurchase")
);
const VoucherManagement = lazy(() =>
  import("../pages/VoucherManagement/VoucherManagement")
);
const LandingPage = lazy(() => import("../pages/LandingPage/LandingPage"));
const AccountDetail = lazy(() =>
  import("../pages/Account/Detail/AccountDetail")
);
const Activity = lazy(() => import("../pages/Activity/Activity"));
const AssetDetail = lazy(() => import("../pages/Asset/Detail/AssetDetail"));
const AssetExclusivePartner = lazy(() =>
  import("../pages/Asset/ExclusivePartner/AssetExclusivePartner")
);
const BankAccountSetting = lazy(() =>
  import("../pages/BanklAccountSetting/BankAccountSetting")
);
const Collection = lazy(() => import("../pages/Collection/Collection"));
const CollectionDetail = lazy(() =>
  import("../pages/Collection/Detail/CollectionDetail")
);
const CreateAsset = lazy(() => import("../pages/CreateAsset"));
const CreateCollection = lazy(() =>
  import("../pages/CreateCollection/CreateCollection")
);
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const ExclusivePartner = lazy(() =>
  import("../pages/ExclusivePartner/ExclusivePartner")
);
const HistoryDisbursment = lazy(() =>
  import("../pages/HistoryDisbursment/HistoryDisbursment")
);
const SummaryDisburse = lazy(() =>
  import("../pages/SummaryDisburse/SummaryDisburse")
);
const HistoryTransaction = lazy(() =>
  import("../pages/HistoryTransaction/HistoryTransaction")
);
const MyCollection = lazy(() => import("../pages/MyCollection/MyCollection"));
const Notification = lazy(() => import("../pages/Notification/Notification"));
const Profile = lazy(() => import("../pages/Profile/Profile"));
const Setting = lazy(() => import("../pages/Setting/Setting"));
const SettingProfile = lazy(() =>
  import("../pages/SettingProfile/SettingProfile")
);
const UpdateCollection = lazy(() =>
  import("../pages/UpdateCollection/UpdateCollection")
);
const FAQ = lazy(() => import("../pages/FAQ/FAQ"));
const KebijakanPrivasi = lazy(() =>
  import("../pages/LayananInformasi/KebijakanPrivasi")
);
const SyaratDanKetentuan = lazy(() =>
  import("../pages/LayananInformasi/SyaratDanKetentuan")
);
const Article = lazy(() => import("../pages/Article/Article"));
const CategoryArticle = lazy(() => import("../pages/Article/CategoryArticle"));
const ArticleDetail = lazy(() => import("../pages/Article/ArticleDetail"));
const PaymentSuccess = lazy(() => import("../pages/StatePage/PaymentSuccess"));
const PaymentFailed = lazy(() => import("../pages/StatePage/PaymentFailed"));
const CreateProjectPage = lazy(() => import("../pages/Project/CreateProject"));
const DetailProject = lazy(() =>
  import("../pages/Project/Detail/DetailProject")
);
const ListProjectUtilities = lazy(() =>
  import("../pages/ListProjectUtilities")
);
const DetailProjectGuest = lazy(() =>
  import("../pages/Project/Detail/DetailProjectGuest")
);
const DetailBarter = lazy(() =>
  import("../pages/Barter/DetailBarter")
);
const CollectionStats = lazy(() => import('../pages/Activity/CollectionStats'))


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/detail-barter/:id/:assetId",
    name: "Detail Barter",
    component: DetailBarter /*  , layout: "" */,
  },
  {
    path: "/create-project",
    name: "Buat Projek",
    component: CreateProjectPage /*  , layout: "" */,
  },
  {
    path: "/detail-project/:id",
    name: "Detail Projek",
    component: DetailProject /*  , layout: "" */,
  },
  {
    path: "/update-project/:id",
    name: "Detail Projek",
    component: UpdateProject /*  , layout: "" */,
  },
  {
    path: "/assets",
    name: "Daftar Asset",
    component: Dashboard /*  , layout: "" */,
  },
  {
    path: "/assets-utilities/:id",
    name: "Daftar Aset Utilitas",
    component: DetailProjectGuest /*  , layout: "" */,
  },
  {
    path: "/asset-exclusive-partner",
    name: "Daftar Asset",
    component: AssetExclusivePartner /*  , layout: "" */,
  },
  {
    path: "/exclusive-partner",
    name: "Exclusive Partner",
    component: ExclusivePartner /*  , layout: "" */,
  },
  {
    path: "/home",
    name: "Landing Page",
    component: LandingPage /*  , layout: "" */,
  },
  {
    path: "/my-collection",
    name: "My Collection",
    component: MyCollection /*  , layout: "" */,
  },
  {
    path: "/collection",
    name: "Collection",
    component: Collection /*  , layout: "" */,
  },
  {
    path: "/collection/:id",
    name: "CollectionDetail",
    component: CollectionDetail /*  , layout: "" */,
  },
  {
    path: "/activity",
    name: "Activity",
    component: Activity /*  , layout: "" */,
  },
  {
    path: "/create-asset",
    name: "Create Asset",
    component: CreateAsset /*  , layout: "" */,
  },
  {
    path: "/create-collection",
    name: "Create Collection",
    component: CreateCollection /*  , layout: "" */,
  },
  {
    path: "/update-collection/:id",
    name: "Update Collection",
    component: UpdateCollection /*  , layout: "" */,
  },
  {
    path: "/asset/:id/",
    name: "Asset Detail",
    component: AssetDetail /*  , layout: "" */,
  },
  {
    path: "/nft-metadata/:contract/:token/:blockchain",
    name: "Asset Detail",
    component: AssetDetail /*  , layout: "" */,
  },
  {
    path: "/profile-page/:slug",
    name: "profile",
    component: Profile /*  , layout: "" */,
  },
  {
    path: "/account/:id",
    name: "Account Info",
    component: AccountDetail /*  , layout: "" */,
  },
  { path: "/faq/", name: "FAQ", component: FAQ /*  , layout: "" */ },
  {
    path: "/kebijakan-privasi/",
    name: "Kebijakan Privasi",
    component: KebijakanPrivasi /*  , layout: "" */,
  },
  {
    path: "/syarat-ketentuan/",
    name: "Syarat dan Ketentuan",
    component: SyaratDanKetentuan /*  , layout: "" */,
  },
  { path: "/article", name: "Article", component: Article /*  , layout: "" */ },
  {
    path: "/article/category/:slug",
    name: "Category",
    component: CategoryArticle /*  , layout: "" */,
  },
  {
    path: "/article/:slug/:id",
    name: "Article Detail",
    component: ArticleDetail /*  , layout: "" */,
  },
  {
    path: "/payment-success",
    name: "Payment Success",
    component: PaymentSuccess /*  , layout: "" */,
  },
  {
    path: "/payment-failed",
    name: "Payment Failed",
    component: PaymentFailed /*  , layout: "" */,
  },
  {
    path: "/list-project-utilites",
    name: "Project Utilites",
    component: ListProjectUtilities /*  , layout: "" */,
  },
  {
    path: "/my-project",
    name: "Project Utilites",
    component: ListProjectUtilities /*  , layout: "" */,
  },
  { path: '/collection-stats', name: 'Collection Stats', component: CollectionStats/*  , layout: "" */ },
  {
    path: "setting",
    name: "Setting",
    component: Setting,
    children: [
      { path: "profile", name: "setting profile", component: SettingProfile },
      { path: "notification", name: "Notifikasi", component: Notification },
      {
        path: "bank-account",
        name: "Rekening Bank",
        component: BankAccountSetting,
      },
      {
        path: "active-purchase",
        name: "Pembelian Aktif",
        component: ActivePurchase,
      },
      {
        path: "success-purchase",
        name: "Pembelian Sukses",
        component: SuccessPurchase,
      },
      {
        path: "expired-purchase",
        name: "Pembelian Kadaluwarsa",
        component: ExpiredPurchase,
      },
      {
        path: "transaction-history",
        name: "Sejarah Transaksi",
        component: HistoryTransaction,
      },
      {
        path: "disbursment-history",
        name: "Sejarah Disbursment",
        component: HistoryDisbursment,
      },
      {
        path: "voucher-management",
        name: "Manajemen Voucher",
        component: VoucherManagement,
      },
      // { path: 'txn-activity-all', name: "Aktivitas Transaksi Aktif", component: AllTxnActivity },
      // { path: 'txn-activity-pending', name: "Aktivitas Transaksi Semua", component: PendingTxnActivity },
    ],
  },
  {
    path: "history-txn",
    name: "Sejarah Transaksi",
    component: Setting,
    children: [
      {
        path: "pending",
        name: "Transaksi Tertunda",
        component: ActivePurchase,
      },
      { path: "success", name: "Semua Transaksi", component: SuccessPurchase },
      { path: "expire", name: "Transaksi Expire", component: ExpiredPurchase },
      {
        path: "summary-disburse",
        name: "Summary Disbursement",
        component: SummaryDisburse,
      },
      {
        path: "list-disburse",
        name: "Sejarah Penjualan",
        component: HistoryDisbursment,
      },
    ],
  },
];

export default routes;
