import {
  ADD_FILTER_CATEGORIES_ID,
  ADD_FILTER_COLLECTIONS_ID,
  REMOVE_FILTER_CATEGORIES_ID,
  REMOVE_FILTER_COLLECTIONS_ID,
  ADD_FILTER_STATUS_LISTING,
  REMOVE_FILTER_STATUS_LISTING,
  SET_FILTER_PRICE,
  SET_FILTER_SEARCH_ASSET,
  SET_FILTER_SORTED_BY,
  SET_FILTER_ACTIVITY_ASSET,
  SET_FILTER_SEARCH_COLLECTION,
  SET_FILTER_VERIFIED,
  ADD_FILTER_VERIFIED,
  REMOVE_FILTER_VERIFIED,
  GET_TOP_COLLECTIONS,
  GET_TOP_ASSETS,
  GET_TOP_ACCOUNTS,
  GET_SMART_SEARCH,
  SET_FILTER_SEARCH_TAG,
  SET_CATEGORY,
  SET_TYPE_TXN,
  RESET_FILTER,
  SET_TYPE_UTIL,
  REMOVE_TYPE_UTIL,
  SET_FILTER_SEARCH_PROJECT,
  SET_UTIL_PUBLISH,
  ADD_FILTER_PROJECT_ID,
  REMOVE_FILTER_PROJECT_ID,
  RESET_FILTER_PROJECT_ID,
  SET_FILTER_SEARCH_ASSET_UTILITY,
  ADD_FILTER_STATUS_SELL,
  REMOVE_FILTER_STATUS_SELL,
  ADD_ACTIVE_FILTER,
  REMOVE_ACTIVE_FILTER,
  SET_IS_OWNER_ALLOWED_FIAT,
  SET_LOADING,
  ADD_BLOCKCHAIN_FILTER,
  REMOVE_BLOCKCHAIN_FILTER,
} from '../types'

const reducer = (state, action) => {
  switch (action.type) {
    case RESET_FILTER:
      return {
        ...state,
        categoryIds: [],
        collectionIds: [],
        verifiedIds: [],
        statusType: [],
        priceRange: [0, 99999999999],
        tags: [],
        searchAsset: undefined,
        searchTag: '',
        sortedBy: null,
        typeUtil: [],
        searchProject: undefined,
        isDraft: false,
        isOwnerAllowedFiatTransaction: false,
        activeFilter: [],
        blockchainType: [],
      }
    case ADD_FILTER_VERIFIED:
      return {
        ...state,
        verifiedIds: state.verifiedIds.concat(action.payload),
        loading: false,
      }
    case REMOVE_FILTER_VERIFIED:
      return {
        ...state,
        verifiedIds: state.verifiedIds.filter(v => v !== action.payload),
        loading: false,
      }
    case ADD_FILTER_PROJECT_ID:
      return {
        ...state,
        projectIds: state.projectIds.concat(action.payload),
        loading: false,
      }
    case REMOVE_FILTER_PROJECT_ID:
      return {
        ...state,
        projectIds: state.projectIds.filter(v => v !== action.payload),
        loading: false,
      }
    case RESET_FILTER_PROJECT_ID:
      return {
        ...state,
        projectIds: [],
        loading: false,
      }
    case ADD_FILTER_CATEGORIES_ID:
      return {
        ...state,
        categoryIds: state.categoryIds.concat(action.payload),
        loading: false,
      }
    case REMOVE_FILTER_CATEGORIES_ID:
      return {
        ...state,
        categoryIds: state.categoryIds.filter(v => v !== action.payload),
        loading: false,
      }
    case ADD_FILTER_COLLECTIONS_ID:
      return {
        ...state,
        collectionIds: state.collectionIds.concat(action.payload),
        loading: false,
      }
    case REMOVE_FILTER_COLLECTIONS_ID:
      return {
        ...state,
        collectionIds: state.collectionIds.filter(v => v !== action.payload),
        loading: false,
      }
    case ADD_FILTER_STATUS_SELL:
      return {
        ...state,
        statusSell: state.statusSell.concat(action.payload),
        loading: false,
      }
    case REMOVE_FILTER_STATUS_SELL:
      return {
        ...state,
        statusSell: state.statusSell.filter(v => v !== action.payload),
        loading: false,
      }
    case ADD_FILTER_STATUS_LISTING:
      return {
        ...state,
        statusType: state.statusType.concat(action.payload),
        loading: false,
      }
    case REMOVE_FILTER_STATUS_LISTING:
      return {
        ...state,
        statusType: state.statusType.filter(v => v !== action.payload),
        loading: false,
      }
    case SET_FILTER_PRICE:
      return {
        ...state,
        loading: false,
        priceRange: action.payload,
      }
    case SET_FILTER_SEARCH_ASSET:
      return {
        ...state,
        loading: false,
        searchAsset: action.payload,
        isTag: action?.isTag,
      }
    case SET_FILTER_SEARCH_ASSET_UTILITY:
      return {
        ...state,
        loading: false,
        searchAssetUtility: action.payload,
      }
    case SET_FILTER_SEARCH_TAG:
      return {
        ...state,
        loading: false,
        searchTag: action.payload,
      }
    case GET_SMART_SEARCH:
      return {
        ...state,
        loading: false,
        topCollections: action?.payload?.collections?.map(
          ({ _source }) => _source,
        ),
        topAssets: action?.payload?.assets?.map(({ _source }) => _source),
        topAccounts: action?.payload?.accounts?.map(({ _source }) => _source),
        topTags: action?.payload?.top_tags,
        tags: action?.payload?.tags?.map(({ _source }) => _source),
      }
    case SET_FILTER_SEARCH_COLLECTION:
      return {
        ...state,
        loading: false,
        searchCollection: action.payload,
      }
    case SET_FILTER_VERIFIED:
      return {
        ...state,
        loading: false,
        isVerified: action.payload,
      }
    case SET_FILTER_SORTED_BY:
      return {
        ...state,
        loading: false,
        sortedBy: action.payload,
      }

    case SET_FILTER_ACTIVITY_ASSET:
      return {
        ...state,
        loading: false,
        activityType: action.payload,
      }
    case GET_TOP_COLLECTIONS:
      return {
        ...state,
        loading: false,
        topCollections: action.payload,
      }
    case GET_TOP_ASSETS:
      return {
        ...state,
        loading: false,
        topAssets: action.payload,
      }
    case GET_TOP_ACCOUNTS:
      return {
        ...state,
        loading: false,
        topAccounts: action.payload,
      }
    case SET_CATEGORY:
      return {
        ...state,
        categoryIds: action.payload ? [action.payload] : [],
      }
    case SET_TYPE_TXN:
      return {
        ...state,
        typeTxn: action.payload,
      }
    case SET_TYPE_UTIL:
      return {
        ...state,
        typeUtil: state.typeUtil.concat(action.payload),
        loading: false,
      }
    case SET_UTIL_PUBLISH:
      return {
        ...state,
        isDraft: action.payload,
        loading: false,
      }
    case REMOVE_TYPE_UTIL:
      return {
        ...state,
        typeUtil: state.typeUtil.filter(v => v !== action.payload),
        loading: false,
      }
    case SET_FILTER_SEARCH_PROJECT:
      return {
        ...state,
        searchProject: action.payload,
        loading: false,
      }
    case ADD_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: state.activeFilter.concat(action.payload),
        loading: false,
      }
    case REMOVE_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: state.activeFilter.filter(v => v !== action.payload),
        loading: false,
      }
    case ADD_BLOCKCHAIN_FILTER:
      return {
        ...state,
        blockchainType: state.blockchainType.concat(action.payload),
        loading: false,
      }
    case REMOVE_BLOCKCHAIN_FILTER:
      return {
        ...state,
        blockchainType: state.blockchainType.filter(v => v !== action.payload),
        loading: false,
      }
    case SET_IS_OWNER_ALLOWED_FIAT:
      return {
        ...state,
        isOwnerAllowedFiatTransaction: action.payload,
        loading: false,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}

export default reducer
