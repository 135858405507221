import React from "react";

const MagnifierLogoImg = (props) =>
    <div {...props}>
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0774 17.0044C15.9534 17.0049 15.8305 16.9809 15.7158 16.9338C15.6011 16.8867 15.4968 16.8173 15.4091 16.7297L11.1857 12.4893C9.98336 13.3037 8.5635 13.7367 7.11137 13.7319C5.25852 13.7616 3.46947 13.0555 2.13641 11.7683C0.803357 10.4811 0.0350777 8.71785 0 6.86509C0.0354183 5.01268 0.80389 3.24989 2.13695 1.96318C3.47 0.676459 5.25886 -0.0291897 7.11137 0.000925821C8.96387 -0.0291897 10.7527 0.676459 12.0858 1.96318C13.4188 3.24989 14.1873 5.01268 14.2227 6.86509C14.2181 8.48941 13.6263 10.0573 12.5564 11.2794L16.7419 15.4885C16.8258 15.5719 16.892 15.6716 16.9363 15.7813C16.9807 15.8911 17.0023 16.0087 16.9998 16.1271C16.9974 16.2455 16.9709 16.3621 16.9221 16.47C16.8733 16.5778 16.803 16.6746 16.7157 16.7546C16.5419 16.9151 16.314 17.0042 16.0774 17.0044ZM7.11137 1.79152C5.74203 1.76911 4.41968 2.29062 3.43428 3.2417C2.44887 4.19279 1.88082 5.49582 1.85468 6.86509C1.88082 8.23436 2.44887 9.5374 3.43428 10.4885C4.41968 11.4396 5.74203 11.9611 7.11137 11.9387C8.48048 11.9607 9.80249 11.4391 10.7876 10.488C11.7727 9.53696 12.3406 8.23413 12.3667 6.86509C12.3406 5.49605 11.7727 4.19322 10.7876 3.24217C9.80249 2.29113 8.48048 1.76946 7.11137 1.79152Z" fill="#747474" />
        </svg>
    </div>


export default MagnifierLogoImg