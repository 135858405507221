import {
  CREATE_ASSET_BID,
  DELETE_ASSET_BID,
  GET_ASSET_BID_BY_ASSET_ID,
  GET_ASSET_BID_BY_ID,
  SET_LOADING,
  UPDATE_ASSET_BID,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_ASSET_BID:
      return {
        ...state,
        assetBid: action.payload,
        loading: false
      };
    case UPDATE_ASSET_BID:
      return {
        ...state,
        assetBid: action.payload,
        loading: false
      };
    case DELETE_ASSET_BID:
      return {
        ...state,
        loading: false
      };
    case GET_ASSET_BID_BY_ASSET_ID:
      return {
        ...state,
        assetBids: action.payload,
        loading: false
      };
    case GET_ASSET_BID_BY_ID:
      return {
        ...state,
        assetBid: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
