import React from 'react';
import classNames from 'classnames';

const Button = ({
  children,
  classstyle,
  color = 'bg-purple-500',
  ...props
}) => {
  return (
    <button
      {...props}
      className={`
        ${classNames({
          'px-6': !classstyle?.includes('px'),
          'py-3': !classstyle?.includes('py'),
          rounded: !classstyle?.includes('rounded-'),
        })}
        ${color}
        ${classstyle}`}>
      {children}
    </button>
  );
};

export default Button;
