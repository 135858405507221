import React, { useReducer } from 'react';
import DisbursmentContext from './disbursmentContext';
import DisbursmentReducer from './disbursmentReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { SET_LOADING, ALL_DISBURSMENT, CREATE_DISBURSMENT, REQUEST_DISBURSMENT, REQUEST_BATCH_DISBURSMENT } from '../types';
import logger from 'use-reducer-logger';
import api from '../../utils/api';

const DisbursmentState = props => {
  const initialState = {
    disbursments: [],
    disbursment: {},
    totalCount: 0,
    pageInfo: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(DisbursmentReducer), initialState);

  // All Collection
  const getAll = async (inputFilter) => {
    setLoading();

    try {

      const res = await post("allAccountsDisbursement", { ...inputFilter });
      dispatch({
        type: ALL_DISBURSMENT,
        payload: res.data.data.allAccountsDisbursements.nodes
      });
      return res.data.data.allAccountsDisbursements.nodes
    } catch (error) {
      console.log('ERR: ', error);
    }
  };


  const create = async (body) => {
    setLoading()
    const res = await api.post("xendit/create_disbursement", { ...body }, { withCredentials: true })
    dispatch({
      type: CREATE_DISBURSMENT,
      payload: res?.data
    })
    return res?.data
  }

  const request = async (body) => {
    setLoading()
    const res = await api.post("xendit/request_disbursement", { ...body }, { withCredentials: true })
    dispatch({
      type: REQUEST_DISBURSMENT,
      payload: res?.data
    })
    return res?.data
  }

  const requestBatch = async (body) => {
    setLoading()
    const res = await api.post("xendit/request_batch_disbursement", { ...body }, { withCredentials: true })
    dispatch({
      type: REQUEST_BATCH_DISBURSMENT,
      payload: res?.data
    })
    return res?.data

  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <DisbursmentContext.Provider
      value={{
        disbursments: state.disbursments,
        disbursment: state.disbursment,
        loading: state.loading,
        totalCount: state.totalCount,
        pageInfo: state.pageInfo,
        getAll,
        create,
        requestBatch,
        request
      }}
    >
      {props.children}
    </DisbursmentContext.Provider>
  );
};

export default DisbursmentState;
