import React from "react";

const CloseIcon = ({viewBox, size, onClick, divClass, color}) => {
    return (
        <div onClick={onClick} className={divClass}>
           <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
            <path d="M13.7806 12.1101L18.872 7.02984C19.0949 6.80685 19.2202 6.50441 19.2202 6.18905C19.2202 5.8737 19.0949 5.57126 18.872 5.34827C18.649 5.12528 18.3466 5 18.0313 5C17.716 5 17.4136 5.12528 17.1906 5.34827L12.1111 10.4404L7.03158 5.34827C6.80862 5.12528 6.50622 5 6.19091 5C5.87559 5 5.5732 5.12528 5.35024 5.34827C5.12728 5.57126 5.00202 5.8737 5.00202 6.18905C5.00202 6.50441 5.12728 6.80685 5.35024 7.02984L10.4416 12.1101L5.35024 17.1904C5.23926 17.3004 5.15117 17.4314 5.09106 17.5757C5.03095 17.72 5 17.8748 5 18.0311C5 18.1875 5.03095 18.3423 5.09106 18.4866C5.15117 18.6309 5.23926 18.7618 5.35024 18.8719C5.46031 18.9829 5.59127 19.071 5.73555 19.1312C5.87984 19.1913 6.0346 19.2222 6.19091 19.2222C6.34721 19.2222 6.50197 19.1913 6.64626 19.1312C6.79055 19.071 6.9215 18.9829 7.03158 18.8719L12.1111 13.7798L17.1906 18.8719C17.3007 18.9829 17.4317 19.071 17.576 19.1312C17.7202 19.1913 17.875 19.2222 18.0313 19.2222C18.1876 19.2222 18.3424 19.1913 18.4867 19.1312C18.631 19.071 18.7619 18.9829 18.872 18.8719C18.983 18.7618 19.071 18.6309 19.1312 18.4866C19.1913 18.3423 19.2222 18.1875 19.2222 18.0311C19.2222 17.8748 19.1913 17.72 19.1312 17.5757C19.071 17.4314 18.983 17.3004 18.872 17.1904L13.7806 12.1101Z" fill={color}/>
            </svg>
        </div>
    )
}

CloseIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: '#3C3C3B'
};

export default CloseIcon