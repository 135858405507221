import ButtonText from '../../Text/ButtonText'
import { SMALL_THUMBNAIL_IMAGE_TYPE } from '../../../utils/constants/renderImgTypes'
// import formatDistance from 'date-fns/formatDistance';
import MailOpen from '../../../assets/icon/mail_open_icon'
import MailClosed from '../../../assets/icon/mail_closed_icon'
import {
  NOTIFICATION_BARTER,
  NOTIFICATION_BARTER_ACCEPTED,
  NOTIFICATION_BID_AUCTION,
  NOTIFICATION_BID_AUCTION_EXPIRED,
  NOTIFICATION_BUY,
  NOTIFICATION_MAKE_OFFER,
  NOTIFICATION_TRANSFER,
} from '../../../utils/constants/notificationTypes'
import { useState } from 'react'
import { UserImage } from '../../../assets'
import { intervalToDuration } from 'date-fns'
const CardNotification = ({
  accountByBuyerId,
  isRead,
  type,
  createdAt,
  handleCardClick,
  id,
  handleClickMark,
  ref,
  isLast,
  assetByAssetId,
}) => {
  const timeDistance = intervalToDuration({
    start: new Date(createdAt),
    end: new Date(),
  })
  const [isHover, setIsHover] = useState(false)
  let name
  let dateTitle = 'Tidak ada keterangan'
  let distance
  let typeTitle
  let url
  //Get Buyer Addr or Name
  if (accountByBuyerId?.userName) {
    name = accountByBuyerId?.userName
  } else {
    name =
      accountByBuyerId?.address.substr(0, 5) +
      '...' +
      accountByBuyerId?.address.substr(accountByBuyerId?.address?.length - 4)
  }

  //Set title notif and url
  switch (type) {
    case NOTIFICATION_BUY:
      typeTitle = `${name} membeli aset Anda`
      url = '/profile-page/asset/#1'
      break
    case NOTIFICATION_MAKE_OFFER:
      typeTitle = `${name} menawar aset Anda`
      url = '/profile-page/offer/#1'
      break
    case NOTIFICATION_BID_AUCTION:
      typeTitle = `${name} menawar lelang aset Anda`
      url = '/profile-page/bid/#0'
      break
    case NOTIFICATION_BID_AUCTION_EXPIRED:
      typeTitle = `Lelang aset kadaluarsa`
      url = '/profile-page/bid/#1'
      break
    case NOTIFICATION_BARTER:
      typeTitle = `${name} mengajukan penawaran barter`
      url = '/profile-page/barter/#0'
      break
    case NOTIFICATION_BARTER_ACCEPTED:
      typeTitle = `Pengajuan barter kamu sudah diterima!`
      url = `/asset/${assetByAssetId?.id}`
      break
    case NOTIFICATION_TRANSFER:
      typeTitle = `${name} memberikan kamu aset!`
      url = `/asset/${assetByAssetId?.id}`
      break
    default:
      break
  }

  //Set caption
  if (timeDistance.years !== 0) {
    dateTitle = 'tahun yang lalu'
    distance = timeDistance.years
  } else if (timeDistance.years === 0 && timeDistance.months !== 0) {
    dateTitle = 'bulan yang lalu'
    distance = timeDistance.months
  } else if (
    timeDistance.years === 0 &&
    timeDistance.months === 0 &&
    timeDistance.days !== 0
  ) {
    dateTitle = 'hari yang lalu'
    distance = timeDistance.days
  } else if (
    timeDistance.years === 0 &&
    timeDistance.months === 0 &&
    timeDistance.days === 0 &&
    timeDistance.hours !== 0
  ) {
    dateTitle = 'jam yang lalu'
    distance = timeDistance.hours
  } else if (
    timeDistance.years === 0 &&
    timeDistance.months === 0 &&
    timeDistance.days === 0 &&
    timeDistance.hours === 0 &&
    timeDistance.minutes !== 0
  ) {
    dateTitle = 'menit yang lalu'
    distance = timeDistance.minutes
  } else if (
    timeDistance.years === 0 &&
    timeDistance.months === 0 &&
    timeDistance.days === 0 &&
    timeDistance.hours === 0 &&
    timeDistance.minutes === 0 &&
    timeDistance.seconds !== 0
  ) {
    dateTitle = 'detik yang lalu'
    distance = timeDistance.seconds
  }

  return (
    <div
      ref={ref}
      onClick={() => handleCardClick(id, url, isRead)}
      className={`w-full ${
        isRead
          ? isHover
            ? 'bg-gray-50'
            : 'bg-white'
          : isHover
          ? 'bg-green_theme_50'
          : 'bg-green_theme_10'
      } px-4 ${
        !isLast && 'border-b'
      } border-gray-200 py-4 flex items-center justify-between group cursor-pointer`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="flex items-center">
        <div className="rounded-full overflow-hidden bg-gray-400">
          <img
            src={
              assetByAssetId?.thumbnailUrl
                ? assetByAssetId?.thumbnailUrl + SMALL_THUMBNAIL_IMAGE_TYPE
                : UserImage
            }
            alt=""
            className="h-10 w-10 rounded-full overflow-hidden"
          />
        </div>
        <div className="flex flex-col ml-3">
          <p className="font-poppins font-semibold text-sm text-teks mb-1">
            {typeTitle}
          </p>
          <div className="flex">
            <p className="font-poppins font-regular text-xs text-gray-700">
              {distance} {dateTitle}
            </p>
            {isHover && !isRead && (
              <ButtonText
                classstyle="font-poppins font-medium text-xs  cursor-pointer"
                onClick={e => handleClickMark(id, e)}
                tx="Tandai Sudah dibaca"
                color="text-gray-700 ml-2 group-hover:text-hijau_hutan"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end gap-x-3">
        {isRead ? (
          <MailOpen />
        ) : (
          <MailClosed onClick={e => handleClickMark(id, e)} />
        )}
      </div>
    </div>
  )
}

export default CardNotification
