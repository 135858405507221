// import { navSidebar } from "../../router/_nav_sidebar"

import { useRef } from 'react'
import EmptyStateSection from '../../Panel/EmptyState'
import ButtonText from '../../Text/ButtonText'
import CardNotification from './CardNotification'

const NotificationMobile = ({
  toggleMenu,
  dataNotif,
  handleCardClick,
  setShow,
  handleClickMark,
  setGetAgain,
  handleClickReadAll,
  unRead,
}) => {
  const length = dataNotif?.length
  const isEmpty = length === 0
  const innerRef = useRef()
  const handleScroll = () => {
    if (innerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef?.current
      if (Math.ceil(scrollTop) + Math.ceil(clientHeight) >= scrollHeight) {
        setGetAgain(true)
        // console.log('WADU')
      }
    }
  }
  return (
    <div
      onScroll={handleScroll}
      className={`transform ease-in-out duration-500 md:hidden flex flex-col bg-white h-full w-full fixed left-0 z-30 overflow-scroll  ${
        toggleMenu ? 'translate-x-0 ' : '-translate-x-full'
      }`}
    >
      <div className="flex justify-between items-center mb-2">
        <p className="font-poppins font-semibold text-lg pl-4 mt-4">
          Notifikasi
        </p>
        {unRead && (
          <ButtonText
            tx="Tandai semua sudah dibaca"
            classstyle="font-poppins font-regular text-xs cursor-pointer"
            color="text-hijau_hutan"
            onClick={handleClickReadAll}
          />
        )}
      </div>
      {!isEmpty ? (
        dataNotif?.map(
          (
            {
              accountByAccountId,
              accountByBuyerId,
              assetByAssetId,
              isRead,
              type,
              createdAt,
              id,
            },
            idx,
          ) => (
            <CardNotification
              // ref={ref}
              key={idx}
              id={id}
              accountByAccountId={accountByAccountId}
              accountByBuyerId={accountByBuyerId}
              isRead={isRead}
              type={type}
              createdAt={createdAt}
              handleCardClick={handleCardClick}
              handleClickMark={handleClickMark}
              isLast={idx === length - 1}
              assetByAssetId={assetByAssetId}
            />
          ),
        )
      ) : (
        <EmptyStateSection
          text={'Belum ada notifikasi untuk kamu'}
          className="w-full"
        />
      )}
    </div>
  )
}

export default NotificationMobile
