import {
  ALL_ASSET,
  ALL_ASSET_CACHE,
  ALL_ASSET_CREATED,
  ALL_ASSET_NOT_IN_ANOTHER_PROJECT,
  ALL_ASSET_OWNED,
  ALL_ASSET_SOLD,
  // ALL_ASSET_UTIL,
  ALL_ASSET_UTIL_GST,
  ALL_NFT_ASSET_BLOCKCHAIN,
  CHECK_ASSET_ITEM_ID_IS_EXIST,
  CHECK_INVOICE,
  CHECK_USER_HAS_ASSET,
  CREATE_ASSET,
  GET_ASSET,
  GET_ASSET_BY_COLLECTION,
  RESET_ASSET_UTIL,
  SET_ADDRESS_OWNER,
  SET_LOADING,
  SET_NFT_METADATA,
  // SET_SELECTED_OWNED,
  UPDATE_ASSET,
  UPLOAD_IPFS_FILE,
  UPLOAD_IPFS_METADATA,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case ALL_ASSET:
      return {
        ...state,
        assets: action.payload,
        totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false
      };
    case ALL_NFT_ASSET_BLOCKCHAIN:
      return {
        ...state,
        allAssetsBlockchain: action.payload,
        totalCount: action.totalCount,
        loading: false
      };
    case RESET_ASSET_UTIL:
      return {
        ...state,
        assetsUtil: [],
        totalCount: 0,
        pageInfo: {},
        loading: false
      };
    case ALL_ASSET_NOT_IN_ANOTHER_PROJECT:
      return {

        ...state,
        assets: action.payload,
        totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false
      }
    case ALL_ASSET_UTIL_GST:
      return {
        ...state,
        assetsUtil: action.payload,
        totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false
      };
    case CHECK_ASSET_ITEM_ID_IS_EXIST:
      return {
        ...state,
        loading: false
      }
    case CHECK_INVOICE:
      return {
        ...state,
        loading: false
      }
    case ALL_ASSET_CACHE:
      return {
        ...state,
        topTags: action?.payload?.topTags,
        randomAssets: action?.payload?.randomAssets,
        categories: action?.payload?.categories,
        banners: action?.payload?.banners?.daftar_asset,
        bannersCollection: action?.payload?.banners?.daftar_koleksi,
        loading: false,
      }
    case CHECK_USER_HAS_ASSET:
      return {
        ...state,
        hasTokenId: action.payload?.nodes?.length === 0 ? false : true,
      }
    case GET_ASSET_BY_COLLECTION:
      return {
        ...state,
        assets: action.payload,
        totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false
      };
    case ALL_ASSET_CREATED:
      return {
        ...state,
        assets: action.payload,
        loading: false
      };
    case ALL_ASSET_OWNED:
      return {
        ...state,
        assets: action.payload,
        totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false
      };
    case ALL_ASSET_SOLD:
      return {
        ...state,
        assets: action.payload,
        loading: false
      };
    case GET_ASSET:
      return {
        ...state,
        asset: action.payload,
        loading: false
      };
    case UPDATE_ASSET:
      return {
        ...state,
        asset: action.payload,
        loading: false
      };
    case CREATE_ASSET:
      return {
        ...state,
        asset: action.payload,
        loading: false
      };
    case SET_NFT_METADATA:
      return {
        ...state,
        assetMetaData: action.payload,
        loading: false
      };
    case UPLOAD_IPFS_FILE:
      return {
        ...state,
        loading: false
      }
    case UPLOAD_IPFS_METADATA:
      return{
        ...state,
        loading: false
      }
    case SET_ADDRESS_OWNER:
      return {
        ...state,
        addressOwnerTemp: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
