const DEV = process.env.REACT_APP_DEV

const GQL_HOST = process.env.REACT_APP_GQL_HOST;
const REST_HOST = process.env.REACT_APP_REST_HOST;

const DEV_GQL_HOST = process.env.REACT_APP_DEV_GQL_HOST;
const DEV_GQL_PORT = process.env.REACT_APP_DEV_GQL_PORT;

const DEV_REST_HOST = process.env.REACT_APP_DEV_REST_HOST;
const DEV_REST_PORT = process.env.REACT_APP_DEV_REST_PORT;

const HOST = process.env.REACT_APP_GQL_HOST;
const PORT = process.env.REACT_APP_GQL_PORT;
const URL_MATIC_EXPLORER = process.env.REACT_APP_URL_MATIC_EXPLORER;
const ALLOW_FIAT_PAYMENT = process.env.REACT_APP_ALLOW_FIAT_PAYMENT
const IS_POLYGON_ALLOWED = process.env.REACT_APP_ALLOW_POLYGON === "true" ? true : false
const IS_ETHEREUM_ALLOWED = process.env.REACT_APP_ALLOW_ETHEREUM === "true" ? true : false
const IS_BINANCE_ALLOWED = process.env.REACT_APP_ALLOW_BINANCE === "true" ? true : false
const IS_ONLY_POLYGON = !IS_ETHEREUM_ALLOWED && !IS_BINANCE_ALLOWED
const IS_PROD = process.env.REACT_APP_IS_PROD === "true" ? true : false

const POLYGON_NFT_MARKETPLACE_ADDRESS = process.env?.REACT_APP_POLYGON_NFT_MARKETPLACE_ADDRESS ?? "0x165CC0EFed78Eb9D53aB8BfFd006dC751126abB4"
const POLYGON_NFT_AUCTION_ADDRESS = process.env?.REACT_APP_POLYGON_NFT_AUCTION_ADDRESS ?? "0xeae6A909b107f1F4127B572f59f5213D5E533672"
const POLYGON_NFT_ADDRESS = process.env?.REACT_APP_POLYGON_NFT_ADDRESS ?? "0xbb5A8EDa5793c2cD4509de0099019361319088cC"
const POLYGON_NFT_MULTI_ADDRESS = process.env?.REACT_APP_POLYGON_NFT_MULTI_ADDRESS ?? "0xB079B70eD1686Fc52BA9739A99CE4d5D8a135D64"
const POLYGON_PAYMASTER_ADDRESS = process.env?.REACT_APP_POLYGON_PAYMASTER_ADDRESS ?? "0x5C31Cb2Cf81da3d53B083178D126fBD2f76ea1c0"
const POLYGON_BANK_ADDRESS = process.env?.REACT_APP_POLYGON_BANK_ADDRESS ?? "0x96AD758938322b21ABBab5aA4069992C3d01A350"
const POLYGON_BARTER_ADDRESS = process.env.REACT_APP_POLYGON_BARTER_ADDRESS ?? "0xDe9A8316f6211F2A05bf996908583ce9cC37b8da"

const BINANCE_NFT_MARKETPLACE_ADDRESS = process.env?.REACT_APP_BINANCE_NFT_MARKETPLACE_ADDRESS ?? "0xD208Ad0EC42AB1D2550BC12356dFE0932f751717"
const BINANCE_NFT_AUCTION_ADDRESS = process.env?.REACT_APP_BINANCE_NFT_AUCTION_ADDRESS ?? "0xDDE520eEEB588ed9B44FB925B055DeEBcff2102e"
const BINANCE_NFT_ADDRESS = process.env?.REACT_APP_BINANCE_NFT_ADDRESS ?? "0xaa63Bc5da23056BbD6866cF34051E83f47a656C2"
const BINANCE_NFT_MULTI_ADDRESS = process.env?.REACT_APP_BINANCE_NFT_MULTI_ADDRESS ?? "0xe2865BbE4245F81eE7233EB8D670447e3126621c"
const BINANCE_PAYMASTER_ADDRESS = process.env?.REACT_APP_BINANCE_PAYMASTER_ADDRESS ?? "0xd121267Ac68139cF36e58B28bC926C75Ac8728Bf"
const BINANCE_BANK_ADDRESS = process.env?.REACT_APP_BINANCE_BANK_ADDRESS ?? "0x5F52A3fe1B8cC5A09deb8D3939C3Bdc07385D4c5"
const BINANCE_BARTER_ADDRESS = process.env.REACT_APP_BINANCE_BARTER_ADDRESS ?? "0x815e0eeA313EC9f84f28aC0a1ea0A968370FE300"

const ETHEREUM_NFT_MARKETPLACE_ADDRESS = process.env?.REACT_APP_ETHEREUM_NFT_MARKETPLACE_ADDRESS ?? "0x37ADB636e38cb243622919508EB8e3DB209BB59a"
const ETHEREUM_NFT_AUCTION_ADDRESS = process.env?.REACT_APP_ETHEREUM_NFT_AUCTION_ADDRESS ?? "0x5aA8Cb3716f484268961d710BbBfA54872DeA7A9"
const ETHEREUM_NFT_ADDRESS = process.env?.REACT_APP_ETHEREUM_NFT_ADDRESS ?? "0xbA461949850A81aA0f5B2f10Cf202087fC1a2722"
const ETHEREUM_NFT_MULTI_ADDRESS = process.env?.REACT_APP_ETHEREUM_NFT_MULTI_ADDRESS ?? "0x4f5146cB21EA93a6cF34BF936A24f1eb69e73F6C"
const ETHEREUM_PAYMASTER_ADDRESS = process.env?.REACT_APP_ETHEREUM_PAYMASTER_ADDRESS ?? "0x6b9B360237a734f156Fd2F3E08b0Fed501108FDD"
const ETHEREUM_BANK_ADDRESS = process.env?.REACT_APP_ETHEREUM_BANK_ADDRESS ?? "0xb1a2682AF95491943076a141A96084246B73A1aC"
const ETHEREUM_BARTER_ADDRESS = process.env.REACT_APP_ETHEREUM_BARTER_ADDRESS ?? "0x7f9BD5178901dd64B2298b549Dd4c94Efea60F05"

const SM = process.env.REACT_APP_SM
const APPS_ADMIN_FEE = process.env.REACT_APP_APPS_ADMIN_FEE
const BCA_LIMIT = process.env.REACT_APP_BCA_LIMIT
const OTHERS_LIMIT = process.env.REACT_APP_OTHERS_LIMIT
const BANK_FEE = process.env.REACT_APP_BANK_FEE
const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME ?? "mumbai"
const ETH_NETWORK_NAME = process.env.REACT_APP_ETH_NETWORK_NAME ?? "rinkeby"
const BNC_NETWORK_NAME = process.env.REACT_APP_BNC_NETWORK_NAME ?? "bnc"


const GAS_PRICE = process.env.REACT_APP_GAS_PRICE
const GAS_PRICE_FORMAT = process.env.REACT_APP_GAS_PRICE_FORMAT
const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
const PPN = parseInt(process.env.REACT_APP_PPN)
const GA_TABLE_ID = process.env.REACT_APP_GA_TABLE_ID
const CLAIM_OWNER_HOST = process.env.REACT_APP_CLAIM_OWNER_HOST
const CLAIM_OWNER_PORT = process.env.REACT_APP_CLAIM_OWNER_PORT
const CLAIM_EO_HOST = process.env.REACT_APP_CLAIM_EO_HOST
const METAMASK_SIGN_TIMEOUT = process.env.REACT_APP_METAMASK_SIGN_TIMEOUT ?? 5000
const INFURA_IPFS_GATEWAY = process.env.REACT_APP_INFURA_IPFS_GATEWAY ?? "https://ipfs.infura-ipfs.io"
const METAMASK_ALLOW_TRANSACTION_MINUTE = process.env.REACT_APP_METAMASK_ALLOW_TRANSACTION_MINUTE ?? 5
const env = {
  GRAPHQL_API_URL: DEV === "true" ? `http://${DEV_GQL_HOST}:${DEV_GQL_PORT}/graphql` : GQL_HOST,
  API_URL: DEV === "true" ? `http://${DEV_REST_HOST}:${DEV_REST_PORT}` : REST_HOST,
  SOCKET_IO_URL: DEV === "true" ? `http://${DEV_REST_HOST}:${DEV_REST_PORT}` : REST_HOST,
  HOST,
  PORT,
  URL_MATIC_EXPLORER,
  USER_ADDRESS_SCANNER: `${URL_MATIC_EXPLORER}address/`,
  DEV,
  ALLOW_FIAT_PAYMENT,
  CLAIM_EO_HOST,
  CLAIM_OWNER_HOST,
  CLAIM_OWNER_PORT,
  CLAIM_OWNER_URL: DEV === "true" ? `http://${CLAIM_OWNER_HOST}:${CLAIM_OWNER_PORT}` : CLAIM_OWNER_HOST,
  IS_PROD,
  POLYGON_NFT_MARKETPLACE_ADDRESS,
  POLYGON_NFT_AUCTION_ADDRESS,
  POLYGON_NFT_ADDRESS,
  POLYGON_NFT_MULTI_ADDRESS,
  POLYGON_PAYMASTER_ADDRESS,
  POLYGON_BANK_ADDRESS,
  POLYGON_BARTER_ADDRESS,
  BINANCE_NFT_MARKETPLACE_ADDRESS,
  BINANCE_NFT_AUCTION_ADDRESS,
  BINANCE_NFT_ADDRESS,
  BINANCE_NFT_MULTI_ADDRESS,
  BINANCE_PAYMASTER_ADDRESS,
  BINANCE_BANK_ADDRESS,
  BINANCE_BARTER_ADDRESS,
  ETHEREUM_NFT_MARKETPLACE_ADDRESS,
  ETHEREUM_NFT_AUCTION_ADDRESS,
  ETHEREUM_NFT_ADDRESS,
  ETHEREUM_NFT_MULTI_ADDRESS,
  ETHEREUM_PAYMASTER_ADDRESS,
  ETHEREUM_BANK_ADDRESS,
  ETHEREUM_BARTER_ADDRESS,
  SM,
  APPS_ADMIN_FEE,
  BCA_LIMIT,
  OTHERS_LIMIT,
  BANK_FEE,
  NETWORK_NAME,
  ETH_NETWORK_NAME,
  BNC_NETWORK_NAME,
  GAS_PRICE,
  GAS_PRICE_FORMAT,
  GA_TRACKING_ID,
  PPN,
  GA_TABLE_ID,
  METAMASK_SIGN_TIMEOUT,
  INFURA_IPFS_GATEWAY,
  METAMASK_ALLOW_TRANSACTION_MINUTE,
  IS_BINANCE_ALLOWED,
  IS_ETHEREUM_ALLOWED,
  IS_POLYGON_ALLOWED,
  IS_ONLY_POLYGON,
};

export default env