import React, { useReducer } from 'react';
import assetBidContext from './assetBidContext';
import assetBidReducer from './assetBidReducer';
import { post } from '../../utils/graphql-api';
import { CREATE_ASSET_BID, GET_ASSET_BID_BY_ASSET_ID, GET_ASSET_BID_BY_ID, SET_LOADING, UPDATE_ASSET_BID } from '../types';
import logger from 'use-reducer-logger';

const AssetBidState = props => {
  const initialState = {
    assetBids: [],
    assetBid: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(assetBidReducer), initialState);

  const createAssetsBid = async (assetInput) => {
    setLoading()
    const res = await post("createAssetsBid", { ...assetInput })
    dispatch({
      type: CREATE_ASSET_BID,
      payload: res.data.data.createAssetsBid.assetsBid
    });
    return res.data.data.createAssetsBid.assetsBid
  }

  const update = async (id, patch) => {
    setLoading()

    const res = await post('updateAssetsBidById', { id, patch })
    dispatch({
      type: UPDATE_ASSET_BID,
      payload: res.data.data.updateAssetsBidById.assetsBid
    })
    return res.data.data.updateAssetsBidById.assetsBid

  }

  const getById = async (id) => {
    setLoading()

    const res = await post("getAssetsBidById", { id })
    await dispatch({
      type: GET_ASSET_BID_BY_ID,
      payload: await res?.data?.data?.assetsBidById
    })
    return res?.data?.data?.assetsBidById
  }

  const getBidByAssetId = async (assetId) => {
    setLoading()
    try {
      const res = await post("getBidByAssetId", { assetId })
      dispatch({
        type: GET_ASSET_BID_BY_ASSET_ID,
        payload: res?.data?.data?.allAssetsBids.nodes
      });
      return res?.data?.data?.allAssetsBids.nodes

    } catch (error) {
      console.log('ERR:', error);
    }

  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <assetBidContext.Provider
      value={{
        assetBids: state.assetBids,
        assetBid: state.assetBid,
        loading: state.loading,
        createAssetsBid,
        getById,
        getBidByAssetId,
        update
      }}
    >
      {props.children}
    </assetBidContext.Provider>
  );
};

export default AssetBidState;
