import React, { useReducer } from 'react';
import PostProcessingContext from './postProcessingContext';
import PostProcessingReducer from './postProcessingReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import api from '../../utils/api';
import { SET_LOADING, CREATE_ASSET_POSTPROCESS_FIAT, CANCEL_SALE_POSTPROCESS,
  //  ADD_TXN_LOG, DELETE_TXN_LO, CREATE_ASSET_POSTPROCESS_FIATG, UPDATE_TXN_LOG, 
   CANCEL_MAKE_OFFER_POSTPROCESS,LISTING_POSTPROCESS_CRYPTO, LISTING_POSTPROCESS_FIAT, BUY_POSTPROCESS, ACCEPT_MAKE_OFFER_POSTPROCESS, CREATE_ASSET_FIXEDPRICE_META, CREATE_ASSET_AUCTION_META, ENABLE_ASSET_TRANSACTION 
  //  ,CREATE_COLLECTION_POSTPROCESS 
  } from '../types';
import logger from 'use-reducer-logger';
// import { set } from 'lodash';

const PostprocessingState = props => {
  const initialState = {
    buyerAsset:{},
    loading: false  
  };

  const [state, dispatch] = useReducer(logger(PostProcessingReducer), initialState);

  const createNftFiat = async ({
    isERC721, userId, dataAsset, price, nft, uid, txnData
  }) => {
    setLoading()
    
    const body = {
      isERC721, userId, dataAsset, price, nft, uid, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_createnft', {  params }, { withCredentials: true })
    await dispatch({
      type: CREATE_ASSET_POSTPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const listingCrypto = async ({
    asset, txnData
  }) => {
    setLoading()
    
    const body = {
      asset, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_activatesale_crypto', {  params }, { withCredentials: true })
    await dispatch({
      type: LISTING_POSTPROCESS_CRYPTO,
      payload: res?.data
    })
  return res?.data
  }

  const listingFiat = async ({
    asset, txnData
  }) => {
    setLoading()
    
    const body = {
      asset, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_activatesale_crypto', {  params }, { withCredentials: true })
    await dispatch({
      type: LISTING_POSTPROCESS_FIAT,
      payload: res?.data
    })
  return res?.data
  }

  const bidAuction = async ({
    nft, txnData, id, userId
  }) => {
    setLoading()

    const body = {
      nft, txnData, id, userId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_bidauction', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_POSTPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }

  const cancelAuction = async ({
    auctionData, userId, formInput, txnData
  }) => {
    setLoading()

    const body = {
      auctionData, userId, formInput, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_cancelauction', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_POSTPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }
  
  const cancelBidderAuction = async ({
    assetAuction, txnData
  }) => {
    setLoading()

    const body = {
      assetAuction, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_cancelbidderauction', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_POSTPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }
  
  const makeOffer = async ({
    assetBids, nft, txnData, newBidContractId, userId
  }) => {
    setLoading()

    const body = {
      assetBids, nft, txnData, newBidContractId, userId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_makeoffer', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_POSTPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }
  
  const settleAuction = async ({
    auctionData, txnData
  }) => {
    setLoading()

    const body = {
      auctionData, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_settleauction', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_POSTPROCESS_FIAT,
      
      payload: res?.data
    })
    return res?.data
  }
  
  const cancelListing = async ({
    nft, txnData
  }) => {
    setLoading()
    const body = {
      nft, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_cancelsale', {  params }, { withCredentials: true })
    await dispatch({
      type: CANCEL_SALE_POSTPROCESS,
      payload: res?.data
    })
    return res?.data
  }
  
  const acceptMakeOffer = async ({
    acceptedAsset, acceptedBidder, offer, txnData
  }) => {
    setLoading()

    const body = {
      acceptedAsset, acceptedBidder, offer, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_acceptmakeoffer', { params }, { withCredentials: true })
    await dispatch({
      type: ACCEPT_MAKE_OFFER_POSTPROCESS,
      payload: res?.data
    })
    return res?.data
  }
  
  const buy = async ({
    nft, userId, userAddress, formInput, txnData, isCrypto
  }) => {
    setLoading()

    const body = {
      nft, userId, userAddress, formInput, txnData, isCrypto
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_buynft', { params }, { withCredentials: true })
    await dispatch({
      type: BUY_POSTPROCESS,
      payload: res?.data
    })
    return res?.data
  }
  
  const transfer = async ({
    nft, userId, userAddress, formInput, txnData, isCrypto
  }) => {
    setLoading()

    const body = {
      nft, userId, userAddress, formInput, txnData, isCrypto
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_transfernft', { params }, { withCredentials: true })
    await dispatch({
      type: BUY_POSTPROCESS,
      payload: res?.data
    })
    return res?.data
  }

  const listingAuction = async ({
    asset, txnData, date, nft, uid, userId
  }) => {
    setLoading()

    const body = {
      asset, txnData, date, nft, uid, userId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_activatesaleauction', { params }, { withCredentials: true })
    await dispatch({
      type: LISTING_POSTPROCESS_FIAT,
      payload: res?.data
    })
    return res?.data
  }
  

  const cancelBidOffer = async ({
    offer, asset, formInput, txnData, userId
  }) => {
      // setLoading()
      const body = {
        offer, asset, formInput, txnData, userId
      }
      const params = JSON.stringify(body)
      const res = await api.post('transactions/synchronize_cancelbid', {  params }, { withCredentials: true })
      await dispatch({
        type: CANCEL_MAKE_OFFER_POSTPROCESS,
        payload: res?.data
      })
      return res?.data
  }

  const createCollection = async ({
    collectionData, verificationData
  }) => {
    const body = {
      collectionData, verificationData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_createcollection', {  params }, { withCredentials: true })
    // dispatch({
    //   type: CREATE_COLLECTION_POSTPROCESS,
    //   payload: res?.data
    // })
    return res?.data
  }

  const updateCollection = async ({
    collectionData, verificationData, collectionId
  }) => {
    const body = {
      collectionData, verificationData, collectionId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_updatecollection', {  params }, { withCredentials: true })
    // dispatch({
    //   type: CREATE_COLLECTION_POSTPROCESS,
    //   payload: res?.data
    // })
    return res?.data
  }

  const acceptBarter = async ({
    acceptedAsset, acceptedRequesterAsset, assetBarterRequest, txnData
  }) => {
    const body = {
      acceptedAsset, acceptedRequesterAsset, assetBarterRequest, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_accept_request_barter', { params }, { withCredentials: true })
    // dispatch({
    //   type: CREATE_COLLECTION_POSTPROCESS,
    //   payload: res?.data
    // })
    return res?.data
  }

  const requestBarter = async ({
    assetBarters, nft, txnData, newBarterContractId, userId, barteredAssetId
  }) => {
    const body = {
      assetBarters, nft, txnData, newBarterContractId, userId, barteredAssetId
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_request_barter', { params }, { withCredentials: true })
    // dispatch({
    //   type: CREATE_COLLECTION_POSTPROCESS,
    //   payload: res?.data
    // })
    return res?.data
  }
  const cancelRequestBarter = async ({
    assetRequestBarter, txnData
  }) => {
    const body = {
      assetRequestBarter, txnData
    }
    const params = JSON.stringify(body)
    const res = await api.post('transactions/synchronize_cancel_request_barter', { params }, { withCredentials: true })
    return res?.data
  }

  const updateMetadata = async(input) => {
    const res = await api.post('/transactions/synchronize_changeuri',input,{withCredentials:true})
    return res?.data
  }

  const createAndActivateSaleFixedprice = async(param, userId, txnData) => {
    const nft = {...param}
    const body = {nft, userId, txnData}
    const params = JSON.stringify(body)
    // console.log(body, "TES")
    const res = await api.post('transactions/create_and_activatesalefixedprice', { params }, { withCredentials: true })
    await dispatch({
      type: CREATE_ASSET_FIXEDPRICE_META,
      payload: res?.data
    })
    return res?.data
  }

  const createAndActivateSaleAuction = async(param, userId, uid, txnData) => {
    const nft = {...param}
    const body = {nft, userId, uid, txnData}
    const params = JSON.stringify(body)
    // console.log(body, "TES")
    const res = await api.post('transactions/create_and_activatesaleauction', { params }, { withCredentials: true })
    await dispatch({
      type: CREATE_ASSET_AUCTION_META,
      payload: res?.data
    })
    return res?.data
  }

  const enableAssetTransaction = async ({
    assetId, txnId
  }) => {
    const body = {assetId, txnId}
    const params = JSON.stringify(body)
    const res = await api.post('transactions/enable_asset_transaction', { params }, { withCredentials: true })
    await dispatch({
      type: ENABLE_ASSET_TRANSACTION,
      payload: res?.data
    })
    return res?.data
  }

  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <PostProcessingContext.Provider
      value={{
        buyerAsset:state.buyerAsset,
        loading: state.loading,
        setLoading,
        createNftFiat,
        listingCrypto,
        listingFiat,
        bidAuction,
        cancelAuction,
        cancelBidderAuction,
        makeOffer,
        settleAuction,
        acceptMakeOffer,
        buy,
        transfer,
        listingAuction,
        cancelListing,
        cancelBidOffer,
        createCollection,
        updateCollection,
        updateMetadata,
        createAndActivateSaleFixedprice,
        createAndActivateSaleAuction,
        enableAssetTransaction,
        requestBarter,
        cancelRequestBarter,
        acceptBarter,
      }}
    >
      {props.children}
    </PostProcessingContext.Provider>
  );
};

export default PostprocessingState;
