import {
  ALL_COLLECTION,
  ALL_COLLECTION_WITH_FILTER,
  CREATE_COLLECTION,
  GET_COLLECTION,
  SET_LOADING,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case ALL_COLLECTION:
      return {
        ...state,
        collections: action.payload.nodes,
        
        totalCount: action.payload.totalCount,
        pageInfo: action.payload.pageInfo,
        loading: false
      };
    case CREATE_COLLECTION:

      return{
        ...state,
        collections: [...state.collections,action?.payload],
        collection: action?.payload,
        loading: false,
      }
    case ALL_COLLECTION_WITH_FILTER:
      return {
        ...state,
        collections: action.payload,
        totalCount: action.totalCount,
        pageInfo: action.pageInfo,
        loading: false
      };
    case GET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
