import React, { useReducer } from 'react';
import UtilitasContext from './utilitasContext';
import UtilitasReducer from './utilitasReducer';
import { post } from '../../utils/graphql-api';
import { GET_ALL_UTILITAS, GET_ALL_UTILITAS_PROJECTS, GET_OWNED_UTILITAS, SET_DETAIL_UTILITAS, SET_LOADING } from '../types';
import logger from 'use-reducer-logger';

const UtilitasState = props => {
  const initialState = {
    utilitass: [],
    ownedUtility: [],
    projects: [],
    pageInfo: {},
    totalCount: 0,
    detailUtilitas: null,
    loading: false
  };

  const [state, dispatch] = useReducer(logger(UtilitasReducer), initialState);


  const getAssetUtilForOwner = async (params) => {
    setLoading();
    const res = await post("getAssetUtilityFilterByAssetAndBuyerId", {...params});
    await dispatch({
      type: GET_OWNED_UTILITAS,
      payload: res?.data?.data.allAssetsUtilities?.nodes,
      totalCount: res?.data?.data.allAssetsUtilities?.totalCount,
      pageInfo: res?.data?.data.allAssetsUtilities?.pageInfo,
    })
    return res?.data?.data.allAssetsUtilities?.nodes
  }

  // Search Users
  const getAll = async (option) => {
    setLoading();

    const res = await post("daftarUtilitasAsset", { ...option });
    await dispatch({
      type: GET_ALL_UTILITAS,
      payload: res?.data?.data?.allAssetsUtilities
    });
    return res?.data?.data?.allAssetsUtilities
  };

  // Search Users
  const getAllProject = async (id) => {
    setLoading();

    const res = await post("filterProjekDaftarUtilitasAsetmu", { buyerId: id });
    await dispatch({
      type: GET_ALL_UTILITAS_PROJECTS,
      payload: res?.data?.data?.filterByProjectUtilitasMu
    });
    return res?.data?.data?.filterByProjectUtilitasMu
  };

  const getDetail = async (params) => {
    setLoading()
    const res = await post('detailUtilitas', {id: params})
    await dispatch({
      type: SET_DETAIL_UTILITAS,
      payload: res?.data?.data?.assetsUtilityById,
    })
    return res?.data?.data?.assetsUtilityById
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <UtilitasContext.Provider
      value={{
        utilitass: state.utilitass,
        projects: state.projects,
        pageInfo: state.pageInfo,
        totalCount: state.totalCount,
        detailUtilitas: state.detailUtilitas,
        ownedUtility: state.ownedUtility,
        loading: state.loading,
        getAllProject,
        getAll,
        getDetail,
        getAssetUtilForOwner,
      }}
    >
      {props.children}
    </UtilitasContext.Provider>
  );
};

export default UtilitasState;
