import React from 'react';
import { EmptyState } from '../../assets';

const EmptyStateSection = ({ className, imgSize, text, textSize, height, customPadding }) => {
  return (
    <div
      className={`${className} ${height} ${customPadding} flex flex-col items-center justify-center`}>
      <img src={EmptyState} className={`h-${imgSize}`} alt="emtpy_state" />
      <p className={`${textSize} font-poppins font-normal -mt-4 text-teks_2`}>{text}</p>
    </div>
  );
};

EmptyStateSection.defaultProps = {
  text: 'Belum ada transaksi',
  className: '',
  imgSize: 48,
  textSize: 'md:text-xl text-base',
  height: 'h-full',
  customPadding: 'py-28'
};

export default EmptyStateSection;
