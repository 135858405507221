import React, { useCallback, useMemo } from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import authContext from '../../../context/Auth/authContext'
import notificationContext from '../../../context/Notification/notificationContext'
import EmptyStateSection from '../../Panel/EmptyState'
import { useNavigate } from 'react-router-dom'
import CardNotification from './CardNotification'
import NotificationMobile from './NotificationMobile'
import ButtonText from '../../Text/ButtonText'
import BellIcon from '../../../assets/icon/bell_icon'
import ResponsiveContext from '../../../context/Responsive/responsiveContext'
import { useClickOutside } from '../../../hooks/useClickOutside'

const IconNotification = ({ session }) => {
  const isMobile = useContext(ResponsiveContext)
  const { pageInfo, getAll, update, updateBatchToRead, getUnread, totalCount } =
    useContext(notificationContext)
  const AuthContext = useContext(authContext)
  const [dataNotif, setDataNotif] = useState([])
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [getAgain, setGetAgain] = useState(false)
  const ref = useRef()
  const mounted = useRef()
  const navigate = useNavigate()
  const isThereUnread = useMemo(() => {
    return totalCount > 0
  }, [totalCount])
  const handleClick = useCallback(() => {
    setShow(!show)
  }, [show])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const fetchLoad = useCallback(async () => {
    const fetchAgain = await getAll({
      first: 5,
      after: pageInfo?.endCursor,
      accountId: AuthContext?.auth?.user?.id,
    })
    setDataNotif(dataNotif.concat(fetchAgain?.nodes))
  }, [AuthContext?.auth?.user?.id, dataNotif, getAll, pageInfo?.endCursor])

  const handleClickMark = useCallback(
    async (id, event) => {
      setLoading(true)
      event.stopPropagation()
      try {
        await update(id, { isRead: true })
        if (totalCount === 1) {
          await getUnread(AuthContext?.auth?.user?.id)
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    },
    [AuthContext?.auth?.user?.id, getUnread, totalCount, update],
  )

  const handleClickReadAll = async () => {
    setLoading(true)
    try {
      // console.log('TEs', id)
      await updateBatchToRead(AuthContext?.auth?.user?.id)
      await getUnread(AuthContext?.auth?.user?.id)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleClickCard = async (id, url, isRead) => {
    // console.log('TES', id, url)
    if (!isRead) {
      await update(id, { isRead: true })
    }
    if (url) {
      navigate(url)
      setShow(false)
    }
  }

  useClickOutside(ref, show, setShow)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // console.log(NotifContext?.pageInfo?.endCursor, "TES")
    if (mounted.current) {
      if (getAgain && pageInfo?.endCursor !== null) {
        fetchLoad()
        setGetAgain(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAgain])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!loading && mounted.current) {
      if (session && AuthContext?.auth?.user?.id !== undefined && show) {
        const fetchNotif = await getAll({
          first: isMobile ? 10 : 5,
          after: null,
          accountId: AuthContext?.auth?.user?.id,
        })
        setDataNotif(fetchNotif?.nodes)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, loading, session, AuthContext?.auth?.user?.id, mounted.current])

  return (
    <div ref={ref} className="relative">
      <div onClick={handleClick} className="relative cursor-pointer">
        <BellIcon size={8} />
        {isThereUnread && (
          <div className="rounded-full absolute top-1 right-1 h-2 w-2 bg-red-700" />
        )}
      </div>
      {!isMobile ? (
        <NotificationBar
          show={show}
          dataNotif={dataNotif}
          handleCardClick={handleClickCard}
          setShow={setShow}
          handleClickMark={handleClickMark}
          setGetAgain={setGetAgain}
          handleClickReadAll={handleClickReadAll}
          unRead={isThereUnread}
          // innerRef={innerRef}
        />
      ) : (
        <NotificationMobile
          toggleMenu={show}
          dataNotif={dataNotif}
          handleCardClick={handleClickCard}
          setShow={setShow}
          handleClickMark={handleClickMark}
          setGetAgain={setGetAgain}
          handleClickReadAll={handleClickReadAll}
          unRead={isThereUnread}
        />
      )}
    </div>
  )
}

//#region

const NotificationBar = ({
  show,
  dataNotif,
  handleCardClick,
  handleClickMark,
  setGetAgain,
  handleClickReadAll,
  unRead,
}) => {
  const length = dataNotif?.length
  const isEmpty = length === 0
  const innerRef = useRef()
  const handleScroll = () => {
    if (innerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef?.current
      if (Math.ceil(scrollTop) + Math.ceil(clientHeight) >= scrollHeight) {
        setGetAgain(true)
        // console.log('WADU')
      }
    }
  }
  // console.log("TES", dataNotif)
  return (
    <div
      onScroll={handleScroll}
      ref={innerRef}
      className={`w-bar_notification h-96 overflow-y-auto absolute right-4 py-4 bg-white shadow-md rounded-lg ${
        show ? 'block' : 'hidden'
      }`}
    >
      <div className="flex justify-between items-center px-4 mb-2">
        <p className="font-poppins font-semibold text-lg">Notifikasi</p>
        {unRead && (
          <ButtonText
            tx="Tandai semua sudah dibaca"
            classstyle="font-poppins font-medium text-xs cursor-pointer"
            color="text-hijau_hutan"
            onClick={handleClickReadAll}
          />
        )}
      </div>
      {!isEmpty ? (
        dataNotif?.map(
          (
            {
              accountByAccountId,
              accountByBuyerId,
              assetByAssetId,
              isRead,
              type,
              createdAt,
              id,
            },
            idx,
          ) => (
            <CardNotification
              // ref={ref}
              key={idx}
              id={id}
              accountByAccountId={accountByAccountId}
              accountByBuyerId={accountByBuyerId}
              isRead={isRead}
              type={type}
              createdAt={createdAt}
              handleCardClick={handleCardClick}
              handleClickMark={handleClickMark}
              isLast={idx === length - 1}
              assetByAssetId={assetByAssetId}
            />
          ),
        )
      ) : (
        <EmptyStateSection
          text={'Belum ada notifikasi untuk kamu'}
          className="w-96"
        />
      )}
    </div>
  )
}
//#endregion

export default React.memo(IconNotification, (pre, next) => {
  return pre.session === next.session
})
