import React, { useCallback } from 'react'
import CloseIcon from '../../assets/icon/close_icon'

const NotifHeader = ({ navigate, show, setShow, location, isFiat }) => {
  const isOnPage = location.pathname.includes('/history-txn')
  const handleToAccount = useCallback(
    () => navigate(`/history-txn/pending#${isFiat ? 'fiat' : 'crypto'}`),
    [navigate, isFiat],
  )
  // const isOnActivity = location.pathname === '/setting/txn-activity-all'
  const handleClose = useCallback(() => {
    setShow(false)
  }, [setShow])

  return (
    <div
      className={`w-full ${
        !show || isOnPage ? 'hidden' : 'flex'
      } bg-kuning_notif py-3 sticky items-center justify-center md:top-72px top-16`}
    >
      <p className="text-center font-poppins font-medium text-yellow-700">
        Kamu memiliki transaksi tertunda. Untuk menyelesaikan transaksi,
        silahkan pindah ke halaman Sejarah Transaksi, atau{' '}
        <a
          onClick={handleToAccount}
          className="cursor-pointer font-semibold text-yellow-700 underline"
        >
          Klik Disini
        </a>
      </p>
      <div
        className="md:ml-16 ml-2 md:mr-0 mr-4 cursor-pointer"
        onClick={handleClose}
      >
        <CloseIcon size={4} color="#222222" />
      </div>
    </div>
  )
}

export default NotifHeader
