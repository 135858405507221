import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.scss'
import { BrowserRouter } from 'react-router-dom'
import './i18n'

import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'video-react/dist/video-react.css'
import 'react-toastify/dist/ReactToastify.css'
import 'swiper/swiper.scss' // core Swiper
import 'swiper/modules/navigation/navigation.scss' // Navigation module
import 'swiper/modules/pagination/pagination.scss'
// import 'swiper/css';

function getLibrary(provider) {
  return new Web3(provider)
}
const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <HelmetProvider>
            {/* <Helmet> */}
            <App />
            {/* </Helmet> */}
          </HelmetProvider>
        </BrowserRouter>
      </Web3ReactProvider>
    </React.StrictMode>,
    rootElement,
  )
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <HelmetProvider>
            {/* <Helmet> */}
            <App />
            {/* </Helmet> */}
          </HelmetProvider>
        </BrowserRouter>
      </Web3ReactProvider>
    </React.StrictMode>,
    rootElement,
  )
}
reportWebVitals()
