import React, { useReducer } from 'react';
import assetBidDetailContext from './assetBidDetailContext';
import assetBidDetailReducer from './assetBidDetailReducer';
import { post } from '../../utils/graphql-api';
import { CREATE_ASSET_BID_DETAIL, GET_ALL_PROFILE_IN, GET_ALL_PROFILE_OFFER_MADE, GET_ASSET_BID_DETAIL, IS_BIDDER_HAS_BID, SET_LOADING, UPDATE_ASSET_BID_DETAIL, GET_BID_DETAIL_BY_ASSET } from '../types';
import logger from 'use-reducer-logger';

const AssetBidDetailState = props => {
  const initialState = {
    offerMade: [],
    offerIn: [],
    assetBidDetail: {},
    loading: false,
    isOffer: [],
    bidDetailByAsset: []
  };

  const [state, dispatch] = useReducer(logger(assetBidDetailReducer), initialState);

  const createAssetsBidDetail = async (assetInput) => {
    setLoading()

    const res = await post("createAssetsBidDetail", { ...assetInput })
    dispatch({
      type: CREATE_ASSET_BID_DETAIL,
      payload: res.data.data.createAssetsBidDetail.assetsBidDetail
    });
    return res.data.data.createAssetsBidDetail.assetsBidDetail

  }

  const get = async (id) => {
    setLoading()

    const res = await post("assetsBidDetailById", { id })
    await dispatch({
      type: GET_ASSET_BID_DETAIL,
      payload: await res?.data?.data?.assetsBidDetailById
    })
    return res?.data?.data?.assetsBidDetailById
  }

  const profileOfferIn = async (assetInput) => {
    setLoading()

    const res = await post("profileOfferIn", { ...assetInput })
    dispatch({
      type: GET_ALL_PROFILE_IN,
      payload: res.data.data?.allAssetsBids?.nodes
    });
    return res.data.data?.allAssetsBids?.nodes

  }


  const update = async (id, patch) => {
    setLoading()

    const res = await post('updateAssetsBidDetailById', { id, patch })
    dispatch({
      type: UPDATE_ASSET_BID_DETAIL,
      payload: res.data.data.updateAssetsBidDetailById.assetsBidDetail
    })
    return res.data.data.updateAssetsBidDetailById.assetsBidDetail

  }

  const profileOfferMade = async (assetInput) => {
    setLoading()

    const res = await post("profileOfferMade", { ...assetInput })
    dispatch({
      type: GET_ALL_PROFILE_OFFER_MADE,
      payload: res.data.data?.allAssetsBidDetails?.nodes
    });
    return res.data.data?.allAssetsBidDetails?.nodes

  }

  const isBidderHasBid = async (input) => {
    setLoading()

    const res = await post("isBidderHasBid", { ...input })

    dispatch({
      type: IS_BIDDER_HAS_BID,
      payload: res.data.data?.allAssetsBidDetails?.nodes
    });
    return res.data.data?.allAssetsBidDetails?.nodes
  }

  const getBidDetailByAsset = async (input) => {
    setLoading()

    const res = await post("assetDetailPenawaran", { ...input })
    dispatch({
      type: GET_BID_DETAIL_BY_ASSET,
      payload: res.data.data?.assetDetailPenawaran?.nodes
    })

    return res.data.data?.assetDetailPenawaran?.nodes
  }

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <assetBidDetailContext.Provider
      value={{
        offerMade: state.offerMade,
        offerIn: state.offerIn,
        isOffer: state.isOffer,
        assetBidDetail: state.assetBidDetail,
        loading: state.loading,
        bidDetailByAsset: state.bidDetailByAsset,
        isBidderHasBid,
        createAssetsBidDetail,
        profileOfferIn,
        profileOfferMade,
        getBidDetailByAsset,
        update,
        get
      }}
    >
      {props.children}
    </assetBidDetailContext.Provider>
  );
};

export default AssetBidDetailState;
