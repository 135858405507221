import React from "react";

const LogoutIcon = ({viewBox, size, onClick, color, ...props}) => {
    return (
        <div onClick={onClick} {...props}>
            <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
            <path d="M8.25 7.5C8.44891 7.5 8.63968 7.42098 8.78033 7.28033C8.92098 7.13968 9 6.94891 9 6.75C9 6.55109 8.92098 6.36032 8.78033 6.21967C8.63968 6.07902 8.44891 6 8.25 6H6.75C6.55109 6 6.36032 6.07902 6.21967 6.21967C6.07902 6.36032 6 6.55109 6 6.75V17.25C6 17.4489 6.07902 17.6397 6.21967 17.7803C6.36032 17.921 6.55109 18 6.75 18H8.25C8.44891 18 8.63968 17.921 8.78033 17.7803C8.92098 17.6397 9 17.4489 9 17.25C9 17.0511 8.92098 16.8603 8.78033 16.7197C8.63968 16.579 8.44891 16.5 8.25 16.5H7.5V7.5H8.25Z" fill={color}/>
            <path d="M18.615 11.565L16.5 8.56499C16.3853 8.4033 16.2112 8.2936 16.0159 8.25987C15.8205 8.22614 15.6198 8.27113 15.4575 8.38499C15.3764 8.44183 15.3073 8.51418 15.2543 8.59785C15.2013 8.68152 15.1654 8.77486 15.1487 8.87248C15.1319 8.9701 15.1347 9.07008 15.1568 9.16662C15.1789 9.26317 15.2199 9.35438 15.2775 9.43499L16.5675 11.25H10.5C10.3011 11.25 10.1103 11.329 9.96967 11.4697C9.82902 11.6103 9.75 11.8011 9.75 12C9.75 12.1989 9.82902 12.3897 9.96967 12.5303C10.1103 12.671 10.3011 12.75 10.5 12.75H16.5L15.15 14.55C15.0909 14.6288 15.0479 14.7184 15.0235 14.8139C14.999 14.9093 14.9936 15.0086 15.0075 15.1061C15.0215 15.2036 15.0545 15.2974 15.1046 15.3821C15.1548 15.4669 15.2212 15.5409 15.3 15.6C15.4298 15.6974 15.5877 15.75 15.75 15.75C15.8664 15.75 15.9813 15.7229 16.0854 15.6708C16.1896 15.6187 16.2801 15.5431 16.35 15.45L18.6 12.45C18.6961 12.3231 18.7493 12.169 18.752 12.0099C18.7547 11.8509 18.7067 11.695 18.615 11.565V11.565Z" fill={color}/>
            </svg>
        </div>
    )
}

LogoutIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: "#868686",
}

export default LogoutIcon