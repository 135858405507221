import Modal from "./Modal";
import CardModal from "../Card/CardModal";
import ModalTitleText from "../Text/ModalTitleText";
// import SpinCircleLogo from "../../assets/animation/spin_circle_logo"
import { useContext } from "react";
import ResponsiveContext from "../../context/Responsive/responsiveContext";
// import RoundedButton from "../Button/RoundedButton";
// import ButtonText from "../Text/ButtonText";
import ButtonModal from "./ButtonModal";
import P from './P';
import Cookies from "js-cookie";
import { LoginIlus } from "../../assets";

const ModalLogin = (props) => {
  const responsive = useContext(ResponsiveContext)

  const handleUnResponsiveMetamask = () => {
    Cookies.set("unResponsiveMetamask", true)
    window.location.reload()
  }

  return (
    <Modal responsive={responsive} {...props} >
      <CardModal>
        <ModalTitleText text="Login dengan Metamask" />
        <img src={LoginIlus} alt="" className="h-28"/>
        <P fontSize="md:text-lg text-base">
          Harap lakukan proses tanda tangan metamask. Jika form metamask belum muncul maka tekan tombol refresh
          dan klik notifikasi icon atau lakukan proses tanda tangan. 
        </P>
        <ButtonModal onClick={handleUnResponsiveMetamask} tx="Refresh" />
      </CardModal>
    </Modal>
  );
};

export default ModalLogin;
