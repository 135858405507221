import React, { useReducer } from 'react';
import parameterContext from './parameterContext';
import parameterReducer from './parameterReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { RESET_PARAM_BARTER, SET_LOADING, SET_PARAM_BARTER } from '../types';
import logger from 'use-reducer-logger';

const ParameterState = props => {
  const initialState = {
    barterParameter: {assetSelected: null, assetBarter: null},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(parameterReducer), initialState);

  const setBarterParam = (payload, step) => {
    dispatch({
      type: SET_PARAM_BARTER,
      payload: {...payload},
      step: step,
    })
  }
  const resetBarterParam = () => {
    dispatch({
      type: RESET_PARAM_BARTER,
    })
  }
 
  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <parameterContext.Provider
      value={{
        barterParameter : state.barterParameter,
        setLoading,
        setBarterParam,
        resetBarterParam,
      }}
    >
      {props.children}
    </parameterContext.Provider>
  );
};

export default ParameterState;
