import {
  format,
  getDate,
  getDay,
  getMonth,
  getYear,
  intervalToDuration,
} from 'date-fns'
import { ethers } from 'ethers'
import env from '../config/env'
import versionTxt from '../version.txt'
// const path = require("path");

import {
  // BCA,
  BNI,
  BRI,
  DANA,
  LINKAJA,
  MANDIRI,
  OVO,
  PERMATA,
  QRIS,
  // SAMPOERNA,
  SHOPEEPAY,
} from '../utils/constants/paymentTypes'
import { ERC721 } from './constants/contractType'
import {
  BLOCKCHAIN_BINANCE,
  BLOCKCHAIN_ETHERIUM,
  BLOCKCHAIN_POLYGON,
} from './constants/blockChainTypes'
import {
  AUCTION_CONTRACT_TYPES,
  BANK_CONTRACT_TYPES,
  BARTER_CONTRACT_TYPES,
  MARKETPLACE_CONTRACT_TYPES,
  MULTI_CONTRACT_TYPES,
  PAYMASTER_CONTRACT_TYPES,
  SINGLE_CONTRACT_TYPES,
} from './constants/contractAddressTypes'
import {
  BNC_MAINNET,
  BNC_TESTNET,
  ETHEREUM_MAINNET,
  ETHEREUM_RINKEBY,
  POLYGON_MAINNET,
  POLYGON_MUMBAI,
} from './constants/metamaskConfig'

export const WEB_HOST = window.location.protocol + '//' + window.location.host

export const comparePricePercentage = (initialPrice, comparedPrice) => {
  return initialPrice && comparedPrice
    ? initialPrice >= comparedPrice
      ? ((initialPrice - comparedPrice) / comparedPrice) * 100
      : ((comparedPrice - initialPrice) / initialPrice) * 100
    : null
}

export const calculateGasFee = gasPrice => {
  return gasPrice !== 0 ? Math.ceil((gasPrice * 3) / 1000) * 1000 : 0
}

export function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest()
  xhr.onload = function () {
    callback(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export const renderFileUploadPath = (type, address) => `/${type}/${address}/`

export const renderUserLabel = (username, address) => {
  return `${username ?? address?.substring(0, 5)}${!username ? '...' : ''}${
    !username ? address?.substring(address?.length - 4, address?.length) : ''
  }`
}

export const renderAddress = address => {
  if (address) {
    return `${address.substr(0, 5)}...${address?.substr(address.length - 4)}`
  } else {
    return null
  }
}

export const convertNumber = value => {
  return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
}

export const renderIntervalDateText = value => {
  const { years, months, weeks, days, hours, minutes, seconds } =
    intervalToDuration({
      start: new Date(value),
      end: new Date(),
    })
  if (years > 0) {
    return `${years} Tahun Yang Lalu`
  } else if (months) {
    return `${months} Bulan Yang Lalu`
  } else if (weeks) {
    return `${weeks} Minggu Yang Lalu`
  } else if (days) {
    return `${days} Hari Yang Lalu`
  } else if (hours) {
    return `${hours} Jam Yang Lalu`
  } else if (minutes) {
    return `${minutes} Menit Yang Lalu`
  } else if (seconds) {
    return `${seconds} Detik Yang Lalu`
  }
}

export const gasPriceGetter = () => {
  return ethers.utils.parseUnits(env.GAS_PRICE, env.GAS_PRICE_FORMAT)
}

export const convertPriceTable = gasFee => {
  return [
    {
      name: BNI?.value,
      adminFee: BNI?.admin_fee(gasFee),
      gasFee,
      maximumLimit: BNI.maximumLimit,
    },
    {
      name: BRI?.value,
      adminFee: BRI?.admin_fee(gasFee),
      gasFee,
      maximumLimit: BRI.maximumLimit,
    },
    {
      name: DANA?.value,
      adminFee: DANA?.admin_fee(gasFee),
      gasFee,
      maximumLimit: DANA.maximumLimit,
    },
    {
      name: LINKAJA?.value,
      adminFee: LINKAJA?.admin_fee(gasFee),
      gasFee,
      maximumLimit: LINKAJA.maximumLimit,
    },
    {
      name: MANDIRI?.value,
      adminFee: MANDIRI?.admin_fee(gasFee),
      gasFee,
      maximumLimit: MANDIRI.maximumLimit,
    },
    {
      name: OVO?.value,
      adminFee: OVO?.admin_fee(gasFee),
      gasFee,
      maximumLimit: OVO.maximumLimit,
    },
    {
      name: PERMATA?.value,
      adminFee: PERMATA?.admin_fee(gasFee),
      gasFee,
      maximumLimit: PERMATA.maximumLimit,
    },
    {
      name: QRIS?.value,
      adminFee: QRIS?.admin_fee(gasFee),
      gasFee,
      maximumLimit: QRIS.maximumLimit,
    },
    {
      name: SHOPEEPAY?.value,
      adminFee: SHOPEEPAY?.admin_fee(gasFee),
      gasFee,
      maximumLimit: SHOPEEPAY.maximumLimit,
    },
  ]
}

export const sumAssetAmount = (assets, publishedAsset) => {
  const sumAsset =
    assets?.reduce((total, asset) => {
      if (asset?.tokenStandardType === ERC721) {
        return total + 1
      } else {
        return total + asset?.remainingAmount
      }
    }, 0) ?? 0
  const sumPublishedAsset =
    publishedAsset?.reduce((total, asset) => {
      return total + asset?.assetsUtilitiesByAssetId?.totalCount
    }, 0) ?? 0
  console.log('zap sum asset amount', sumAsset, sumPublishedAsset)
  return sumAsset + sumPublishedAsset
}

export const makeid = length => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function kFormatter(num, threshold = 999) {
  return Math.abs(num) > threshold
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'
    : Math.sign(num) * Math.abs(num).toFixed(2)
}

export function formatMillion(num) {
  if (Math.abs(num) > 999999 && Math.abs(num) < 999999999) {
    return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + ' Juta'
  } else if (Math.abs(num) > 99999999) {
    return Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(1) + ' Milyar'
  } else {
    return Math.sign(num) * Math.abs(num).toFixed(2)
  }
}

export const dateFormater = date => {
  const dataHari = [
    'Minggu',
    'Senin',
    'Selasa',
    'Rabu',
    'Kamis',
    'Jumat',
    'Sabtu',
  ]
  const dataBulan = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]
  const newDate = format(new Date(date), 'd MMMM y,h:mm a')
  const getHari = dataHari[getDay(new Date(newDate))]
  const getTanggal = getDate(new Date(newDate))
  const getBulan = dataBulan[getMonth(new Date(newDate))]
  const getTahun = getYear(new Date(newDate))
  return {
    day: getHari,
    dateNumber: getTanggal,
    month: getBulan,
    year: getTahun,
    date: newDate,
    dateIndo: `${getHari}, ${getTanggal} ${getBulan} ${getTahun}`,
  }
}
export function readVersionTextFile() {
  var res
  var rawFile = new XMLHttpRequest()
  rawFile.open('GET', versionTxt, false)
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status === 0) {
        var allText = rawFile.responseText
        res = allText
      }
    }
  }
  rawFile.send(null)
  return res
}

export function isHex(text) {
  let regExp = /^[0-9a-fA-F]+$/
  let result = regExp.test(text)
  return result
}

export function numFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'Milyar'
  }
  if (num >= 10000000) {
    return (num / 1000000).toFixed(0).replace(/\.0$/, '') + ' Jt'
  }
  // if (num >= 1000) {
  //    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  // }
  return num
}

export function getNetworkDetail(blockchainType) {
  let mumbaiChainId, chainName, nativeCurrency, rpcUrls, blockExplorerUrls
  switch (blockchainType) {
    case BLOCKCHAIN_BINANCE:
      if (env.BNC_NETWORK_NAME === 'bnc') {
        mumbaiChainId = BNC_MAINNET.chainId
        chainName = BNC_MAINNET.chainName
        nativeCurrency = BNC_MAINNET.nativeCurrency
        rpcUrls = BNC_MAINNET.rpcUrl
        blockExplorerUrls = BNC_MAINNET.blockExplorerUrl
      } else {
        mumbaiChainId = BNC_TESTNET.chainId
        chainName = BNC_TESTNET.chainName
        nativeCurrency = BNC_TESTNET.nativeCurrency
        rpcUrls = BNC_TESTNET.rpcUrl
        blockExplorerUrls = BNC_TESTNET.blockExplorerUrl
      }

      break
    case BLOCKCHAIN_ETHERIUM:
      if (env.ETH_NETWORK_NAME === 'ethereum') {
        mumbaiChainId = ETHEREUM_MAINNET.chainId
        chainName = ETHEREUM_MAINNET.chainName
        nativeCurrency = ETHEREUM_MAINNET.nativeCurrency
        rpcUrls = ETHEREUM_MAINNET.rpcUrl
        blockExplorerUrls = ETHEREUM_MAINNET.blockExplorerUrl
      } else {
        mumbaiChainId = ETHEREUM_RINKEBY.chainId
        chainName = ETHEREUM_RINKEBY.chainName
        nativeCurrency = ETHEREUM_RINKEBY.nativeCurrency
        rpcUrls = ETHEREUM_RINKEBY.rpcUrl
        blockExplorerUrls = ETHEREUM_RINKEBY.blockExplorerUrl
      }

      break
    case BLOCKCHAIN_POLYGON:
      if (env.NETWORK_NAME === 'polygon') {
        mumbaiChainId = POLYGON_MAINNET.chainId
        chainName = POLYGON_MAINNET.chainName
        nativeCurrency = POLYGON_MAINNET.nativeCurrency
        rpcUrls = POLYGON_MAINNET.rpcUrl
        blockExplorerUrls = POLYGON_MAINNET.blockExplorerUrl
      } else if (env.NETWORK_NAME === 'mumbai') {
        mumbaiChainId = POLYGON_MUMBAI.chainId
        chainName = POLYGON_MUMBAI.chainName
        nativeCurrency = POLYGON_MUMBAI.nativeCurrency
        rpcUrls = POLYGON_MUMBAI.rpcUrl
        blockExplorerUrls = POLYGON_MUMBAI.blockExplorerUrl
      }
      break

    default:
      if (env.NETWORK_NAME === 'polygon') {
        mumbaiChainId = POLYGON_MAINNET.chainId
        chainName = POLYGON_MAINNET.chainName
        nativeCurrency = POLYGON_MAINNET.nativeCurrency
        rpcUrls = POLYGON_MAINNET.rpcUrl
        blockExplorerUrls = POLYGON_MAINNET.blockExplorerUrl
      } else if (env.NETWORK_NAME === 'mumbai') {
        mumbaiChainId = POLYGON_MUMBAI.chainId
        chainName = POLYGON_MUMBAI.chainName
        nativeCurrency = POLYGON_MUMBAI.nativeCurrency
        rpcUrls = POLYGON_MUMBAI.rpcUrl
        blockExplorerUrls = POLYGON_MUMBAI.blockExplorerUrl
      } else if (env.NETWORK_NAME === 'rinkeby') {
        mumbaiChainId = ETHEREUM_RINKEBY.chainId
        chainName = ETHEREUM_RINKEBY.chainName
        nativeCurrency = ETHEREUM_RINKEBY.nativeCurrency
        rpcUrls = ETHEREUM_RINKEBY.rpcUrl
        blockExplorerUrls = ETHEREUM_RINKEBY.blockExplorerUrl
      }
      break
  }

  return {
    mumbaiChainId,
    chainName,
    nativeCurrency,
    rpcUrls,
    blockExplorerUrls,
  }
}

export function getContractHelper(
  blockchainType = BLOCKCHAIN_POLYGON,
  contractType,
) {
  const type = blockchainType ?? BLOCKCHAIN_POLYGON
  console.log('zap get contract helper', blockchainType ?? BLOCKCHAIN_POLYGON)
  function getPolygonContract() {
    switch (contractType) {
      case MULTI_CONTRACT_TYPES:
        return env.POLYGON_NFT_MULTI_ADDRESS

      case SINGLE_CONTRACT_TYPES:
        return env.POLYGON_NFT_ADDRESS

      case MARKETPLACE_CONTRACT_TYPES:
        return env.POLYGON_NFT_MARKETPLACE_ADDRESS

      case PAYMASTER_CONTRACT_TYPES:
        return env.POLYGON_PAYMASTER_ADDRESS

      case BANK_CONTRACT_TYPES:
        return env.POLYGON_BANK_ADDRESS

      case AUCTION_CONTRACT_TYPES:
        return env.POLYGON_NFT_AUCTION_ADDRESS
      case BARTER_CONTRACT_TYPES:
        return env.POLYGON_BARTER_ADDRESS
      default:
        break
    }
  }
  function getBinanceContract() {
    switch (contractType) {
      case MULTI_CONTRACT_TYPES:
        return env.BINANCE_NFT_MULTI_ADDRESS

      case SINGLE_CONTRACT_TYPES:
        return env.BINANCE_NFT_ADDRESS

      case MARKETPLACE_CONTRACT_TYPES:
        return env.BINANCE_NFT_MARKETPLACE_ADDRESS

      case PAYMASTER_CONTRACT_TYPES:
        return env.BINANCE_PAYMASTER_ADDRESS

      case BANK_CONTRACT_TYPES:
        return env.BINANCE_BANK_ADDRESS

      case AUCTION_CONTRACT_TYPES:
        return env.BINANCE_NFT_AUCTION_ADDRESS
      case BARTER_CONTRACT_TYPES:
        return env.BINANCE_BARTER_ADDRESS
      default:
        break
    }
  }
  function getEthereumContract() {
    switch (contractType) {
      case MULTI_CONTRACT_TYPES:
        return env.ETHEREUM_NFT_MULTI_ADDRESS

      case SINGLE_CONTRACT_TYPES:
        return env.ETHEREUM_NFT_ADDRESS

      case MARKETPLACE_CONTRACT_TYPES:
        return env.ETHEREUM_NFT_MARKETPLACE_ADDRESS

      case PAYMASTER_CONTRACT_TYPES:
        return env.ETHEREUM_PAYMASTER_ADDRESS

      case BANK_CONTRACT_TYPES:
        return env.ETHEREUM_BANK_ADDRESS

      case AUCTION_CONTRACT_TYPES:
        return env.ETHEREUM_NFT_AUCTION_ADDRESS
      case BARTER_CONTRACT_TYPES:
        return env.ETHEREUM_BARTER_ADDRESS
      default:
        break
    }
  }
  switch (type) {
    case BLOCKCHAIN_POLYGON:
      return getPolygonContract()
    case BLOCKCHAIN_BINANCE:
      return getBinanceContract()
    case BLOCKCHAIN_ETHERIUM:
      return getEthereumContract()
    default:
      break
  }
}

export const getJsonRpcProvider = blockchainType => {
  const type = blockchainType ?? BLOCKCHAIN_POLYGON
  switch (type) {
    case BLOCKCHAIN_POLYGON:
      return process.env.REACT_APP_PROVIDER_LINK
        ? new ethers.providers.JsonRpcProvider(
            process.env.REACT_APP_PROVIDER_LINK,
          )
        : new ethers.providers.getDefaultProvider()

    case BLOCKCHAIN_ETHERIUM:
      return process.env.REACT_APP_ETH_PROVIDER_LINK
        ? new ethers.providers.JsonRpcProvider(
            process.env.REACT_APP_ETH_PROVIDER_LINK,
          )
        : new ethers.providers.getDefaultProvider()

    case BLOCKCHAIN_BINANCE:
      return process.env.REACT_APP_BSC_PROVIDER_LINK
        ? new ethers.providers.JsonRpcProvider(
            process.env.REACT_APP_BSC_PROVIDER_LINK,
          )
        : new ethers.providers.getDefaultProvider()
    default:
      break
  }
}
