import React from "react";

const PencilIcon = ({viewBox, size, onClick, className, color, ...props}) => {
    return (
        <div onClick={onClick} className={className} {...props}>
           <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
            <path d="M11.9403 8.22002L15.7803 12.06L10.5136 17.3334C10.2688 17.5778 9.95451 17.7408 9.61363 17.8L6.45363 18.3267C6.41612 18.3322 6.37819 18.3345 6.34029 18.3334C6.16238 18.3314 5.99238 18.2595 5.86696 18.1334C5.79106 18.0575 5.73403 17.9649 5.70045 17.863C5.66687 17.7611 5.65768 17.6528 5.67363 17.5467L6.20029 14.3867C6.25932 14.0459 6.42205 13.7316 6.66629 13.4867L11.9403 8.22002ZM17.533 6.46669C17.2816 6.2132 16.9825 6.01199 16.653 5.87469C16.3234 5.73738 15.97 5.66669 15.613 5.66669C15.256 5.66669 14.9025 5.73738 14.573 5.87469C14.2434 6.01199 13.9443 6.2132 13.693 6.46669L12.8863 7.27335L16.7263 11.1134L17.533 10.3067C17.7865 10.0553 17.9877 9.75622 18.125 9.42669C18.2623 9.09715 18.333 8.74368 18.333 8.38669C18.333 8.02969 18.2623 7.67622 18.125 7.34669C17.9877 7.01715 17.7865 6.71806 17.533 6.46669V6.46669Z" fill={color}/>
            </svg>
        </div>
    )
}

PencilIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: '#868686'
};

export default PencilIcon