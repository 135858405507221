import React from "react";

const BinanceIcon = ({viewBox, size, onClick, divClass, color}) => {
    return (
        <div onClick={onClick} className={divClass}>
           <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
                <g clipPath="url(#clip0_11292_3394)">
                <path d="M11.9997 2.04208L14.4669 4.5612L8.25428 10.7553L5.78705 8.29542L11.9997 2.04208Z" fill="#F0B90B" stroke="#F0B90B"/>
                <path d="M15.7451 5.77631L18.2124 8.29542L8.25428 18.2237L5.78705 15.7639L15.7451 5.77631Z" fill="#F0B90B" stroke="#F0B90B"/>
                <path d="M4.50885 9.51053L6.97608 12.0296L4.50885 14.4895L2.04163 12.0296L4.50885 9.51053Z" fill="#F0B90B" stroke="#F0B90B"/>
                <path d="M19.4906 9.51053L21.9578 12.0296L11.9997 21.9579L9.53247 19.4981L19.4906 9.51053Z" fill="#F0B90B" stroke="#F0B90B"/>
                </g>
                <defs>
                <clipPath id="clip0_11292_3394">
                <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
                </clipPath>
                </defs>
            </svg>
        </div>
    )
}

BinanceIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: '#3C3C3B'
};

export default BinanceIcon