import React, { useReducer } from 'react';
import CategoryContext from './categoryContext';
import CategoryReducer from './categoryReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { ALL_CATEGORY,  SET_LOADING } from '../types';
import logger from 'use-reducer-logger';

const CategoryState = props => {
  const initialState = {
    categories: [],
    category: {},
    loading: false
  };

  const [state, dispatch] = useReducer(logger(CategoryReducer), initialState);

  // Search Users
  const getAllCategories = async () => {
    setLoading();
    
    const res = await post("getAllCategories");
    dispatch({
      type: ALL_CATEGORY,
      payload: res.data.data.allCategories.nodes
    });
    return res?.data?.data?.allCategories?.nodes
  };

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <CategoryContext.Provider
      value={{
        categories: state.categories,
        category: state.category,
        loading: state.loading,
        getAllCategories
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};

export default CategoryState;
