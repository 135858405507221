import React, { useReducer } from 'react';
import TxnContext from './txnContext';
import TxnReducer from './txnReducer';
// import { ALL_COLLECTION,SET_LOADING } from '../types';
import { post } from '../../utils/graphql-api';
import { SET_LOADING, GET_TXN_LOG, ADD_TXN_LOG, DELETE_TXN_LOG, UPDATE_TXN_LOG, CANCEL_TXN_LOG_CREATE_ASSET } from '../types';
import logger from 'use-reducer-logger';
import api from '../../utils/api';

const TxnState = props => {
  const initialState = {
    txnPending: [],
    pageInfo: {},
    loadingTxn: false
  };

  const [state, dispatch] = useReducer(logger(TxnReducer), initialState);

  const getTxn = async (prop) => {
    setLoading()
    const res = await post('getTransactionLogs', { ...prop })
    await dispatch({
      type: GET_TXN_LOG,
      payload: res?.data?.data?.allTransactionsLogs
    })
    return res?.data?.data?.allTransactionsLogs
  }

  
  const add = async (input) => {
    setLoading()
    const res = await post('addTransactionLog', { ...input })
    await dispatch({
      type: ADD_TXN_LOG,
      payload: res?.data?.data?.createTransactionsLog?.transactionsLog
    })
    return res?.data?.data?.createTransactionsLog?.transactionsLog
  }
  
  const _delete = async (id) => {
    setLoading()
    const res = await post('deleteTransactionLog', { id})
    await dispatch({
      type: DELETE_TXN_LOG,
      payload: res?.data?.data?.deleteTransactionLog?.transactionsLog
    })
    return res?.data?.data?.deleteTransactionLog?.transactionsLog
  }
  
  const update = async (id,patch) => {
    setLoading()
    const res = await post('updateTranscationLog', { id,patch })
    await dispatch({
      type: UPDATE_TXN_LOG,
      payload: res?.data?.data?.updateTranscationLog?.transactionsLog
    })
    return res?.data?.data?.updateTranscationLog?.transactionsLog
  }

  const cancelTxnCreateAsset = async (txnId, fileCid, metadataCid) => {
    setLoading()
    const res = await api.post('/transactions/cancel_create_asset', {txnId: txnId, fileCid: fileCid, metadataCid: metadataCid })
    await dispatch({
      type: CANCEL_TXN_LOG_CREATE_ASSET,
    })
    return res.data
  } 


  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <TxnContext.Provider
      value={{
        txnPending: state.txnPending,
        loadingTxn: state.loadingTxn,
        pageInfo: state.pageInfo,
        setLoading,
        getTxn,
        delete: _delete,
        update,
        add,
        cancelTxnCreateAsset,
      }}
    >
      {props.children}
    </TxnContext.Provider>
  );
};

export default TxnState;
