const P = ({ fontSize = 'text-lg', children, className }) => {
  return (
    <p
      className={`font-poppins font-normal text-teks_2 mb-6 mt-6 text-center ${fontSize} ${className}`}
    >
      {children}
    </p>
  )
}

export default P
