import React, { useReducer } from 'react';
import ArticleReducer from './articleReducer';
import ArticleContext from './articleContext';
import {post} from '../../utils/graphql-api'
import { GET_ARTICLES, GET_ARTICLE_BY_ID, GET_ARTICLE_CATEGORIES, GET_ARTICLE_CATEGORY_BY_SLUG, GET_SMART_ARTICLES, LOADING_SEARCH_ARTICLE, SET_LOADING } from '../types';
import logger from 'use-reducer-logger';
import api from '../../utils/api';

const ArticleState = props => {
    const initialState = {
        articles: [],
        selected_article: [],
        categories: [],
        selected_category: [],
        loading: false,
        loadingSearch: false,
        topArticle: [],
    }
   
    const [state, dispatch] = useReducer(logger(ArticleReducer), initialState)

    const setLoading = () => dispatch({ type: SET_LOADING });
    const setLoadingSearch = () => dispatch({ type: LOADING_SEARCH_ARTICLE });

    const getSearch = async (indexes, keyword = "") => {
        setLoadingSearch()
        const res = await api.post('/asset/search', { indexes, keyword })
        await dispatch({
          type: GET_SMART_ARTICLES,
          payload: res?.data?.data
        })
        return res?.data?.data
      }


    const getArticles = async () => {
        setLoading();
        const res = await post("getAllArticles");
        await dispatch({
            type: GET_ARTICLES,
            payload: res.data.data.allArticles.nodes,
        })
        return res.data.data.allArticles.nodes
    }

    const getCategories = async () => {
        setLoading();
        const res = await post("getAllArticleCatagories");
        await dispatch({
            type: GET_ARTICLE_CATEGORIES,
            payload: res.data.data.allArticleCategories.nodes,
        })
        return res.data.data.allArticleCategories.nodes
    }

    const getArticleByID = async (id) => {
        setLoading()
        const res = await post("getArticleById", {id})
        await dispatch({
            type: GET_ARTICLE_BY_ID,
            payload: res.data.data.articleById,
        })
        return res.data.data.articleById
    }

    const getCategoryBySlug = async (slug) => {
        setLoading()
        const res = await post("getArticleCategoriesBySlug", {slug})
        await dispatch({
            type: GET_ARTICLE_CATEGORY_BY_SLUG,
            payload: res.data.data.articleCategoryBySlug,
        })
        return res.data.data.articleCategoryBySlug
    }

    return (
        <ArticleContext.Provider 
        value={{
            articles: state.articles,
            selected_article: state.selected_article,
            selected_category: state.selected_category,
            categories: state.categories,
            loading: state.loading,
            loadingSearch: state.loadingSearch,
            topArticle: state.topArticle,
            setLoadingSearch,
            setLoading,
            getArticles,
            getCategories,
            getArticleByID,
            getCategoryBySlug,
            getSearch,
        }}>
            {props.children}
        </ArticleContext.Provider>
    )
}

export default ArticleState