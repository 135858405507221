import {
  ALL_DISBURSMENT,
  CREATE_DISBURSMENT,
  REQUEST_DISBURSMENT,
  SET_LOADING,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case ALL_DISBURSMENT:
      return {
        ...state,
        disbursments: action.payload,
        loading: false
      };
    case CREATE_DISBURSMENT:
      return {
        ...state,
        disbursment: action.payload,
        loading: false
      };
    case REQUEST_DISBURSMENT:
      return {
        ...state,
        disbursment: action.payload,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default reducer
