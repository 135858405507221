import {
  RESET_PARAM_BARTER,
    SET_LOADING,
    SET_PARAM_BARTER,
  } from '../types';
  
  const reducer = (state, action) => {
    switch (action.type) {
      case SET_PARAM_BARTER:
        let param
        if(action?.step === 1){
          param = {...state.barterParameter, assetSelected: action.payload}
        } else {
          param = {...state.barterParameter, assetBarter: action.payload}
        }
        return {
          ...state,
          barterParameter: param,
          loading: false
        }
      case RESET_PARAM_BARTER:
        return {
          ...state,
          barterParameter: {assetSelected: null, assetBarter: null},
          loading: false
        }
      case SET_LOADING:
        return {
          ...state,
          loading: true
        };
      default:
        return state;
    }
  };
  
  export default reducer
  