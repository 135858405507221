import React from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowLeftIcon from '../../assets/icon/arrow_left_icon'
import ChevronRightIcon from '../../assets/icon/checvron_right_icon'
import { navSidebar, navTxn } from '../../router/_nav_sidebar'

const SubMenu = ({ showSubMenu, setShowSubMenu, menuParam, setShowMenu }) => {
  let data
  const toSetting = '/setting/'
  const toTxn = '/history-txn/'
  const navigate = useNavigate()
  switch (menuParam) {
    case 0:
      data = [{}]
      break
    case 1:
      data = navSidebar
      break
    case 2:
      data = navTxn
      break
    default:
      break
  }
  return (
    <div
      className={`transform ease-in-out duration-500 md:hidden flex flex-col bg-white h-full w-full top-0 fixed left-0 z-30 overflow-scroll  ${
        showSubMenu ? 'translate-x-0 ' : '-translate-x-full'
      }`}
    >
      <div className="grid grid-cols-3 justify-center bg-white items-center px-4 pt-5 pb-2">
        <ArrowLeftIcon onClick={setShowSubMenu} className="mr-auto" />
        <p className="text-center font-poppins font-semibold text-base text-black">
          {menuParam === 1 ? 'Pengaturan' : 'Transaksi'}
        </p>
      </div>
      {/* {navSidebar?.content?.map(({name, url}, idx) =>  <div key={idx} onClick={()=> {setShowMenu(false); navigate(`/setting/${url}`)}} className="flex px-4 py-4 border-b justify-between items-center"> */}
      {data?.map(({ title, content }, idx) => (
        <div
          key={idx}
          className="flex w-full flex-col px-4 py-4 justify-between"
        >
          <p className="font-poppins font-semibold text-base text-teks mb-2">
            {title}
          </p>
          {content?.map(({ name, url }, idx) => (
            <div
              key={idx}
              className="flex justify-between items-center w-full py-3 border-b pl-3"
              onClick={() => {
                setShowMenu(false)
                navigate((menuParam === 1 ? toSetting : toTxn) + url)
              }}
            >
              <p className="text-base text-teks font-poppins">{name}</p>
              <ChevronRightIcon color="#868686" />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default SubMenu
