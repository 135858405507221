import { useReducer, useEffect } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import { LOGIN, LOGOUT, SET_LOADING, GA_TOKEN, SET_CONNECT_WALLET, SET_SESSION_LOGIN, SET_METAMASK_LOADING, SET_CHANGE_ACCOUNT, SET_WEB3_CONNECTION, SET_CHANGE_NETWORK, SET_WRONG_ACCOUNT, SET_IS_AT_CREATE_ASSET, GENERATE_AUTHORIZATION_CODE, SET_CHANGE_NETWORK_WITH_BLOCKCHAIN_TYPE } from '../types';
import logger from 'use-reducer-logger';
import api from '../../utils/api';
import useLocalStorage from '../../utils/hooks/useLocalStorage';
// import ModalConnectWallet from '../../component/Modal/ModalConnectWallet';
// import ModalChangeAccount from '../../component/Modal/ModalChangeAccount';
// import ModalChangeNetwork from '../../component/Modal/ModalChangeNetwork';
// import ModalLogin from '../../component/Modal/ModalLogin';
const AuthState = props => {

  const [auth, setAuth] = useLocalStorage("auth")
  const [atga, setAtGa] = useLocalStorage("atga")

  const initialState = {
    auth: auth ?? {},
    loading: false,
    atga: atga ?? {},
    connWallet: false,
    session: false,
    metamaskLoading: false,
    changeAccount: false,
    web3Connection: null,
    changeNetwork: false,
    blockchainType: "",
    wrongAccount: false,
    isAtCreateAsset: false,
    authorizationCode:"",
  };
  const [state, dispatch] = useReducer(logger(AuthReducer), initialState);

  const getCurrentVersion = async()=>{
    const res = await api.get('web_version');
    return res.data
  }

  const generateAuthorizationCode = async ()=> {
    setLoading();
    const res = await api.post('redirect_auth/authorization', {}, { withCredentials: true })
    await dispatch({
      type: GENERATE_AUTHORIZATION_CODE,
      payload: res.data
    })
    return res.data
  }

  // Login Users
  const login = async (inputLogin) => {
    setLoading();
    const res = await api.post("login", { ...inputLogin }, {withCredentials: true});
    await dispatch({
      type: LOGIN,
      payload: res.data
    });
    setAuth(res.data)
    return res.data
  };

  // Logout Users
  const logout = async (token) => {
    setLoading();

    setAuth({})
    const res = await api.post("logout", {}, {withCredentials: true});
    await dispatch({
      type: LOGOUT,
    });
    return res.data
  };

  const refreshToken = async () => {
    setLoading()
    const res = await api.post("token", {}, { withCredentials: true })
    await dispatch({
      type: LOGIN,
      payload: res.data
    })
    setAuth(res.data)
    return res.data
  }

  const gaToken = async () => {
    setLoading()
    const res = await api.get("gatoken", {}, {withCredentials: true});
    setAtGa(res.data)
    dispatch({
      type: GA_TOKEN,
      payload: res.data
    })
    return res.data
  }

  const setConnWallet = (bool) => {
    setLoading()
    dispatch({
      type: SET_CONNECT_WALLET,
      payload: bool
    })
  }

  const setSession = (bool) => {
    setLoading()
    dispatch({
      type: SET_SESSION_LOGIN,
      payload: bool
    })
  }

  const setMetamaskLoading = (bool) => {
    setLoading()
    dispatch({
      type: SET_METAMASK_LOADING,
      payload: bool
    })
  }

  const setChangeAccount = (bool) => {
    setLoading()
    dispatch({
      type: SET_CHANGE_ACCOUNT,
      payload: bool
    })
  }

  const setWeb3Connection = (connection) => {
    setLoading()
    dispatch({
      type: SET_WEB3_CONNECTION,
      payload: connection
    })
  }

  const setChangeNetwork = (bool, blockchainType) => {
    setLoading()
    if(blockchainType){
      const data = {
        bool,
        blockchainType
      }
      dispatch({
        type: SET_CHANGE_NETWORK_WITH_BLOCKCHAIN_TYPE,
        payload: data
      })
    } else{
      dispatch({
        type: SET_CHANGE_NETWORK,
        payload: bool
      })
    }
  }

  const setWrongAccount = (bool) => {
    setLoading()
    dispatch({
      type: SET_WRONG_ACCOUNT,
      payload: bool
    })
  }

  const setIsAtCreateAsset = (bool) => {
    setLoading()
    dispatch({
      type: SET_IS_AT_CREATE_ASSET,
      payload: bool
    })
  }

  useEffect(() => {
    if(window.localStorage.getItem("atga") !== undefined) {
      gaToken()
    }
    setInterval(() => {
      gaToken()
    }, 900000) //15 menit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        refreshToken,
        gaToken, 
        setMetamaskLoading,
        setConnWallet,
        setSession,
        setChangeAccount,
        setWeb3Connection,
        setChangeNetwork,
        setWrongAccount,
        setIsAtCreateAsset,
        generateAuthorizationCode,
        getCurrentVersion,
        auth: state.auth,
        atga: state.atga,
        session: state.session,
        connWallet: state.connWallet,
        metamaskLoading: state.metamaskLoading,
        changeAccount: state.changeAccount,
        web3Connection: state.web3Connection,
        changeNetwork: state.changeNetwork,
        wrongAccount: state.wrongAccount,
        isAtCreateAsset: state.isAtCreateAsset,
        authorizationCode: state.authorizationCode,
      }}
    >
      {props.children}
      {/* <ModalConnectWallet backdrop={false} show={state.connWallet} setShow={setConnWallet} />
      <ModalChangeNetwork backdrop={false} show={state.changeNetwork} setShow={setChangeNetwork} />
      <ModalChangeAccount backdrop={false} show={state.changeAccount} />
      <ModalLogin backdrop={false} show={state.metamaskLoading} /> */}
    </AuthContext.Provider>
  );
};

export default AuthState;
