import classNames from 'classnames'
import { useMemo } from 'react'

type ButtonComponentProp = {
  size: 'sm' | 'md' | 'lg'
  type: 'normal' | 'ghost' | 'ghost-bordered'
  color: 'primary' | 'secondary' | 'terniary' | 'red' | 'gray'
  customWidth?: 40 | 48 | 64
  handleClick?: () => void
  fillContainer?: boolean
  disabled?: boolean
  text?: string
  loading?: boolean
}

const ButtonComponent: React.FC<ButtonComponentProp> = ({
  size,
  type,
  handleClick,
  color,
  fillContainer,
  disabled,
  text,
  customWidth,
  loading,
}) => {
  const renderSize = classNames(
    { 'h-12 text-sm': size === 'sm' },
    { 'h-14 text-base': size === 'md' },
    { 'h-16 text-lg': size === 'lg' },
  )
  const normalPrimary = color === 'primary' && type === 'normal'
  const normalSecondary = color === 'secondary' && type === 'normal'
  const normalTerniary = color === 'terniary' && type === 'normal'
  const normalRed = color === 'red' && type === 'normal'
  const normalGray = color === 'gray' && type === 'normal'
  const ghostPrimary =
    color === 'primary' && (type === 'ghost' || type === 'ghost-bordered')
  const ghostSecondary =
    color === 'secondary' && (type === 'ghost' || type === 'ghost-bordered')
  const ghostTerniary =
    color === 'terniary' && (type === 'ghost' || type === 'ghost-bordered')
  const ghostRed =
    color === 'red' && (type === 'ghost' || type === 'ghost-bordered')
  const ghostGray =
    color === 'gray' && (type === 'ghost' || type === 'ghost-bordered')

  const renderColor = classNames(
    {
      'bg-green_theme_500': normalPrimary,
    },
    {
      'bg-blue_theme_500': normalSecondary,
    },
    {
      'bg-orange_theme_500': normalTerniary,
    },
    {
      'bg-red_theme_500': normalRed,
    },
    {
      'bg-teks_secondary': normalGray,
    },
    {
      'bg-green_theme_50': ghostPrimary,
    },
    {
      'bg-blue_theme_50': ghostSecondary,
    },
    {
      'bg-orange_theme_50': ghostTerniary,
    },
    {
      'bg-red_theme_50': ghostRed,
    },
    {
      'bg-divider': ghostGray,
    },
  )

  const renderDisabled = classNames(
    { 'opacity-60 cursor-default': disabled },
    {
      'opacity-100 cursor-pointer active:scale-90 transform transition-transform duration-800':
        !disabled,
    },
  )

  const renderHover = classNames(
    {
      'hover:bg-green_theme_900': normalPrimary && !disabled,
    },
    {
      'hover:bg-blue_theme_900': normalSecondary && !disabled,
    },
    {
      'hover:bg-orange_theme_900': normalTerniary && !disabled,
    },
    {
      'hover:bg-red_theme_900': normalRed && !disabled,
    },
    {
      'hover:bg-gray-600': normalGray && !disabled,
    },
    {
      'hover:bg-green_theme_100': ghostPrimary && !disabled,
    },
    {
      'hover:bg-blue_theme_100': ghostSecondary && !disabled,
    },
    {
      'hover:bg-orange_theme_100': ghostTerniary && !disabled,
    },
    {
      'hover:bg-red_theme_100': ghostRed && !disabled,
    },
    {
      'hover:bg-gray-200': ghostGray && !disabled,
    },
  )

  const renderTextColor = classNames(
    { 'text-white': type === 'normal' },
    {
      'text-green_theme_500':
        (type === 'ghost' || type === 'ghost-bordered') && color === 'primary',
    },
    {
      'text-blue_theme_500':
        (type === 'ghost' || type === 'ghost-bordered') &&
        color === 'secondary',
    },
    {
      'text-orange_theme_500':
        (type === 'ghost' || type === 'ghost-bordered') && color === 'terniary',
    },
    {
      'text-red_theme_500':
        (type === 'ghost' || type === 'ghost-bordered') && color === 'red',
    },
    {
      'text-teks_secondary':
        (type === 'ghost' || type === 'ghost-bordered') && color === 'gray',
    },
  )

  const renderBorder = classNames(
    {
      'border border-green_theme_500':
        color === 'primary' && type === 'ghost-bordered',
    },
    {
      'border border-blue_theme_500':
        color === 'secondary' && type === 'ghost-bordered',
    },
    {
      'border border_orange_theme_500':
        color === 'terniary' && type === 'ghost-bordered',
    },
    {
      'border border-red_theme_500':
        color === 'red' && type === 'ghost-bordered',
    },
  )

  const renderWidth = useMemo(() => {
    return customWidth
      ? `w-${customWidth.toString()}`
      : fillContainer
      ? 'w-full'
      : 'w-auto px-6'
  }, [customWidth, fillContainer])

  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={disabled}
      className={`flex rounded-xl items-center justify-center ${renderColor} ${renderWidth} ${renderSize} ${renderDisabled} ${renderBorder} ${renderHover}`}
    >
      <p className={`font-poppins font-bold tracking-wide ${renderTextColor}`}>
        {loading ? 'Loading...' : text}
      </p>
    </button>
  )
}

ButtonComponent.defaultProps = {
  handleClick: () => {},
  fillContainer: false,
  type: 'normal',
  color: 'primary',
  size: 'md',
  disabled: false,
  text: 'Button Text',
  customWidth: undefined,
  loading: false,
}

export default ButtonComponent
