import { BankBniLogo, BankBriLogo, BankMandiriLogo, BankPermataLogo, BankSampoernaLogo, WalletDanaLogo, WalletLinkajaLogo, WalletOvoLogo, WalletQrisLogo, WalletShopeePayLogo } from "../../assets"
import env from "../../config/env"
/* export const BCA = {
    logo: <img src={BankBcaLogo} className="w-14 h-4" />,
    value: "BCA",
    admin_fee: (price) => (4500 * 10) / 100 + 4500,
    maximumLimit: 100000000,
} */

export const SAMPOERNA = {
    logo: <img alt="SampoernaSampoerna " src={BankSampoernaLogo} className="w-32 h-8" />,
    value: "SAMPOERNA",
    admin_fee: (price) => (4500 * env.PPN) / 100 + 4500,
    maximumLimit: 100000000,
}

export const PERMATA = {
    logo: <img alt="PermataPermata " src={BankPermataLogo} className="w-32 h-8" />,
    value: "PERMATA",
    admin_fee: (price) => (4500 * env.PPN) / 100 + 4500,
    maximumLimit: 100000000,
}

export const BRI = {
    logo: <img alt="BriBri " src={BankBriLogo} className="w-20 h-6" />,
    value: "BRI",
    admin_fee: (price) => (4500 * env.PPN) / 100 + 4500,
    maximumLimit: 100000000,
}

export const BNI = {
    logo: <img alt="BniBni " src={BankBniLogo} className="w-16 h-6" />,
    value: "BNI",
    admin_fee: (price) => (4500 * env.PPN) / 100 + 4500,
    maximumLimit: 100000000,
}

export const MANDIRI = {
    logo: <img alt="MandiriMandiri " src={BankMandiriLogo} className="w-24 h-6" />,
    value: "MANDIRI",
    admin_fee: (price) => (4500 * env.PPN) / 100 + 4500,
    maximumLimit: 100000000,
}

export const LINKAJA = {
    logo: <img alt="Wallet Linkaja" src={WalletLinkajaLogo} className="w-10 h-6" />,
    value: "LINKAJA",
    admin_fee: (price) => {
        return (price * 1.5) / 100 + (((price * 1.5) / 100) * env.PPN) / 100;
    },
    maximumLimit: 2000000,
}

export const OVO = {
    logo: <img alt="Wallet Ovo" src={WalletOvoLogo} className="w-14 h-4" />,
    value: "OVO",
    admin_fee: (price) => {
        return (price * 1.5) / 100 + (((price * 1.5) / 100) * env.PPN) / 100;
    },
    maximumLimit: 2000000,
}

export const SHOPEEPAY = {
    logo: <img alt="Wallet Shopee" src={WalletShopeePayLogo} className="h-8" />,
    value: "SHOPEEPAY",
    admin_fee: (price) => {
        return (price * 1.5) / 100 + (((price * 1.5) / 100) * env.PPN) / 100;
    },
    maximumLimit: 2000000,
}

export const DANA = {
    logo: <img alt="Wallet Dana" src={WalletDanaLogo} className="h-8" />,
    value: "DANA",
    admin_fee: (price) => {
        return (price * 1.5) / 100 + (((price * 1.5) / 100) * env.PPN) / 100;
    },
    maximumLimit: 2000000,
}

export const QRIS = {
    logo: <img alt="Wallet Qris" src={WalletQrisLogo} className="h-6" />,
    value: "QRIS",
    admin_fee: (price) => {
        return (price * 0.636) / 100 + (((price * 0.636) / 100) * env.PPN) / 100;
    },
    maximumLimit: 5000000,
}
