import React from 'react'
import classNames from 'classnames'

const RoundedButton = ({
  children,
  className,
  color = 'bg-hijau_hutan',
  ...props
}) => {
  return (
    <button
      type="button"
      {...props}
      className={`${classNames({
        'py-6': !className?.includes('py'),
        'px-3': !className?.includes('px'),
      })}
        rounded-xl ${color}
        ${className}`}
    >
      {children}
    </button>
  )
}

export default React.memo(RoundedButton)
