import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { MagnifierLogoImg, UserImage } from '../../assets'
import filterContext from '../../context/Filter/filterContext'
import Input from './Input'
import { isEmpty } from 'lodash'
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom'
import SpinCircleLogo from '../../assets/animation/spin_circle_logo'
import { SMALL_THUMBNAIL_IMAGE_TYPE } from '../../utils/constants/renderImgTypes'
import Chips from '../Chips/Chips'
import CloseIcon from '../../assets/icon/close_icon'
import { useClickOutside } from '../../hooks/useClickOutside'
import Img from 'react-cool-img'
import { LoadingSpinner } from '../../assets/animation'

const TOP_COUNT = 5
const TYPING_DELAY = 1000
const InputSmartSearch = ({ setShowMenuMobile }) => {
  const ref = useRef()
  const [searchParams, setSearchParams] = useSearchParams()
  const FilterContext = useContext(filterContext)
  const [search, setSearchs] = useState(FilterContext?.searchAsset)
  const [show, setShow] = useState(false)
  // const [activeTag, setActiveTag] = useState(false)
  const [topTags, setTopTags] = useState([])
  const navigate = useNavigate()

  const handleClearTagBtn = () => {
    // setActiveTag(false)
    searchParams.delete('tag')
    setSearchParams(searchParams)
    FilterContext?.setSearchTag('')
    setShow(false)
  }

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      let input = {}
      if (search) {
        input = [
          {
            index: 'assets',
            limit: TOP_COUNT,
          },
          {
            index: 'collections',
            limit: TOP_COUNT,
          },
          {
            index: 'tags',
            limit: TOP_COUNT,
          },
          {
            index: 'accounts',
            limit: TOP_COUNT,
          },
        ]
        setShow(true)
      } else {
        input = [
          {
            index: 'top_tags',
          },
        ]
      }
      // implement elastic search
      const { top_tags } = await FilterContext?.getSearch(input, search)
      setTopTags(top_tags)
    }, TYPING_DELAY)
    return () => clearTimeout(timeOutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useClickOutside(ref, show, setShow)

  const handleInput = useCallback(e => {
    setSearchs(e.target.value)
  }, [])

  const handleOnFocus = useCallback(() => {
    setShow(true)
  }, [])

  const renderPlaceHolder = useMemo(() => {
    if (searchParams.get('tag') !== null) {
      return ''
    } else {
      return 'Jelajahi NFT Saya'
    }
  }, [searchParams])

  const handleClickTag = useCallback(
    async tag => {
      const isSearch = Object.keys(tag).includes('title')
      // setActiveTag(true)
      await FilterContext?.setSearchAsset('')
      await FilterContext?.setSearchTag(isSearch ? tag.title : tag?.key)
      setShow(false)
      setShowMenuMobile(false)
      setSearchs('')
      navigate(
        {
          pathname: '/assets',
          search: `${createSearchParams({
            tag: isSearch ? tag.title : tag?.key,
          })}`,
        },
        { replace: true },
      )
    },
    [FilterContext, navigate, setShowMenuMobile],
  )

  const handleClickAsset = useCallback(
    asset => {
      // setActiveTag(false)
      navigate(`/asset/${asset?.id}`)
      setShowMenuMobile(false)
      setShow(false)
      setSearchs('')
    },
    [navigate, setShowMenuMobile],
  )

  const handleClickCollection = useCallback(
    collection => {
      // setActiveTag(false)
      navigate(`/collection/${collection?.id}`)
      setShowMenuMobile(false)
      setShow(false)
      setSearchs('')
    },
    [navigate, setShowMenuMobile],
  )

  const handleClickAccount = useCallback(
    account => {
      // setActiveTag(false)
      navigate(`/account/${account?.id}`)
      setShowMenuMobile(false)
      setShow(false)
      setSearchs('')
    },
    [navigate, setShowMenuMobile],
  )

  const handleKeyDown = useCallback(
    async e => {
      if (e.keyCode === 13) {
        e.preventDefault()
        e.stopPropagation()
        await FilterContext?.setSearchAsset(search)
        await FilterContext?.setSearchTag('')
        navigate('/assets')
      }
    },
    [FilterContext, navigate, search],
  )

  const renderTextChips = useMemo(() => {
    return searchParams?.get('tag')?.length > 15
      ? `${searchParams?.get('tag').substring(0, 10)}...`
      : searchParams?.get('tag')
  }, [searchParams])

  return (
    <div ref={ref} className="relative">
      <Input
        value={search}
        disabled={searchParams.get('tag') !== null}
        placeholder={renderPlaceHolder}
        customPositionIcon="md:top-4 top-3 left-4"
        appendleft={<MagnifierLogoImg />}
        className="md:h-12 h-10 font-poppins md:text-base text-sm"
        customPaddingInput={'pl-10'}
        onFocus={handleOnFocus}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
      />
      <div
        className={`absolute top-1 left-10 ${
          searchParams.get('tag') === null && 'hidden'
        }`}
      >
        <Chips
          text={renderTextChips}
          handleClick={handleClearTagBtn}
          rightIcon={<CloseIcon size={4} color="#00AA5B" />}
          customPadding="px-4"
          selected={true}
        />
      </div>
      <ul
        className={`absolute ${
          !show && 'hidden'
        } md:w-filter_container w-11/12 left-0 bg-white border-gray-200 border-2 overflow-y-auto max-h-96 rounded-lg z-30 text-teks pt-1`}
      >
        {FilterContext?.loading && (
          <li className="flex flex-row items-center justify-center bg-white">
            <p className="rounded-t mr-1 bg-white py-2 px-4 cursor-pointer block whitespace-no-wrap font-poppins">
              Mencari Data
            </p>
            <SpinCircleLogo />
          </li>
        )}
        {!search && (
          <li
            className="justify-between flex flex-row bg-white"
            key={'labelTags'}
          >
            <p className="rounded-t bg-white py-2 px-4 block font-semibold font-poppins whitespace-no-wrap">
              Tag terpopuler
            </p>
          </li>
        )}
        {!search && (
          <ul className="inline-flex flex-wrap px-4 bg-white w-full">
            {topTags?.map((tag, i) => (
              <li
                className={`flex flex-row items-center cursor-pointer mr-2 mb-4 rounded ${
                  searchParams.get('tag') === tag?.key
                    ? 'bg-gray-400'
                    : 'bg-gray-100'
                } px-4 justify-start hover:bg-gray-100`}
                key={i + 'tag'}
                onClick={() => handleClickTag(tag)}
              >
                <p className="rounded-t py-2 block font-poppins font-normal text-teks whitespace-no-wrap">
                  {tag?.key}
                </p>
              </li>
            ))}
          </ul>
        )}
        {!isEmpty(FilterContext?.tags) && search && (
          <li key={'labelTags'}>
            <p className="rounded-t bg-white py-2 px-4 block font-semibold whitespace-no-wrap font-poppins">
              Tag
            </p>
          </li>
        )}

        {search && (
          <ul className="inline-flex flex-wrap px-4 bg-white w-full">
            {FilterContext?.tags?.map((tag, i) => {
              return (
                <li
                  className={`flex flex-row items-center cursor-pointer mr-2 rounded mb-4 ${
                    searchParams.get('tag') === tag?.title
                      ? 'bg-gray-400'
                      : 'bg-gray-100'
                  }  px-4 justify-start hover:bg-gray-100`}
                  key={i + 'tag'}
                  onClick={() => handleClickTag(tag)}
                >
                  <p className="rounded-t py-2 block whitespace-no-wrap">
                    {tag?.title}
                  </p>
                </li>
              )
            })}
          </ul>
        )}
        {!isEmpty(FilterContext?.topAssets) && search && (
          <li key={'labelAssets'}>
            <p className="rounded-t bg-white font-poppins font-semibold py-2 px-4 block whitespace-no-wrap">
              Nama Aset
            </p>
          </li>
        )}
        {search &&
          FilterContext?.topAssets?.map((asset, i) => (
            <li
              className="flex flex-row items-center cursor-pointer px-4 justify-start hover:bg-gray-100 bg-white"
              key={i + 'assets'}
              onClick={() => handleClickAsset(asset)}
            >
              <Img
                alt="asset"
                src={
                  asset?.thumbnail_url
                    ? asset?.thumbnail_url + SMALL_THUMBNAIL_IMAGE_TYPE
                    : UserImage
                }
                className="h-5 w-5 mr-2 rounded-full border boderd-divider"
                error={UserImage}
                placeholder={LoadingSpinner}
              />
              <p className="rounded-t py-2 block whitespace-no-wrap font-poppins text-teks">
                {asset?.name}
              </p>
            </li>
          ))}
        {!isEmpty(FilterContext?.topCollections) && search && (
          <li key={'labelCollections'}>
            <p className="rounded-t bg-white py-2 px-4 block whitespace-no-wrap font-poppins font-semibold text-teks">
              Nama Koleksi
            </p>
          </li>
        )}
        {search &&
          FilterContext?.topCollections?.map((collection, i) => (
            <li
              className="flex flex-row items-center cursor-pointer  px-4 justify-start hover:bg-gray-100 bg-white"
              key={i + 'collection'}
              onClick={() => handleClickCollection(collection)}
            >
              <Img
                alt="collection"
                src={
                  collection?.logo_url
                    ? collection?.logo_url + SMALL_THUMBNAIL_IMAGE_TYPE
                    : UserImage
                }
                error={UserImage}
                placeholder={LoadingSpinner}
                className="h-5 w-5 mr-2 rounded-full"
              />
              <p className="rounded-t  py-2 block whitespace-no-wrap">
                {collection?.name}
              </p>
            </li>
          ))}
        {!isEmpty(FilterContext?.topAccounts) && search && (
          <li key={'labelAccount'}>
            <p className="rounded-t bg-white py-2 px-4 block font-semibold whitespace-no-wrap">
              Nama Akun
            </p>
          </li>
        )}
        {search &&
          FilterContext?.topAccounts?.map((account, i) => (
            <li
              className="flex flex-row items-center cursor-pointer  px-4 justify-start hover:bg-gray-100 bg-white"
              key={i + 'account'}
              onClick={() => handleClickAccount(account)}
            >
              <Img
                alt="account profile"
                src={
                  account?.profile_picture_url
                    ? account?.profile_picture_url + SMALL_THUMBNAIL_IMAGE_TYPE
                    : UserImage
                }
                error={UserImage}
                placeholder={LoadingSpinner}
                className="h-5 w-5 mr-2 rounded-full"
              />
              <p className="rounded-t py-2 block whitespace-no-wrap">
                {account?.user_name}
              </p>
            </li>
          ))}
      </ul>
    </div>
  )
}

InputSmartSearch.defaultProps = {
  setShowMenuMobile: () => {},
}

export default InputSmartSearch
