import React from "react";

const WireframeIcon = ({ fill = "#C6C6C6", width = "33", height = "27", ...props }) =>
    <div {...props}>
        <svg {...props} width={width} height={height} viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.5 5.99988C19.1022 5.99988 18.7206 6.15791 18.4393 6.43922C18.158 6.72052 18 7.10205 18 7.49988V19.4999C18 19.8977 18.158 20.2792 18.4393 20.5605C18.7206 20.8418 19.1022 20.9999 19.5 20.9999H25.5C25.8978 20.9999 26.2794 20.8418 26.5607 20.5605C26.842 20.2792 27 19.8977 27 19.4999V7.49988C27 7.10205 26.842 6.72052 26.5607 6.43922C26.2794 6.15791 25.8978 5.99988 25.5 5.99988H19.5ZM24 8.99988H21V17.9999H24V8.99988Z" fill={fill} />
            <path d="M7.5 5.99988C7.10218 5.99988 6.72064 6.15791 6.43934 6.43922C6.15804 6.72052 6 7.10205 6 7.49988C6 7.8977 6.15804 8.27923 6.43934 8.56054C6.72064 8.84184 7.10218 8.99988 7.5 8.99988H13.5C13.8978 8.99988 14.2794 8.84184 14.5607 8.56054C14.842 8.27923 15 7.8977 15 7.49988C15 7.10205 14.842 6.72052 14.5607 6.43922C14.2794 6.15791 13.8978 5.99988 13.5 5.99988H7.5Z" fill={fill} />
            <path d="M7.5 12.0001C7.10218 12.0001 6.72064 12.1582 6.43934 12.4395C6.15804 12.7208 6 13.1023 6 13.5001C6 13.8979 6.15804 14.2795 6.43934 14.5608C6.72064 14.8421 7.10218 15.0001 7.5 15.0001H13.5C13.8978 15.0001 14.2794 14.8421 14.5607 14.5608C14.842 14.2795 15 13.8979 15 13.5001C15 13.1023 14.842 12.7208 14.5607 12.4395C14.2794 12.1582 13.8978 12.0001 13.5 12.0001H7.5Z" fill={fill} />
            <path d="M6 19.5C6 19.1022 6.15804 18.7206 6.43934 18.4393C6.72064 18.158 7.10218 18 7.5 18H13.5C13.8978 18 14.2794 18.158 14.5607 18.4393C14.842 18.7206 15 19.1022 15 19.5C15 19.8978 14.842 20.2794 14.5607 20.5607C14.2794 20.842 13.8978 21 13.5 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 0C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5V22.5C0 23.6935 0.474106 24.8381 1.31802 25.682C2.16193 26.5259 3.30653 27 4.5 27H28.5C29.6935 27 30.8381 26.5259 31.682 25.682C32.5259 24.8381 33 23.6935 33 22.5V4.5C33 3.30653 32.5259 2.16193 31.682 1.31802C30.8381 0.474106 29.6935 0 28.5 0H4.5ZM28.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V22.5C3 22.8978 3.15804 23.2794 3.43934 23.5607C3.72064 23.842 4.10218 24 4.5 24H28.5C28.8978 24 29.2794 23.842 29.5607 23.5607C29.842 23.2794 30 22.8978 30 22.5V4.5C30 4.10218 29.842 3.72064 29.5607 3.43934C29.2794 3.15804 28.8978 3 28.5 3Z" fill={fill} />
        </svg>

    </div>

export default WireframeIcon