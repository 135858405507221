import {
  ALL_NOTIFICATION,
  CREATE_NOTIFICATION,
  SET_LOADING,
  UNREAD_NOTIFICATION,
} from '../types'

const reducer = (state, action) => {
  switch (action.type) {
    case ALL_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload.nodes,
        pageInfo: action.payload.pageInfo,
        loading: false,
      }

    case CREATE_NOTIFICATION:
      return {
        ...state,
        notification: action.payload.nodes,
        loading: false,
      }
    case UNREAD_NOTIFICATION:
      return {
        ...state,
        totalCount: action.payload?.total,
        loading: false,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}

export default reducer
