import classNames from 'classnames';
import React from 'react';

const Card = React.forwardRef((props, ref) => {
  const { children, width, height, className, style, key } = props;
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        ...style
      }}
      ref={ref}
      className={`${classNames({
        rounded: !className?.includes('rounded'),
        'bg-gray-200': !className?.includes('bg'),
        'py-2': !className?.includes('py'),
        'px-3': !className?.includes('px'),
        'shadow-xl': !className?.includes('shadow'),
        'text-gray-800': !className?.includes('text')
      })}${className}`}
      key={key}
      {...props}>
      {children}
    </div>
  );
});

export default React.memo(Card);
