import Modal from "./Modal";
import CardModal from "../Card/CardModal";
import Cookies from "js-cookie";
import ModalTitleText from "../Text/ModalTitleText";
// import SpinCircleLogo from "../../assets/animation/spin_circle_logo"
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import ResponsiveContext from "../../context/Responsive/responsiveContext";
// import RoundedButton from "../Button/RoundedButton";
// import ButtonText from "../Text/ButtonText";
import ButtonModal from "./ButtonModal";
import P from './P';
import ExclamationIcon from "../../assets/icon/exclamation_icon";
import web3ModalContext from "../../context/Web3Modal/web3ModalContext";
import authContext from "../../context/Auth/authContext";
import { renderUserLabel } from "../../utils/helper";

const ModalWrongAccount = (props) => {

  const navigate = useNavigate()
  const responsive = useContext(ResponsiveContext)
  const { web3Modal, injectedProvider } = useContext(web3ModalContext)
  const { logout, setSession } = useContext(authContext)

  const logoutOfWeb3Modal = async () => {
    navigate('/')
    await web3Modal.clearCachedProvider();
    if (
      injectedProvider &&
      injectedProvider.provider &&
      typeof injectedProvider.provider.disconnect == "function"
    ) {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const handleLogout = async () => {
    await logout();
    Cookies.remove("web3login");
    setSession(false)
    await logoutOfWeb3Modal();
    props.setShow(false)
  }

  return (
    <Modal responsive={responsive} {...props}   >
      <CardModal>
        <ModalTitleText text="Salah Akun" />
        <ExclamationIcon width="100" height="100" />
        <P>
        {
          `
          Kami mendetekasi akun login berbeda dengan akun yang aktif di metamask,
          kembalikan pada account yang benar (${renderUserLabel(null, JSON.parse(localStorage.getItem("auth"))?.user?.address)}) atau silahkan logout terlebih dahulu
          `
        }
        </P>
        <ButtonModal onClick={handleLogout} tx="Logout" />
      </CardModal>
    </Modal>
  );
};

export default ModalWrongAccount;
