import React from "react";

const EthereumIcon = ({viewBox, size, onClick, divClass, color}) => {
    return (
        <div onClick={onClick} className={divClass}>
           <svg viewBox={`0 0 ${viewBox} ${viewBox}`} className={`h-${size} w-${size} fill-current`}>
                <path d="M11.9983 2L11.8641 2.45577V15.6801L11.9983 15.8139L18.1367 12.1854L11.9983 2Z" fill="#343434"/>
                <path d="M11.9982 2L5.85962 12.1854L11.9982 15.8139V9.39522V2Z" fill="#8C8C8C"/>
                <path d="M11.9982 16.9761L11.9226 17.0683V21.779L11.9982 21.9998L18.1404 13.3495L11.9982 16.9761Z" fill="#3C3C3B"/>
                <path d="M11.9982 21.9998V16.9761L5.85962 13.3495L11.9982 21.9998Z" fill="#8C8C8C"/>
                <path d="M11.9982 15.8139L18.1367 12.1854L11.9982 9.39523V15.8139Z" fill="#141414"/>
                <path d="M5.85962 12.1854L11.9982 15.8139V9.39523L5.85962 12.1854Z" fill="#393939"/>
            </svg>
        </div>
    )
}

EthereumIcon.defaultProps = {
    viewBox: 24,
    size: 6,
    onClick: ()=>{},
    color: '#3C3C3B'
};

export default EthereumIcon