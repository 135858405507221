
import React from "react";

const MarketPlaceIcon = (props) => {
    return (
        <div {...props}>
            <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
            <path d="M18.2832 6.23313C17.4477 5.40348 16.4549 4.74908 15.363 4.30833C14.2711 3.86758 13.1022 3.64935 11.9249 3.66646C9.71476 3.66094 7.59295 4.53361 6.02624 6.09251C4.45953 7.6514 3.57626 9.76882 3.57074 11.979C3.56521 14.1891 4.43788 16.3109 5.99678 17.8776C7.55568 19.4443 9.6731 20.3276 11.8832 20.3331C12.3595 20.3412 12.8249 20.191 13.2065 19.9059C13.5881 19.6208 13.8643 19.2171 13.9916 18.7581C14.0727 18.4267 14.0719 18.0804 13.9891 17.7494C13.9064 17.4183 13.7442 17.1124 13.5166 16.8581C13.4639 16.7982 13.4296 16.7245 13.4176 16.6457C13.4057 16.5669 13.4165 16.4863 13.449 16.4135C13.4815 16.3406 13.5341 16.2787 13.6008 16.2349C13.6674 16.1911 13.7452 16.1674 13.8249 16.1665H15.1999C16.494 16.1725 17.7425 15.6887 18.6947 14.8123C19.6469 13.9359 20.2322 12.7317 20.3332 11.4415C20.3642 10.4795 20.1984 9.52129 19.8459 8.62568C19.4934 7.73007 18.9616 6.9159 18.2832 6.23313V6.23313ZM15.2332 14.4998H13.8582C13.4567 14.4975 13.0631 14.6118 12.7252 14.8288C12.3873 15.0458 12.1196 15.3562 11.9547 15.7223C11.7897 16.0884 11.7345 16.4945 11.7958 16.8914C11.8571 17.2883 12.0322 17.6588 12.2999 17.9581C12.352 18.0107 12.3889 18.0765 12.4065 18.1484C12.4241 18.2204 12.4218 18.2957 12.3999 18.3665C12.3582 18.5415 12.1666 18.6498 11.9082 18.6665C10.9618 18.6543 10.0289 18.4408 9.17139 18.0401C8.31391 17.6395 7.55158 17.0608 6.9351 16.3426C6.31862 15.6244 5.86213 14.7832 5.59599 13.8749C5.32985 12.9667 5.26017 12.0121 5.39157 11.0748C5.62921 9.49975 6.4162 8.05949 7.61335 7.00874C8.8105 5.95799 10.3407 5.36446 11.9332 5.33313H11.9999C12.9423 5.32079 13.8776 5.4973 14.7506 5.85225C15.6237 6.20719 16.4168 6.73339 17.0832 7.39979C17.6032 7.92013 18.0118 8.54092 18.284 9.22433C18.5562 9.90774 18.6864 10.6394 18.6666 11.3748C18.5803 12.2273 18.182 13.018 17.5483 13.5948C16.9146 14.1716 16.0901 14.4939 15.2332 14.4998V14.4998Z" fill="#676767"/>
            <path d="M12 8.6665C12.6904 8.6665 13.25 8.10686 13.25 7.4165C13.25 6.72615 12.6904 6.1665 12 6.1665C11.3096 6.1665 10.75 6.72615 10.75 7.4165C10.75 8.10686 11.3096 8.6665 12 8.6665Z" fill="#676767"/>
            <path d="M14.7085 8.00013C14.4945 8.12377 14.322 8.30808 14.2127 8.52977C14.1034 8.75145 14.0622 9.00057 14.0945 9.24562C14.1267 9.49068 14.2309 9.72067 14.3939 9.90653C14.5568 10.0924 14.7712 10.2258 15.0099 10.2898C15.2486 10.3539 15.501 10.3457 15.7351 10.2664C15.9691 10.187 16.1745 10.0401 16.325 9.84406C16.4756 9.64806 16.5647 9.41182 16.5811 9.16519C16.5974 8.91857 16.5403 8.67263 16.4169 8.45847C16.3349 8.31613 16.2256 8.19136 16.0953 8.09128C15.9651 7.9912 15.8164 7.91778 15.6577 7.87522C15.4991 7.83265 15.3336 7.82177 15.1707 7.84321C15.0078 7.86464 14.8508 7.91797 14.7085 8.00013V8.00013Z" fill="#676767"/>
            <path d="M9.29168 7.99993C9.07752 7.87653 8.83159 7.8194 8.58496 7.83575C8.33833 7.85209 8.10209 7.94119 7.90609 8.09177C7.71009 8.24236 7.56313 8.44767 7.48379 8.68175C7.40444 8.91584 7.39628 9.16819 7.46033 9.40692C7.52438 9.64564 7.65776 9.86002 7.84362 10.023C8.02948 10.1859 8.25947 10.2901 8.50453 10.3223C8.74958 10.3546 8.9987 10.3135 9.22039 10.2042C9.44207 10.0949 9.62638 9.92229 9.75002 9.70826C9.83219 9.56602 9.88551 9.40898 9.90695 9.24611C9.92838 9.08325 9.9175 8.91776 9.87494 8.7591C9.83237 8.60044 9.75895 8.45173 9.65887 8.32146C9.55879 8.1912 9.43402 8.08194 9.29168 7.99993V7.99993Z" fill="#676767"/>
            <path d="M7.1333 11.3833C6.92827 11.5218 6.76894 11.7179 6.67546 11.947C6.58199 12.176 6.55858 12.4276 6.6082 12.67C6.65782 12.9124 6.77824 13.1346 6.95422 13.3084C7.13019 13.4823 7.3538 13.6001 7.59674 13.6468C7.83967 13.6935 8.09101 13.6671 8.31892 13.5709C8.54683 13.4747 8.74107 13.313 8.87705 13.1063C9.01302 12.8997 9.08461 12.6573 9.08276 12.4099C9.08091 12.1625 9.00569 11.9213 8.86663 11.7167C8.77528 11.5803 8.65779 11.4634 8.52097 11.3727C8.38415 11.282 8.2307 11.2194 8.06951 11.1884C7.90832 11.1574 7.74258 11.1586 7.58188 11.1921C7.42118 11.2256 7.26872 11.2906 7.1333 11.3833V11.3833Z" fill="#676767"/>

                </svg>
        </div>
    )
}

export default MarketPlaceIcon