import {
  ADD_VALIDATION_BANK,
  ADD_VERIFIED_BANK,
  ALL_BANK,
  GET_BANK,
  GET_VALIDATION_BANK,
  SET_LOADING,
  SET_MODAL_BANK,
  SET_URL_INV_BANK,
  SET_WAITING_BANK,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case ALL_BANK:
      return {
        ...state,
        banks: action.payload,
        loading: false
      };
    case GET_BANK:
      return {
        ...state,
        bank: action.payload,
        loading: false
      };
      case GET_VALIDATION_BANK:
        return {
          ...state,
          validationInfo: action.payload,
          loading: false
        };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_MODAL_BANK:
      return {
        ...state,
        showAddBank: action.payload,
      };
    case SET_WAITING_BANK:
      return {
        ...state,
    showWaiting: action.payload,
      };
    case SET_URL_INV_BANK:
      return {
        ...state,
      invoiceUrl: action.payload,
      };
    case ADD_VALIDATION_BANK: 
      return {
        ...state,
        loading: false,
      }
    case ADD_VERIFIED_BANK: 
      return {
        ...state,
        loading: false,
      }
    default:
      return state;
  }
};

export default reducer
