import React from "react";

const FacebookIcon = ({ fill = "#FFFFFF", width = "20", height = "20", ...props }) => {
    return (
        <div {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.90002 0.399902C4.65325 0.399902 0.400024 4.65313 0.400024 9.8999C0.400024 15.1467 4.65325 19.3999 9.90002 19.3999C15.1468 19.3999 19.4 15.1467 19.4 9.8999C19.4 4.65313 15.1468 0.399902 9.90002 0.399902ZM12.1503 6.9648H10.7224C10.5532 6.9648 10.3651 7.18745 10.3651 7.48334V8.51449H12.1513L11.8812 9.98501H10.3651V14.3995H8.67987V9.98501H7.15096V8.51449H8.67987V7.64959C8.67987 6.40865 9.54081 5.40027 10.7224 5.40027H12.1503V6.9648Z" fill={fill} />
            </svg>
        </div>
    )
}

export default FacebookIcon