import React from "react"
import ResponsiveContext from "./responsiveContext"
import { useMediaQuery } from 'react-responsive'

const ResponsiveState = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <ResponsiveContext.Provider value={isMobile}>
            {props.children}
        </ResponsiveContext.Provider>
    )
}

export default ResponsiveState