import Layout from "./container/Layout/Layout";
import AssetState from "./context/Asset/AssetState";
import AssetActivitiesState from "./context/AssetActivities/AssetActivitiesState";
import CategoryState from "./context/Category/CategoryState";
import CollectionState from "./context/Collection/CollectionState";
import "./App.scss"
import 'react-loading-skeleton/dist/skeleton.css'
import AssetAuctionState from "./context/AssetAuction/AssetAuctionState";
import AssetAuctionDetailState from "./context/AssetAuctionDetail/AssetAuctionDetailState";
import AssetBidState from "./context/AssetBid/AssetBidState";
import AssetBidDetailState from "./context/AssetBidDetail/AssetBidDetailState";
import AccountState from "./context/Account/AccountState";
import AuthState from "./context/Auth/AuthState";
import SocketState from "./context/Socket/SocketState";
import FilterState from "./context/Filter/FilterState";
import FiatState from "./context/FiatPayment/FiatState";
import AccountsBankState from "./context/AccounsBank/AccountsBankState";
import BankState from "./context/Bank/BankState";
import HeaderMobileState from "./context/HeaderMobile/HeaderMobileState";
import NotificationState from "./context/Notification/NotificationState";
import DisbursmentState from "./context/Disbursment/DisbursmentState";
import { ToastContainer } from "react-toastify";
import ResponsiveState from "./context/Responsive/ResponsiveState";
import ExclusivePartnerState from "./context/ExclusivePartner/ExclusivePartnerState";
import Web3ModalState from "./context/Web3Modal/Web3ModalState";
import ArticleState from "./context/Article/ArticleState";
import TxnState from "./context/Txn/txnState";
import PreprocessingState from "./context/Preprocessing/preProcessingState";
import PostprocessingState from "./context/Postprocessing/postProcessingState";
import ProjectState from "./context/ProjectUtilities/ProjectUtilState";
import UtilitasState from "./context/Utilitas/UtilitasState";
import ParameterState from "./context/Parameter/ParameterState";
import BarterState from "./context/Barter/BarterState";
function App() {
  return (
    <Web3ModalState>
      <BarterState>
        <UtilitasState>
          <ParameterState>
            <TxnState>
              <PreprocessingState>
                <PostprocessingState>
                  <ProjectState>
                    <ArticleState>
                      <ResponsiveState>
                        <AccountsBankState>
                          <BankState>
                            <NotificationState>
                              <DisbursmentState>
                                <ExclusivePartnerState>
                                  <SocketState>
                                    <FilterState>
                                      <AccountState>
                                        <FiatState>
                                          <AssetAuctionDetailState>
                                            <AssetState>
                                              <AssetActivitiesState>
                                                <AssetAuctionState>
                                                  <CollectionState>
                                                    <CategoryState>
                                                      <AssetBidState>
                                                        <AssetBidDetailState>
                                                          <AuthState>
                                                            <HeaderMobileState>
                                                              <ToastContainer />
                                                              <Layout />
                                                            </HeaderMobileState>
                                                          </AuthState>
                                                        </AssetBidDetailState>
                                                      </AssetBidState>
                                                    </CategoryState>
                                                  </CollectionState>
                                                </AssetAuctionState>
                                              </AssetActivitiesState>
                                            </AssetState>
                                          </AssetAuctionDetailState>
                                        </FiatState>
                                      </AccountState>
                                    </FilterState>
                                  </SocketState>
                                </ExclusivePartnerState>
                              </DisbursmentState>
                            </NotificationState>
                          </BankState>
                        </AccountsBankState>
                      </ResponsiveState>
                    </ArticleState>
                  </ProjectState>
                </PostprocessingState>
              </PreprocessingState>
            </TxnState>
          </ParameterState>
        </UtilitasState>
      </BarterState>
    </Web3ModalState>
  );
}

export default App;
