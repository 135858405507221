import MarketPlaceIcon from '../assets/icon/marketplace_icon'
import ActivityIcon from '../assets/icon/activity_icon'
// import FaqIcon from "../assets/icon/faq_icon"
import ArticleIcon from '../assets/icon/article_icon'
import BecomePartnerIcon from '../assets/icon/become_partner_icon'

const nav = [
  {
    name: 'Marketplace',
    disabled: true,
    url: '/assets',
    collapse: true,
    icon: MarketPlaceIcon,
    children: [
      {
        name: 'navigation.allAsset',
        url: '/assets',
        parent: 'Marketplace',
      },
      {
        name: 'Aset Ekslusif',
        url: '/asset-exclusive-partner',
        parent: 'Marketplace',
      },
      {
        name: 'navigation.allCollection',
        url: '/collection',
        parent: 'Marketplace',
      },
      {
        name: 'Utilitas',
        url: '/list-project-utilites',
        parent: 'Marketplace',
      },
    ],
  },
  {
    name: 'Statistik',
    disabled: true,
    url: '/activity',
    collapse: true,
    icon: ActivityIcon,
    children: [
      {
        name: 'Aktivitas',
        url: '/activity',
        parent: 'Statistik',
      },
      {
        name: 'Peringkat',
        url: '/collection-stats',
        parent: 'Statistik',
      },
    ],
  },
  {
    name: 'Mau Jadi Partner?',
    url: '/exclusive-partner',
    icon: BecomePartnerIcon,
  },
  // {
  //   name: 'FAQ',
  //   url: '/faq',
  //   icon : FaqIcon,
  // },
  // {
  //   name: 'Artikel',
  //   url: '/article',
  //   icon : ArticleIcon,
  // },
  {
    name: 'Informasi',
    disabled: true,
    url: '/',
    collapse: true,
    icon: ArticleIcon,
    children: [
      {
        name: 'Artikel',
        url: '/article',
        parent: 'Informasi',
      },
      {
        name: 'FAQ',
        url: '/faq',
        parent: 'Informasi',
      },
    ],
  },
]
export default nav
