import {
  ADD_TXN_LOG,
  CANCEL_TXN_LOG_CREATE_ASSET,
  DELETE_TXN_LOG,
  GET_TXN_LOG,
    SET_LOADING,
    UPDATE_TXN_LOG,
  } from '../types';
  
  const reducer = (state, action) => {
    switch (action.type) {
      case GET_TXN_LOG:
        return {
          ...state,
          txnPending: action.payload?.nodes,
          pageInfo: action.payload?.pageInfo,
          loadingTxn: false
        };
      case ADD_TXN_LOG:
        return {
          ...state,
          loadingTxn: false
        };
      case UPDATE_TXN_LOG:
        return {
          ...state,
          loadingTxn: false
        };
      case DELETE_TXN_LOG:
        return {
          ...state,
          loadingTxn: false
        };
      case CANCEL_TXN_LOG_CREATE_ASSET:
        return {
          ...state,
          loadingTxn: false
        };
      case SET_LOADING:
        return {
          ...state,
          loadingTxn: true
        };
      default:
        return state;
    }
  };
  
  export default reducer